import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <>
        <path d="M11.5562 2.69929C11.6959 2.26934 12.3041 2.26935 12.4438 2.69929L14.2901 8.38136C14.3525 8.57364 14.5317 8.70382 14.7339 8.70382H20.7084C21.1604 8.70382 21.3484 9.28231 20.9827 9.54803L16.1492 13.0597C15.9856 13.1786 15.9172 13.3892 15.9797 13.5815L17.8259 19.2636C17.9656 19.6935 17.4735 20.051 17.1078 19.7853L12.2743 16.2736C12.1107 16.1548 11.8893 16.1548 11.7257 16.2736L6.89225 19.7853C6.52652 20.051 6.03443 19.6935 6.17413 19.2636L8.02034 13.5815C8.08282 13.3892 8.01438 13.1786 7.85082 13.0597L3.01736 9.54803C2.65162 9.28231 2.83959 8.70382 3.29166 8.70382H9.26614C9.46831 8.70382 9.64749 8.57364 9.70997 8.38136L11.5562 2.69929Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6051 2.39027C11.0441 1.03901 12.9558 1.03903 13.3949 2.39027L15.1213 7.70382H20.7083C22.1291 7.70382 22.7198 9.52193 21.5704 10.357L17.0504 13.641L18.7769 18.9545C19.216 20.3058 17.6694 21.4294 16.5199 20.5943L12 17.3104L7.48 20.5943C6.33055 21.4295 4.78399 20.3058 5.22303 18.9545L6.94951 13.641L2.42954 10.357C1.28008 9.52192 1.87084 7.70382 3.29162 7.70382H8.87861L10.6051 2.39027ZM12 4.5694L10.661 8.69038C10.4646 9.29468 9.9015 9.70382 9.2661 9.70382H4.93305L8.43857 12.2507C8.95262 12.6242 9.16771 13.2862 8.97136 13.8905L7.63238 18.0115L11.1379 15.4646C11.6519 15.0911 12.348 15.0911 12.8621 15.4646L16.3676 18.0115L15.0286 13.8905C14.8322 13.2862 15.0473 12.6242 15.5614 12.2507L19.0669 9.70382H14.7338C14.0984 9.70382 13.5353 9.29468 13.339 8.69038L12 4.5694Z"
        />
    </>,
    "Star",
);
