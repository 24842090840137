import { MasteringType } from "../../../model/MasteringType";
import usePayPerUse from "../../state/usePayPerUse";
import { LoadedServiceDetailsComputed } from "../../state/useServiceDetails";

export default function usePayPerUseUtils(
    serviceDetails: LoadedServiceDetailsComputed,
) {
    const { payPerUse } = usePayPerUse();

    return {
        userShouldEnablePayPerUse: (masteringType: MasteringType) => {
            const quotaExhausted =
                masteringType === "INITIAL_MASTER"
                    ? serviceDetails.initialMasterQuota.usage >=
                      serviceDetails.initialMasterQuota.value
                    : serviceDetails.remasterQuota.usage >=
                      serviceDetails.remasterQuota.value;

            return (
                quotaExhausted &&
                serviceDetails.bonusRemaining === 0 &&
                payPerUse.applicable &&
                !payPerUse.enabled
            );
        },
    };
}
