import { CircularProgress, IconButton } from "@mui/material";
import PauseIcon from "../icons/PauseIcon";
import PlayIcon from "../icons/PlayIcon";

export default function PlayPauseButton({
    playRequested,
    isLoadingAudioSource,
    onPlayClick,
    onPauseClick,
    disabled,
    gradientBackground,
}: {
    playRequested: boolean;
    isLoadingAudioSource: boolean;
    onPlayClick: () => void;
    onPauseClick: () => void;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    gradientBackground?: boolean;
}) {
    const isPlaying = playRequested && !isLoadingAudioSource;

    const playPauseIconStyle = {
        width: 18,
        height: 18,
        margin: "3px",
    };
    let playPauseButtonIcon;
    if (isPlaying) {
        playPauseButtonIcon = <PauseIcon style={playPauseIconStyle} />;
    } else {
        playPauseButtonIcon = <PlayIcon style={playPauseIconStyle} />;
    }

    return (
        <IconButton
            data-testid="play-pause-button"
            className={
                gradientBackground ? "gradient-button" : "standard-button"
            }
            sx={{
                "&.gradient-button": {
                    background:
                        "linear-gradient(0deg, #9B4DFF 33%, #4DA1FF 100%)",
                },

                "&.standard-button": {
                    backgroundColor: "#2D547C",
                },
            }}
            onClick={() => {
                if (isPlaying) onPauseClick();
                else onPlayClick();
            }}
            aria-label={isPlaying ? "pause" : "play"}
            disabled={disabled || isLoadingAudioSource}
        >
            {playPauseButtonIcon}
            {isLoadingAudioSource && (
                <CircularProgress
                    aria-label="loading preview"
                    sx={{
                        position: "absolute",
                        top: -4,
                        left: -4,
                    }}
                    size={48}
                    color="secondary"
                />
            )}
        </IconButton>
    );
}
