import LoadingButton from "@mui/lab/LoadingButton";
import {
    Card,
    CardContent,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useId } from "react";
import { MasteringPriceWithTax } from "../../model/MasteringPriceWithTax";
import formatPrice from "../formatPrice";
import formatTotalPrice from "../formatTotalPrice";
import colors from "../theme/colors";

export default function MarketingCard({
    loading,
    onClick,
    disabled,
    masteringPriceWithTax,
    canBenefitPpuPromo,
}: {
    loading: boolean;
    onClick: () => void;
    disabled: boolean;
    masteringPriceWithTax: MasteringPriceWithTax;
    canBenefitPpuPromo: boolean;
}) {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const titleId = useId();

    return (
        <Card
            role="region"
            aria-labelledby={titleId}
            sx={{
                flexBasis: smallScreen ? "100%" : "293px",
                minWidth: "293px",
                alignSelf: "self-start",
                borderRadius: "6px",
                backgroundColor: colors.newColors.neutrals.n5,
                ".MuiCardContent-root": {
                    paddingBottom: "16px",
                },
            }}
        >
            <CardContent>
                <Typography
                    id={titleId}
                    sx={{
                        boxSizing: "border-box",
                        marginLeft: "-16px",
                        marginRight: "-16px",
                        marginTop: "-16px",
                        padding: "6px 16px 6px 16px",
                        height: "33px",
                        background:
                            "linear-gradient(0deg, #9B4DFF 30.13%, #4DA1FF 99.74%)",
                        fontWeight: 600,
                    }}
                >
                    Pay Per Use
                </Typography>
                {canBenefitPpuPromo && (
                    <Typography
                        display="flex"
                        sx={{
                            marginTop: "16px",
                            color: colors.newColors.neutrals.n1,
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            component="span"
                            sx={{
                                color: colors.newColors.neutrals.n0,
                                marginRight: "8px",
                                fontSize: "20px",
                            }}
                        >
                            $0.00
                        </Typography>{" "}
                        for your first 2 tracks
                    </Typography>
                )}
                <Typography
                    display="flex"
                    sx={{
                        marginTop: "16px",
                        color: colors.newColors.neutrals.n1,
                        alignItems: "center",
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            textDecoration: canBenefitPpuPromo
                                ? "line-through"
                                : "",
                            color: colors.newColors.neutrals.n0,
                            marginRight: "8px",
                            fontSize: "20px",
                        }}
                    >
                        {formatTotalPrice(masteringPriceWithTax)}
                    </Typography>{" "}
                    per extra track
                </Typography>
                <Typography
                    component="div"
                    sx={{
                        marginTop: "16px",
                        color: colors.newColors.neutrals.n1,
                        alignItems: "center",
                    }}
                >
                    <div>
                        Price excl. taxes:{" "}
                        {formatPrice(
                            masteringPriceWithTax.amountExcludingTaxInCents,
                        )}
                    </div>
                    <div>Tax: {formatTax(masteringPriceWithTax.tax)}</div>
                </Typography>

                <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={onClick}
                    disabled={disabled}
                    sx={{
                        width: "100%",
                        marginTop: "16px",
                    }}
                >
                    Activate
                </LoadingButton>
            </CardContent>
        </Card>
    );
}

function formatTax(tax: MasteringPriceWithTax["tax"]): string {
    if (tax.status === "NOT_CALCULATED_YET") {
        return "not calculated yet";
    }
    return formatPrice(tax.taxInCents);
}
