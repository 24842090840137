import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg fill="none" viewBox="0 0 20 15">
        <mask
            id="mask0_256_127"
            maskUnits="userSpaceOnUse"
            x="0"
            y="1"
            width="20"
            height="13"
            style={{ maskType: "alpha" }}
        >
            <circle cx="6.5" cy="7.5" r="6" stroke="black" />
            <circle cx="13.5" cy="7.5" r="6" stroke="black" />
        </mask>
        <g mask="url(#mask0_256_127)">
            <rect
                y="1"
                width="20"
                height="13"
                fill="url(#paint0_linear_256_127)"
            />
            <rect y="1" width="20" height="13" fill="black" fillOpacity="0.2" />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_256_127"
                x1="0"
                y1="7.5"
                x2="20"
                y2="7.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F81790" />
                <stop offset="1" stopColor="#FE7025" />
            </linearGradient>
        </defs>
    </svg>,
    "StereoMore",
);
