import { Country } from "./model/Country";

export type Config = {
    sentry: {
        enabled: boolean;
        dsn: string | null;
        environment: string | null;
    };
    authenticationServerUrl: string | null;
    gtm: {
        enabled: boolean;
        containerId: string | null;
    };
    countries: Country[];
    guestPreviewEnabled: boolean;
    masteringPublicFullPriceInCents: number;
};

let config: Config | null = null;

export default {
    set(newConfig: Config): void {
        config = newConfig;
    },

    get(): Config {
        if (config == null) {
            throw Error("Config has not been set!");
        }

        return config;
    },
};
