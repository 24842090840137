import {
    Box,
    Container,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { SystemProps } from "@mui/system";
import { jwtDecode } from "jwt-decode";
import { useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import config from "../../config";
import sentryLogger from "../../sentryLogger";
import HiddenWavFileInput from "../IndexPage/HiddenWavFileInput";
import Studio, { NewMasteringDialogInput } from "../IndexPage/Studio/Studio";
import studioState from "../IndexPage/Studio/studioState";
import useAudioFilesValidation from "../IndexPage/useAudioFilesValidation";
import SupportedAudioFileFormatSpecs from "../SupportedAudioFileFormatSpecs";
import homeBg from "../assets/home_bg.jpg";
import logoPath from "../assets/logo.svg";
import mesh from "../assets/mesh.png";
import plus from "../assets/plus.svg";
import formatPrice from "../formatPrice";
import useAuthentication from "../state/useAuthentication";
import useNotifications from "../state/useNotifications";
import colors from "../theme/colors";
import SkewedText from "./SkewedText";

export default function HomePage() {
    const theme = useTheme();
    const horizontalLayout = useMediaQuery(theme.breakpoints.up("md"));
    const smallWidth = useMediaQuery(theme.breakpoints.down("sm"));

    const { authenticationState } = useAuthentication();
    if (authenticationState.type === "REGISTERED_USER_AUTHENTICATED") {
        return <Navigate to="/" replace />;
    }

    return (
        <Box
            style={{
                background: `linear-gradient(180deg, rgba(0, 16, 34, 0.00) 0%, #001022 100%), url(${homeBg}) lightgray 50% / cover no-repeat`,
                height: "100%",
            }}
            padding="52px 14px 14px 14px"
        >
            <Container
                style={{
                    display: "flex",
                    flexDirection: horizontalLayout ? "row" : "column",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "space-between",
                    padding: 0,
                    gap: horizontalLayout ? "200px" : "20px",
                }}
            >
                <ServiceDescription
                    smallWidth={smallWidth}
                    flexBasis={0}
                    flexGrow={horizontalLayout ? 1.05 : undefined}
                />
                <UploadArea
                    smallWidth={smallWidth}
                    flexBasis={0}
                    flexGrow={1}
                />
            </Container>
        </Box>
    );
}

function ServiceDescription({
    smallWidth,
    ...otherProps
}: {
    smallWidth: boolean;
} & SystemProps) {
    return (
        <Box display="flex" flexDirection="column" {...otherProps}>
            <Box>
                <img
                    style={{
                        maxHeight: "3em",
                        maxWidth: "100%",
                    }}
                    src={logoPath}
                    alt="Virtu's Logo"
                />
                <SkewedText style={{ marginTop: "10px" }}>
                    from mixed to master
                </SkewedText>
            </Box>
            <Typography
                fontSize={smallWidth ? 16 : 18}
                marginTop="16px"
                component="div"
            >
                <Typography
                    variant="h1"
                    style={{
                        fontSize: "unset",
                        display: "inline",
                        fontWeight: "bold",
                    }}
                >
                    VIRTU Assisted Mastering
                </Typography>{" "}
                is an online mastering platform meant to make the art of
                mastering as accessible as it is essential. Smart track analysis
                and an easily navigable interface make it easy for anyone to
                give their tracks the professional treatment they deserve.
            </Typography>
        </Box>
    );
}

function UploadArea({
    smallWidth,
    ...otherProps
}: {
    smallWidth: boolean;
} & SystemProps) {
    const fileInput = useRef<HTMLInputElement | null>(null);

    const { authenticationState, login } = useAuthentication();

    const [dialogInput, setDialogInput] =
        useState<NewMasteringDialogInput | null>(() => {
            const restoredState =
                authenticationState.type === "GUEST_USER_AUTHENTICATED"
                    ? studioState.retrieve(
                          authenticationState.authenticatedUser.id,
                      )
                    : null;
            return restoredState == null
                ? null
                : {
                      type: "RESTORED_STATE",
                      restoredState,
                  };
        });

    const [highlighted, setHighlighted] = useState(false);
    const { validateAudioFiles } = useAudioFilesValidation();
    const { addNotification } = useNotifications();

    async function onValidFileSelected(selectedFile: File) {
        if (authenticationState.type === "GUEST_USER_AUTHENTICATED") {
            const { authorizationHeader } =
                authenticationState.authenticatedUser;

            if (isUserTokenAlmostExpired(authorizationHeader)) {
                await attemptLoginAndUploadFile(selectedFile);
            } else {
                setDialogInput({ type: "SELECTED_FILE", selectedFile });
            }
        } else if (authenticationState.type === "NOT_AUTHENTICATED") {
            await attemptLoginAndUploadFile(selectedFile);
        } else {
            throw new Error(
                `Unexpected authentication state: ${authenticationState.type}`,
            );
        }
    }

    async function attemptLoginAndUploadFile(selectedFile: File) {
        try {
            await login(null);
            setDialogInput({ type: "SELECTED_FILE", selectedFile });
        } catch (e) {
            sentryLogger.captureException(e);
            addNotification({
                severity: "error",
                message:
                    "Unable to process the selected file, please retry later",
            });
        }
    }

    return (
        <>
            <Box
                role="button"
                display="flex"
                border="1px dashed"
                borderColor={
                    highlighted
                        ? colors.newColors.neutrals.n0
                        : "rgba(255, 255, 255, 0.50)"
                }
                borderRadius="8px"
                height="485px"
                style={{
                    background: `url(${mesh}) 50% / cover no-repeat`,
                    cursor: "pointer",
                    backgroundColor: highlighted
                        ? "rgba(22, 129, 255, 1)"
                        : undefined,
                    backgroundClip: "padding-box",
                    transition: "all 250ms",
                }}
                flexDirection="column"
                onClick={() => {
                    fileInput.current!.click();
                }}
                onDragOver={(event) => {
                    event.preventDefault();
                    setHighlighted(true);
                }}
                onDragLeave={(event) => {
                    event.preventDefault();
                    setHighlighted(false);
                }}
                onDrop={(event) => {
                    event.preventDefault();
                    setHighlighted(false);

                    validateAudioFiles(event.dataTransfer.files, (file) => {
                        // noinspection JSIgnoredPromiseFromCall
                        onValidFileSelected(file);
                    });
                }}
                {...otherProps}
            >
                <Box
                    flexGrow={1}
                    padding="35px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="16px"
                >
                    <Typography
                        textAlign="center"
                        fontSize={smallWidth ? "18px" : "24px"}
                        fontWeight="800"
                    >
                        Try it FREE
                    </Typography>
                    <Typography
                        textAlign="center"
                        fontSize={smallWidth ? "14px" : "18px"}
                        maxWidth="400px"
                    >
                        Upload your latest mix to hear the VIRTU difference for
                        yourself.
                    </Typography>
                    <img
                        src={plus}
                        alt=""
                        width={smallWidth ? 60 : 104}
                        draggable={false}
                    />
                    <Typography textAlign="center">
                        2 Free Masters then only{" "}
                        {formatPrice(
                            config.get().masteringPublicFullPriceInCents,
                        )}{" "}
                        + taxes thereafter
                    </Typography>
                </Box>
                <Box
                    textAlign="center"
                    bgcolor="rgb(255 255 255 / 20%)"
                    borderRadius="0px 0px 7px 7px"
                    padding="10px"
                >
                    <SupportedAudioFileFormatSpecs />
                </Box>
                <HiddenWavFileInput
                    onFileSelected={onValidFileSelected}
                    ref={fileInput}
                />
            </Box>
            <Studio
                input={dialogInput}
                onClose={() => {
                    setDialogInput(null);
                }}
            />
        </>
    );
}

function isUserTokenAlmostExpired(authorizationHeader: string): boolean {
    const decodedToken = jwtDecode<CustomJwtPayload>(authorizationHeader);
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const expirationGapInSeconds = 60 * 60; // 1 hour
    const tokenExpirationTimestampInSeconds = decodedToken.exp;
    const maximumExpirationTimestampInSeconds =
        tokenExpirationTimestampInSeconds - expirationGapInSeconds;
    return maximumExpirationTimestampInSeconds < currentTimestampInSeconds;
}

type CustomJwtPayload = {
    sub: string;
    exp: number;
};
