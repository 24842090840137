import { Alert, Skeleton, Tooltip } from "@mui/material";
import { MasteringType } from "../../../../model/MasteringType";
import InformationIcon from "../../../icons/InformationIcon";
import useAuthentication from "../../../state/useAuthentication";
import usePricing from "../../../state/usePricing";
import { LoadedServiceDetailsComputed } from "../../../state/useServiceDetails";
import colors from "../../../theme/colors";
import MasteringCost from "./MasteringCost";
import MasteringCostDetails from "./MasteringCostDetails";

export default function MasteringCostInformation({
    serviceDetails,
    masteringType,
}: {
    serviceDetails: LoadedServiceDetailsComputed;
    masteringType: MasteringType;
}) {
    const { authenticationState } = useAuthentication();

    const isPayPerUseActive =
        authenticationState.type === "REGISTERED_USER_AUTHENTICATED" &&
        authenticationState.authenticatedUser.payPerUseEnabled;

    const [pricing] = usePricing();

    let masteringPriceWithTax;
    if (pricing.status === "LOADED" && pricing.masteringPriceWithTax) {
        masteringPriceWithTax = pricing.masteringPriceWithTax;
    } else if (pricing.status === "LOADING") {
        masteringPriceWithTax = pricing.previousValue;
    } else {
        masteringPriceWithTax = null;
    }

    const initialLoadInProgress =
        pricing.status === "LOADING" && masteringPriceWithTax == null;

    let contents;
    if (initialLoadInProgress) {
        contents = <Skeleton width={200} height={43} />;
    } else if (masteringPriceWithTax == null) {
        contents = (
            <Tooltip title="The pricing can't be loaded, please retry later">
                <Alert severity="error">Cannot load pricing</Alert>
            </Tooltip>
        );
    } else {
        contents = (
            <>
                <MasteringCost
                    isPayPerUseActive={isPayPerUseActive}
                    serviceDetails={serviceDetails}
                    masteringPriceWithTax={masteringPriceWithTax}
                    masteringType={masteringType}
                />

                <Tooltip
                    slotProps={{
                        tooltip: {
                            sx: {
                                padding: "12px",
                            },
                        },
                    }}
                    title={
                        <MasteringCostDetails
                            isPayPerUseActive={isPayPerUseActive}
                            serviceDetails={serviceDetails}
                            masteringPriceWithTax={masteringPriceWithTax}
                            masteringType={masteringType}
                        />
                    }
                    arrow
                >
                    <InformationIcon
                        sx={{
                            fill: colors.neutrals.n1,
                        }}
                    />
                </Tooltip>
            </>
        );
    }

    return (
        <div
            role="region"
            aria-label="Mastering cost information"
            style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
            }}
        >
            {contents}
        </div>
    );
}
