import { z } from "zod";

const referenceTrackStatusSchema = z.enum([
    "CREATED",
    "ANALYZING",
    "ANALYZED",
    "ANALYSIS_ERROR",
]);

export const referenceTrackSchema = z.object({
    id: z.number(),
    fileName: z.string(),
    status: referenceTrackStatusSchema,
    loudness: z.number().nullable(),
});

export type ReferenceTrack = z.infer<typeof referenceTrackSchema>;
