import { z } from "zod";

export const billingInformationSchema = z.object({
    firstName: z.nullable(z.string()),
    lastName: z.nullable(z.string()),
    companyName: z.nullable(z.string()),
    vatNumber: z.nullable(z.string()),
    street: z.nullable(z.string()),
    city: z.nullable(z.string()),
    region: z.nullable(z.string()),
    country: z.nullable(z.string()),
    postalCode: z.nullable(z.string()),
});

export type BillingInformation = z.infer<typeof billingInformationSchema>;
