import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M6.15588 13.28H9.24588V20.48C9.24588 21.54 10.5659 22.04 11.2659 21.24L18.8359 12.64C19.4959 11.89 18.9659 10.72 17.9659 10.72H14.8759V3.52003C14.8759 2.46003 13.5559 1.96003 12.8559 2.76003L5.28588 11.36C4.63588 12.11 5.16588 13.28 6.15588 13.28Z" />
        <linearGradient
            id="blueToMagentaGradient"
            x1="128.594"
            y1="1.57971"
            x2="127.525"
            y2="-10.3809"
            gradientUnits="userSpaceOnUse"
        >
            <stop stopColor="#9B4DFF" />
            <stop offset="1" stopColor="#4DA1FF" />
        </linearGradient>
    </svg>,
    "payPerUse",
);
