import LoadingButton from "@mui/lab/LoadingButton";
import {
    Box,
    Button,
    Link,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useId, useState } from "react";
import Backend from "../../../Backend";
import config from "../../../config";
import { BillingInformation } from "../../../model/BillingInformation";
import { Country } from "../../../model/Country";
import useNotifications from "../../state/useNotifications";
import colors from "../../theme/colors";
import CountrySelect from "./CountrySelect";

export default function BillingInformationSection({
    initialBillingInformation,
    onSaveAndContinue,
    onChange,
    onError,
    collapsed,
}: {
    initialBillingInformation: BillingInformation | undefined;
    onSaveAndContinue: (newBillingInformation: BillingInformation) => void;
    onChange: () => void;
    onError: (e: Error) => void;
    collapsed: boolean;
}) {
    const [billingInformation, setBillingInformation] = useState<
        BillingInformation | undefined
    >(initialBillingInformation);

    useEffect(() => {
        (async () => {
            if (initialBillingInformation == null) {
                try {
                    const fetchedBillingInformation =
                        await Backend.getBillingInformation();
                    setBillingInformation(
                        fetchedBillingInformation ?? {
                            firstName: null,
                            lastName: null,
                            companyName: null,
                            vatNumber: null,
                            street: null,
                            city: null,
                            region: null,
                            postalCode: null,
                            country: null,
                        },
                    );
                } catch (e: any) {
                    onError(e);
                }
            }
        })();
    }, [onError, initialBillingInformation]);

    return collapsed && billingInformation ? (
        <CollapsedBillingInformationSection
            billingInformation={billingInformation}
            onChange={onChange}
        />
    ) : (
        <UncollapsedBillingInformation
            billingInformation={billingInformation}
            setBillingInformation={setBillingInformation}
            onSaveAndContinue={() => onSaveAndContinue(billingInformation!)}
        />
    );
}

function CollapsedBillingInformationSection({
    billingInformation,
    onChange,
}: {
    billingInformation: BillingInformation;
    onChange: () => void;
}) {
    const titleId = useId();

    return (
        <Stack spacing="8px" role="region" aria-labelledby={titleId}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <BillingInformationHeading titleId={titleId} />
                <Button
                    onClick={() => onChange()}
                    size="small"
                    variant="outlined"
                    color="secondary"
                >
                    Change
                </Button>
            </div>
            <Typography>
                {[
                    [billingInformation.firstName, billingInformation.lastName]
                        .filter((field) => !!field?.trim())
                        .join(" "),
                    billingInformation.companyName,
                    billingInformation.vatNumber,
                    billingInformation.street,
                    billingInformation.city,
                    billingInformation.region,
                    billingInformation.postalCode,
                    billingInformation.country,
                ]
                    .filter((field) => !!field?.trim())
                    .join(", ")}
            </Typography>
        </Stack>
    );
}

function UncollapsedBillingInformation({
    billingInformation,
    setBillingInformation,
    onSaveAndContinue,
}: {
    billingInformation: BillingInformation | undefined;
    setBillingInformation: (newValue: BillingInformation) => void;
    onSaveAndContinue: () => void;
}) {
    const [country, setCountry] = useState<Country>();
    const [updatingBillingInformation, setUpdatingBillingInformation] =
        useState(false);

    const { addNotification } = useNotifications();
    const { countries } = config.get();

    const [errors, setErrors] = useState<{
        [K in keyof BillingInformation]: string | null;
    }>({
        firstName: null,
        lastName: null,
        companyName: null,
        vatNumber: null,
        street: null,
        city: null,
        region: null,
        postalCode: null,
        country: null,
    });

    const titleId = useId();

    const cleanedBillingInformation = billingInformation
        ? {
              ...billingInformation,
              country:
                  countries.find(
                      (theCountry) =>
                          theCountry.name === billingInformation.country,
                  )?.name ?? null,
          }
        : undefined;

    const saveBillingInformation = async (
        billingInformationToSave: BillingInformation,
    ) => {
        const updatedErrors: {
            [K in keyof BillingInformation]: string | null;
        } = {
            firstName: null,
            lastName: null,
            companyName: null,
            vatNumber: null,
            street: null,
            city: null,
            region: null,
            postalCode: null,
            country: null,
        };

        const required = (text: string | null) =>
            text?.trim() ? null : "Required";

        updatedErrors.street = required(billingInformationToSave.street);
        updatedErrors.city = required(billingInformationToSave.city);
        updatedErrors.postalCode = required(
            billingInformationToSave.postalCode,
        );
        updatedErrors.country = required(billingInformationToSave.country);

        if (country?.regionNeeded) {
            updatedErrors.region = required(billingInformationToSave.region);
        }
        if (
            Object.values(updatedErrors).some(
                (updatedError) => updatedError !== null,
            )
        ) {
            setErrors(updatedErrors);
        } else {
            setUpdatingBillingInformation(true);

            try {
                await Backend.updateBillingInformation(
                    billingInformationToSave,
                );
                onSaveAndContinue();
            } catch (e: any) {
                if (e.response?.data?.code === "INVALID_ADDRESS") {
                    updatedErrors.street = "To be checked";
                    updatedErrors.city = "To be checked";
                    updatedErrors.postalCode = "To be checked";

                    if (country?.regionNeeded) {
                        updatedErrors.region = "To be checked";
                    }

                    setErrors(updatedErrors);

                    addNotification({
                        severity: "error",
                        message:
                            "Unable to update billing information, please check your address and try again",
                        manualDismiss: true,
                    });
                } else if (e.response?.data?.code === "INVALID_VAT_NUMBER") {
                    updatedErrors.vatNumber =
                        "This VAT number is not eligible for tax exemption. Tax exemption is only possible for countries located in EU and UK. " +
                        "If you are in one of those countries, please make sure your VAT is prefixed with your country code (FR12345678912). " +
                        "If you are outside of EU and UK, please leave the VAT number blank.";

                    setErrors(updatedErrors);

                    addNotification({
                        severity: "error",
                        message:
                            "Unable to update billing information, please check the VAT number and try again",
                        manualDismiss: true,
                    });
                } else if (e.response?.data?.code === "FORBIDDEN_COUNTRY") {
                    updatedErrors.country = "This country is forbidden.";

                    setErrors(updatedErrors);

                    addNotification({
                        severity: "error",
                        message:
                            "In accordance with our Groupe Economic Sanctions Policy, and to ensure compliance with international regulations, we can not process purchases in your country at this time.",
                        details: (
                            <Link href="https://www.audiotonix.com/responsibility/legal/group-economic-sanctions-policy/">
                                See here
                            </Link>
                        ),
                        manualDismiss: true,
                    });
                } else {
                    addNotification({
                        severity: "error",
                        message: "Unable to update billing information",
                        manualDismiss: true,
                    });
                }
            } finally {
                setUpdatingBillingInformation(false);
            }
        }
    };

    return (
        <div>
            <Box
                role="region"
                aria-labelledby={titleId}
                borderRadius={2}
                border="1px solid"
                borderColor={colors.neutrals.n3}
                padding={1}
                display="flex"
                flexDirection="column"
                margin="auto" // Center the form
            >
                <BillingInformationHeading titleId={titleId} />
                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    marginBottom={1}
                >
                    <BillingInformationTextField
                        label="First name"
                        fieldName="firstName"
                        billingInformation={cleanedBillingInformation}
                        setBillingInformation={setBillingInformation}
                        required={false}
                        errors={errors}
                    />
                    <BillingInformationTextField
                        label="Last name"
                        fieldName="lastName"
                        billingInformation={cleanedBillingInformation}
                        setBillingInformation={setBillingInformation}
                        required={false}
                        errors={errors}
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    marginBottom={1}
                >
                    <BillingInformationTextField
                        label="Company name"
                        fieldName="companyName"
                        billingInformation={cleanedBillingInformation}
                        setBillingInformation={setBillingInformation}
                        required={false}
                        errors={errors}
                    />
                    <BillingInformationTextField
                        label="VAT identification number"
                        fieldName="vatNumber"
                        billingInformation={cleanedBillingInformation}
                        setBillingInformation={setBillingInformation}
                        required={false}
                        errors={errors}
                    />
                </Box>

                <Box marginBottom={1}>
                    <BillingInformationTextField
                        label="Street"
                        fieldName="street"
                        billingInformation={cleanedBillingInformation}
                        setBillingInformation={setBillingInformation}
                        required
                        errors={errors}
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    marginBottom={1}
                >
                    <BillingInformationTextField
                        label="City"
                        fieldName="city"
                        billingInformation={cleanedBillingInformation}
                        setBillingInformation={setBillingInformation}
                        required
                        errors={errors}
                    />
                    <BillingInformationTextField
                        label="State or region"
                        fieldName="region"
                        billingInformation={cleanedBillingInformation}
                        setBillingInformation={setBillingInformation}
                        required={country?.regionNeeded ?? false}
                        errors={errors}
                    />
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    marginBottom={2}
                >
                    <BillingInformationTextField
                        label="Postal code"
                        fieldName="postalCode"
                        billingInformation={cleanedBillingInformation}
                        setBillingInformation={setBillingInformation}
                        required
                        errors={errors}
                    />
                    <BillingInformationCountrySelect
                        billingInformation={cleanedBillingInformation}
                        setBillingInformation={setBillingInformation}
                        required
                        errors={errors}
                        countries={countries}
                        setCountry={setCountry}
                    />
                </Box>
                <LoadingButton
                    variant="contained"
                    disabled={!cleanedBillingInformation}
                    loading={updatingBillingInformation}
                    onClick={() =>
                        saveBillingInformation(cleanedBillingInformation!)
                    }
                >
                    Save and Continue
                </LoadingButton>
            </Box>
        </div>
    );
}

function BillingInformationTextField({
    label,
    fieldName,
    billingInformation,
    setBillingInformation,
    required,
    errors,
}: {
    label: string;
    fieldName: keyof BillingInformation;
    billingInformation: BillingInformation | undefined;
    setBillingInformation: (newValue: BillingInformation) => void;
    required: boolean;
    errors: { [K in keyof BillingInformation]: string | null };
}) {
    return billingInformation ? (
        <TextField
            label={label}
            value={billingInformation[fieldName] ?? ""}
            onChange={(event) =>
                setBillingInformation({
                    ...billingInformation,
                    [fieldName]: event.target.value,
                })
            }
            required={required}
            error={errors[fieldName] !== null}
            helperText={errors[fieldName]}
            fullWidth
        />
    ) : (
        <Skeleton width="100%">
            <TextField />
        </Skeleton>
    );
}

function BillingInformationHeading({ titleId }: { titleId: string }) {
    return (
        <Typography variant="h3" id={titleId}>
            Billing Information
        </Typography>
    );
}

function BillingInformationCountrySelect({
    billingInformation,
    setBillingInformation,
    required,
    errors,
    countries,
    setCountry,
}: {
    billingInformation: BillingInformation | undefined;
    setBillingInformation: (newValue: BillingInformation) => void;
    errors: { [K in keyof BillingInformation]: string | null };
    required: boolean;
    countries: Country[];
    setCountry: (country: Country) => void;
}) {
    return billingInformation ? (
        <CountrySelect
            countries={countries}
            countryName={billingInformation.country}
            setCountry={(country) => {
                setCountry(country);
                setBillingInformation({
                    ...billingInformation,
                    country: country.name,
                });
            }}
            required={required}
            error={errors.country !== null}
            helperText={errors.country}
        />
    ) : (
        <Skeleton width="100%">
            <CountrySelect
                countries={[]}
                countryName=""
                setCountry={() => {}}
            />
        </Skeleton>
    );
}
