import { Quota } from "../../Backend";
import { MasteringType } from "../../model/MasteringType";
import dates from "../dates";
import colors from "../theme/colors";
import QuotaProgressBar from "./QuotaProgressBar";

export default function LicenseQuotaDisplay({
    quotaType,
    quota,
    resetDate,
}: {
    quotaType: MasteringType;
    quota: Quota;
    resetDate?: string;
}) {
    const quotaTypeWording =
        quotaType === "INITIAL_MASTER" ? "masters" : "remasters";

    return (
        <div data-testid="license-quota-display">
            <div
                style={{
                    lineHeight: "14px",
                    fontSize: "14px",
                    color: colors.neutrals.n0,
                    textAlign: "left",
                }}
            >
                {quota.value === quota.usage
                    ? `All ${quota.value} free ${quotaTypeWording} used`
                    : `${quota.value - quota.usage} free ${quotaTypeWording} left`}
                {resetDate && (
                    <span style={{ color: colors.neutrals.n2 }}>
                        {" "}
                        - Resets on{" "}
                        {dates.formatLocalDateWithoutYear(resetDate)}
                    </span>
                )}
            </div>
            <QuotaProgressBar progress={(quota.usage * 100) / quota.value} />
        </div>
    );
}
