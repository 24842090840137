import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AapPromotionBanner from "./AapPromotionBanner";
import AccountPage from "./AccountPage";
import AuthenticationPage from "./AuthenticationPage";
import AuthenticationStubPage from "./AuthenticationStubPage";
import AuthenticationWorkflow from "./AuthenticationWorkflow";
import HomePage from "./HomePage/HomePage";
import IndexPage from "./IndexPage/IndexPage";
import MainHeader from "./MainHeader/MainHeader";
import NotificationCenter from "./NotificationCenter";
import ProfilePage from "./ProfilePage";
import Protected from "./Protected";
import useServiceDetails from "./state/useServiceDetails";
import darkTheme from "./theme/darkTheme";

export default function ApplicationView() {
    const { serviceDetails } = useServiceDetails();

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline enableColorScheme />
            <BrowserRouter>
                <Box height="100vh" display="flex" flexDirection="column">
                    <NotificationCenter />
                    <div
                        style={{
                            position: "sticky",
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: "999",
                        }}
                    >
                        {serviceDetails.status === "LOADED" &&
                            !serviceDetails.hasAap &&
                            serviceDetails.shouldDisplayAapPromotionBanner && (
                                <AapPromotionBanner />
                            )}
                        <MainHeader />
                    </div>
                    <main style={{ flexGrow: 1 }}>
                        <AuthenticationWorkflow>
                            <Routes>
                                <Route
                                    index
                                    element={
                                        <Protected component={IndexPage} />
                                    }
                                />
                                <Route path="home" element={<HomePage />} />
                                <Route
                                    path="account"
                                    element={
                                        <Protected component={AccountPage} />
                                    }
                                >
                                    <Route
                                        path="profile"
                                        element={
                                            <Protected
                                                component={ProfilePage}
                                            />
                                        }
                                    />
                                </Route>
                                <Route
                                    path="authentication"
                                    element={<AuthenticationPage />}
                                />
                                <Route
                                    path="authentication-stub"
                                    element={<AuthenticationStubPage />}
                                />
                            </Routes>
                        </AuthenticationWorkflow>
                    </main>
                </Box>
            </BrowserRouter>
        </ThemeProvider>
    );
}
