export default {
    newColors: {
        primary: "#00A3FF",
        neutrals: {
            n0: "#F5F5F5",
            n1: "#D0CBCB",
            n2: "#878795",
            n3: "#333A43",
            n4: "#23272A",
            n5: "#1A1E22",
            n6: "#14181B",
        },
        special: {
            orange: "#FF7223",
        },
        gradients: {
            orangeToPink: "linear-gradient(45deg, #FF7223 0%, #F81790 100%)",
            pinkToPurple: "linear-gradient(45deg, #E323FF 0%, #7517F8 100%)",
        },
        backgrounds: {
            blur: "#1E2429B2",
            hover: "#0A2C3F",
        },
    },
    neutrals: {
        n0: "#FFFFFF",
        n1: "#B0B0B0",
        n2: "#757575",
        n3: "#333333",
        n4: "#2A313A",
        n5: "#1A1A1A",
        n6: "#000000",
    },
    primary: {
        p1: "#EFF7FF",
        p2: "#A8C8ED",
        p3: "#3190FF",
        p4: "#1681FF",
        p5: "#096DE1",
        p6: "#1F3854",
    },
    red: {
        r1: "#FFDAD2",
        r2: "#E43812",
        r3: "#D6320E",
        r4: "#C6300F",
        r5: "#401F18",
    },
    green: {
        g1: "#D7F0E1",
        g2: "#37B469",
        g3: "#20392A",
    },
    yellow: {
        y1: "#FBC132",
    },
} as const;
