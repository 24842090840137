import {
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { LoudnessPreset } from "../../../model/Mastering";
import InformationIcon from "../../icons/InformationIcon";
import LoudnessIcon from "../../icons/LoudnessIcon";
import colors from "../../theme/colors";
import loudnessPresetMapping from "../loudnessPresetMapping";
import getClosestLoudnessPreset from "./getClosestLoudnessPreset";

const loudnesses = ["LOW", "MEDIUM", "HIGH"] as const;

export default function LoudnessSelection({
    selectedLoudness,
    onChange,
    referenceTrackLoudness,
}: {
    selectedLoudness: LoudnessPreset;
    onChange: (value: LoudnessPreset) => void;
    referenceTrackLoudness: number | null;
}) {
    const verticalLayout = useMediaQuery(useTheme().breakpoints.down("md"));

    let recommendedPresetFromReferenceTrack: LoudnessPreset | null;
    if (referenceTrackLoudness == null) {
        recommendedPresetFromReferenceTrack = null;
    } else {
        recommendedPresetFromReferenceTrack = getClosestLoudnessPreset(
            referenceTrackLoudness,
        );
    }

    return (
        <ToggleButtonGroup
            fullWidth={!verticalLayout}
            value={selectedLoudness}
            onChange={(_event, value) => onChange(value)}
            exclusive
            orientation={verticalLayout ? "vertical" : "horizontal"}
        >
            {loudnesses.map((loudness) => (
                <ToggleButton
                    key={loudness}
                    value={loudness}
                    aria-label={loudnessPresetMapping[loudness].label}
                    sx={{
                        justifyContent: "space-between",
                        padding: loudness === selectedLoudness ? "0px" : "1px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "8px",
                        }}
                    >
                        <LoudnessIcon
                            style={{
                                marginRight: "8px",
                                fill:
                                    loudness === selectedLoudness
                                        ? colors.primary.p3
                                        : colors.neutrals.n2,
                            }}
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                            }}
                        >
                            <Typography>
                                {loudnessPresetMapping[loudness].label}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{ color: colors.neutrals.n2 }}
                            >
                                {loudnessPresetMapping[loudness].lufs} LUFS
                            </Typography>
                            {loudness ===
                                recommendedPresetFromReferenceTrack && (
                                <Typography
                                    variant="body2"
                                    style={{ color: colors.newColors.primary }}
                                >
                                    Best match to your reference track
                                </Typography>
                            )}
                        </div>
                    </div>

                    <Tooltip
                        title={
                            <span>
                                Around {loudnessPresetMapping[loudness].lufs}{" "}
                                LUFS
                                <br />
                                {loudnessPresetMapping[loudness].hint}
                            </span>
                        }
                    >
                        <InformationIcon
                            role="img"
                            style={{
                                fontSize: "16px",
                                fill: colors.neutrals.n2,
                                marginRight: "8px",
                            }}
                        />
                    </Tooltip>
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}
