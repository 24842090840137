import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg fill="none" viewBox="0 0 15 15">
        <path
            d="M3.0047 2.89371C3.8657 2.87794 6.13187 2.89371 7.40229 7.83347C8.83558 12.6031 11.0159 12.0919 11.9953 12.0919"
            stroke="#EAB03D"
            strokeLinecap="round"
        />
        <rect
            x="0.5"
            y="0.5"
            width="14"
            height="14"
            rx="3.5"
            stroke="#EAB03D"
        />
    </svg>,
    "HighFrequencyLess",
);
