import useNotifications from "../state/useNotifications";

export default function useAudioFilesValidation() {
    const { addNotification } = useNotifications();

    return {
        validateAudioFiles(
            files: FileList | null,
            onSuccess: (file: File) => void,
        ) {
            if (files === null || files.length === 0) {
                addNotification({
                    severity: "error",
                    message: "No file selected",
                });
            } else if (files.length > 1) {
                addNotification({
                    severity: "error",
                    message: "More than one file selected",
                });
            } else {
                const file = files[0];

                if (!file.name.match(/.*\.wav$/)) {
                    addNotification({
                        severity: "error",
                        message: "Not a WAV file",
                    });
                } else if (file.size > 200 * 1024 ** 2) {
                    addNotification({
                        severity: "error",
                        message: `File size should not be greater than 200MB.`,
                    });
                } else {
                    onSuccess(file);
                }
            }
        },
    };
}
