import { MasteringPriceWithTax } from "../model/MasteringPriceWithTax";
import formatPrice from "./formatPrice";

export default function formatTotalPrice(
    priceWithTax: MasteringPriceWithTax,
): string {
    if (priceWithTax.tax.status === "NOT_CALCULATED_YET") {
        return `${formatPrice(priceWithTax.amountExcludingTaxInCents)} (+\u00A0taxes)`;
    }
    return `${formatPrice(priceWithTax.amountExcludingTaxInCents + priceWithTax.tax.taxInCents)}`;
}
