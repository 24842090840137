import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import baseThemeOptions from "./baseThemeOptions";
import colors from "./colors";

export default createTheme(
    deepmerge(baseThemeOptions, {
        palette: {
            mode: "light",
            background: {
                default: colors.newColors.neutrals.n0,
                paper: colors.neutrals.n0,
            },
            primary: {
                main: colors.newColors.primary,
                dark: colors.primary.p3,
                contrastText: colors.neutrals.n0,
            },
            warning: {
                main: colors.yellow.y1,
            },
            error: {
                main: colors.red.r3,
            },
        },
    }),
);
