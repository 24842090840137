const trackFileErrors: Record<string, string | undefined> = {
    BAD_CHANNEL_COUNT: "Only stereo files are supported.",
    BAD_BIT_DEPTH:
        "Incorrect bit depth. Only 16, 24, or 32Bits files are accepted.",
    ZERO_FRAMES:
        "The audio file contains no audio frame, or its metadata is corrupted.",
    TRUNCATED_AUDIO_FILE: "It seems this file is incomplete.",
    CORRUPTED_DATA_SIZE_WAV_HEADER:
        "It seems this file has been corrupted by a known FL Studio bug, please update your DAW and make a new export.",
    SILENT_AUDIO: "The audio file is silent.",
};

export default function getTrackFileErrorMessage(
    apiCode: string,
): string | undefined {
    return trackFileErrors[apiCode];
}
