import { atom } from "jotai";
import { Mastering } from "../../../model/Mastering";
import masteringListAtom from "./masteringListAtom";

export type AudioType = "ORIGINAL" | "MASTERED";

const currentMasteringIdAtom = atom<number | undefined>(undefined);

const currentMastering = atom(
    (get): Mastering | undefined => {
        const masteringId = get(currentMasteringIdAtom);
        const masteringList = get(masteringListAtom.masterings);

        if (
            masteringId === undefined ||
            masteringList === "MASTERING_NOT_INITIALIZED"
        ) {
            return undefined;
        }
        return masteringList.find((m) => m.id === masteringId);
    },
    (_get, set, mastering: Mastering | undefined) => {
        set(currentMasteringIdAtom, mastering?.id);
    },
);

export default {
    currentMastering,
    selectedAudioType: atom<AudioType>("MASTERED"),
    play: atom(false),
    currentTimeInSeconds: atom(0),
    volume: atom(1),
    levelMatching: atom(false),
    isAudioSourceLoaded: atom(false),
};
