import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg fill="none" viewBox="0 0 15 15">
        <rect
            x="0.5"
            y="0.5"
            width="14"
            height="14"
            rx="3.5"
            stroke="#EAB03D"
        />
        <path d="M3 7.5H12" stroke="#EAB03D" strokeLinecap="round" />
    </svg>,
    "HighFrequencyDefaultIcon",
);
