import { Typography } from "@mui/material";
import { MasteringPriceWithTax } from "../../../../model/MasteringPriceWithTax";
import { MasteringType } from "../../../../model/MasteringType";
import formatTotalPrice from "../../../formatTotalPrice";
import PayPerUseIcon from "../../../icons/PayPerUseIcon";
import { LoadedServiceDetailsComputed } from "../../../state/useServiceDetails";
import colors from "../../../theme/colors";

export default function MasteringCost({
    isPayPerUseActive,
    serviceDetails,
    masteringPriceWithTax,
    masteringType,
}: {
    isPayPerUseActive: boolean;
    serviceDetails: LoadedServiceDetailsComputed;
    masteringPriceWithTax: MasteringPriceWithTax;
    masteringType: MasteringType;
}) {
    const { isNextInitialMasterFree, isNextRemasterFree } = serviceDetails;

    return (
        <div
            aria-label="Next master pricing"
            style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
            }}
        >
            <PayPerUseIcon
                style={
                    isPayPerUseActive
                        ? { fill: "url(#blueToMagentaGradient)" }
                        : { fill: colors.neutrals.n1 }
                }
            />
            <Typography>
                {(
                    masteringType === "INITIAL_MASTER"
                        ? isNextInitialMasterFree
                        : isNextRemasterFree
                )
                    ? "Free Master"
                    : `${formatTotalPrice(masteringPriceWithTax)} Master`}
            </Typography>
        </div>
    );
}
