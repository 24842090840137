import * as Sentry from "@sentry/browser";

import config from "./config";

type State = "NOT_INITIALIZED" | "ENABLED" | "DISABLED";
let state: State = "NOT_INITIALIZED";

function captureException(error: unknown) {
    ensureInitialized();

    const filteredOut = (error as any)?.response?.status === 401;
    console.error(
        error,
        filteredOut ? "(not reported to Sentry)" : "(reported to Sentry",
    );

    if (state === "ENABLED" && !filteredOut) {
        Sentry.captureException(error);
    }
}

function ensureInitialized() {
    if (state === "NOT_INITIALIZED") {
        const { enabled } = config.get().sentry;

        if (enabled) {
            Sentry.init({
                dsn: config.get().sentry.dsn!,
                environment: config.get().sentry.environment!,
            });

            Sentry.setTag("app", "front-office");

            state = "ENABLED";
        } else {
            state = "DISABLED";
        }
    }
}

export default {
    captureException,
};
