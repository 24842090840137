import { Box, Rating } from "@mui/material";
import { useState } from "react";

import StarBorderIcon from "../../icons/StarBorderIcon";
import StarIcon from "../../icons/StarIcon";

export default function StarRating({
    rating,
    setRating,
    disabled,
}: {
    rating: number | null;
    setRating: (newRating: number | null) => void;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
}) {
    const labels = [
        "😣 Very bad",
        "😒 Bad",
        "🙂 Fine",
        "😊 Good",
        "🥰 Very good",
    ];

    const [hover, setHover] = useState(-1);

    let ratingValue: string | null = null;

    if (rating != null) {
        ratingValue = labels[rating - 1];
    }

    // /!\ Be careful when you modify code related to hovering /!\
    // Due to limitations with jsdom, this feature cannot be properly tested
    if (hover !== -1) {
        ratingValue = labels[hover - 1];
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <Rating
                size="small"
                name="rating"
                value={rating}
                onChange={(_event, newValue) => {
                    setRating(newValue);
                }}
                onChangeActive={(_event, newValue) => {
                    setHover(newValue);
                }}
                icon={<StarIcon fontSize="inherit" color="primary" />}
                emptyIcon={
                    <StarBorderIcon fontSize="inherit" color="primary" />
                }
                disabled={disabled}
                sx={{
                    ".MuiRating-icon": {
                        margin: 1,
                    },
                }}
            />
            {ratingValue != null && (
                <Box sx={{ marginLeft: "30px" }}>{ratingValue}</Box>
            )}
        </Box>
    );
}
