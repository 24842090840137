import { ReactNode } from "react";

export default function PageContent({ children }: { children: ReactNode }) {
    return (
        <div
            style={{
                paddingTop: "20px",
                height: "100%",
                maxHeight: "100%",
                overflowY: "auto",
            }}
        >
            {children}
        </div>
    );
}
