import { styled, Switch } from "@mui/material";
import { SwitchProps } from "@mui/material/Switch/Switch";
import { CSSProperties } from "react";

const StyledSwitch = styled(Switch)(({ theme }) => ({
    width: 29 + 18,
    height: 17 + 18,
    "& .MuiSwitch-track": {
        border: "solid 2px white",
        width: 29,
        height: 17,
    },
    "& .MuiSwitch-switchBase": {
        top: 7,
        left: 7,
        padding: 6,
        "& .MuiSwitch-thumb": {
            color: "white",
            width: 9,
            height: 9,
        },
    },
    "& > .Mui-checked": {
        transform: "translateX(12px)",
        "& + .MuiSwitch-track": {
            background: "none",
        },
        "& .MuiSwitch-thumb": {
            color: theme.palette.primary.main,
        },
    },
}));

export default function ParameterToggleSwitch({
    leftLabel,
    rightLabel,
    ariaLabel,
    switchProps,
    style,
    dataTestid,
}: {
    leftLabel: string;
    rightLabel: string;
    ariaLabel: string;
    switchProps: SwitchProps;
    style?: CSSProperties;
    dataTestid?: string;
}) {
    return (
        <div
            style={{
                ...style,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontWeight: 700,
            }}
        >
            <span>{leftLabel}</span>
            <StyledSwitch
                data-testid={dataTestid}
                checked={switchProps.checked}
                onChange={switchProps.onChange}
                inputProps={{
                    "aria-label": ariaLabel,
                }}
            />
            <span>{rightLabel}</span>
        </div>
    );
}
