import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg
        width="24"
        height="24"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.95839 8.12109C5.37983 8.12109 5.72148 8.46274 5.72148 8.88418V13.249C5.72148 13.6705 5.37983 14.0121 4.95839 14.0121C4.53696 14.0121 4.19531 13.6705 4.19531 13.249V8.88418C4.19531 8.46274 4.53696 8.12109 4.95839 8.12109Z"
            fill="url(#paint0_linear_927_51022)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.01113 6.66602C8.43257 6.66602 8.77421 7.00766 8.77421 7.4291V14.6936C8.77421 15.1151 8.43257 15.4567 8.01113 15.4567C7.58969 15.4567 7.24805 15.1151 7.24805 14.6936V7.4291C7.24805 7.00766 7.58969 6.66602 8.01113 6.66602Z"
            fill="url(#paint1_linear_927_51022)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0619 5.21289C11.4833 5.21289 11.825 5.55453 11.825 5.97597V16.1504C11.825 16.5718 11.4833 16.9135 11.0619 16.9135C10.6405 16.9135 10.2988 16.5718 10.2988 16.1504V5.97597C10.2988 5.55453 10.6405 5.21289 11.0619 5.21289Z"
            fill="url(#paint2_linear_927_51022)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1146 6.66602C14.5361 6.66602 14.8777 7.00766 14.8777 7.4291V14.6936C14.8777 15.1151 14.5361 15.4567 14.1146 15.4567C13.6932 15.4567 13.3516 15.1151 13.3516 14.6936V7.4291C13.3516 7.00766 13.6932 6.66602 14.1146 6.66602Z"
            fill="url(#paint3_linear_927_51022)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.1674 8.12109C17.5888 8.12109 17.9305 8.46274 17.9305 8.88418V13.249C17.9305 13.6705 17.5888 14.0121 17.1674 14.0121C16.7459 14.0121 16.4043 13.6705 16.4043 13.249V8.88418C16.4043 8.46274 16.7459 8.12109 17.1674 8.12109Z"
            fill="url(#paint4_linear_927_51022)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.00184 2.00184C3.35959 0.6441 5.39131 0.125 8.01017 0.125H14.1148C16.7337 0.125 18.7654 0.6441 20.1232 2.00184C21.4809 3.35959 22 5.39131 22 8.01017V14.1148C22 16.7337 21.4809 18.7654 20.1232 20.1232C18.7654 21.4809 16.7337 22 14.1148 22H8.01017C5.39131 22 3.35959 21.4809 2.00184 20.1232C0.6441 18.7654 0.125 16.7337 0.125 14.1148V8.01017C0.125 5.39131 0.6441 3.35959 2.00184 2.00184ZM3.081 3.081C2.1495 4.0125 1.65116 5.54183 1.65116 8.01017V14.1148C1.65116 16.5832 2.1495 18.1125 3.081 19.044C4.0125 19.9755 5.54183 20.4738 8.01017 20.4738H14.1148C16.5832 20.4738 18.1125 19.9755 19.044 19.044C19.9755 18.1125 20.4738 16.5832 20.4738 14.1148V8.01017C20.4738 5.54183 19.9755 4.0125 19.044 3.081C18.1125 2.1495 16.5832 1.65116 14.1148 1.65116H8.01017C5.54183 1.65116 4.0125 2.1495 3.081 3.081Z"
            fill="url(#paint5_linear_927_51022)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_927_51022"
                x1="18.0865"
                y1="8.34417"
                x2="16.857"
                y2="4.29421"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4DFFDF" />
                <stop offset="1" stopColor="#4DA1FF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_927_51022"
                x1="21.1392"
                y1="6.99889"
                x2="18.6584"
                y2="1.52233"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4DFFDF" />
                <stop offset="1" stopColor="#4DA1FF" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_927_51022"
                x1="24.19"
                y1="5.65596"
                x2="20.3053"
                y2="-0.786985"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4DFFDF" />
                <stop offset="1" stopColor="#4DA1FF" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_927_51022"
                x1="27.2427"
                y1="6.99889"
                x2="24.7619"
                y2="1.52233"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4DFFDF" />
                <stop offset="1" stopColor="#4DA1FF" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_927_51022"
                x1="30.2955"
                y1="8.34417"
                x2="29.066"
                y2="4.29421"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4DFFDF" />
                <stop offset="1" stopColor="#4DA1FF" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_927_51022"
                x1="199.232"
                y1="0.953338"
                x2="197.948"
                y2="-15.3702"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4DFFDF" />
                <stop offset="1" stopColor="#4DA1FF" />
            </linearGradient>
        </defs>
    </svg>,
    "AudioFile",
);
