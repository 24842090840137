import { z } from "zod";

export const previewStatusSchema = z.enum([
    "CREATED",
    "GENERATING",
    "GENERATED",
    "GENERATION_VERSION_MISMATCH_ERROR",
    "GENERATION_OTHER_ERROR",
]);

type PreviewStatus = z.infer<typeof previewStatusSchema>;

export function isPreviewInFinalStatus(status: PreviewStatus): boolean {
    const finalStatuses = [
        "GENERATED",
        "GENERATION_VERSION_MISMATCH_ERROR",
        "GENERATION_OTHER_ERROR",
    ];
    return finalStatuses.includes(status);
}
