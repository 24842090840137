import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Mastering, isMasteringInFinalStatus } from "../../../model/Mastering";
import DeleteIcon from "../../icons/DeleteIcon";
import DownloadIcon from "../../icons/DownloadIcon";
import RemasterIcon from "../../icons/RemasterIcon";
import StarBorderIcon from "../../icons/StarBorderIcon";
import StarIcon from "../../icons/StarIcon";
import useDeleteMasteringDialog from "./useDeleteMasteringDialog";

export default function MasteringListEntryMenu({
    index,
    mastering,
    onDeleteSuccessful,
    onDownload,
    onRate,
    isMasteringSuccessful,
    showRemasterDialog,
    allActionsInMoreMenu,
}: {
    mastering: Mastering;
    index: number;
    onDeleteSuccessful: () => void;
    onDownload: () => void;
    onRate: () => void;
    isMasteringSuccessful: boolean;
    showRemasterDialog: (mastering: Mastering) => void;
    allActionsInMoreMenu: boolean;
}) {
    const { deleteMasteringDialogElement, openDeleteMasteringDialog } =
        useDeleteMasteringDialog({
            mastering,
            onMasteringDeleted: onDeleteSuccessful,
        });

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuAnchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    function onNewMasterClick() {
        showRemasterDialog(mastering);
        closeMenu();
    }

    function closeMenu() {
        setMenuAnchorEl(null);
    }

    return (
        <>
            <Tooltip title="More actions">
                <IconButton
                    id={`open-menu-more-actions-${index}`}
                    aria-haspopup="true"
                    aria-expanded={isMenuOpen ? "true" : undefined}
                    onClick={handleClick}
                >
                    <MoreHorizIcon style={{ fontSize: "18px" }} />
                </IconButton>
            </Tooltip>
            <Menu
                id={`more-actions-menu-${index}`}
                anchorEl={menuAnchorEl}
                open={isMenuOpen}
                onClose={() => {
                    closeMenu();
                }}
                MenuListProps={{
                    "aria-labelledby": `more-actions-button-${index}`,
                }}
                sx={{
                    marginTop: "8px",
                    "& .MuiSvgIcon-root": { width: 18, height: 18 },
                }}
            >
                {allActionsInMoreMenu && (
                    <MenuItem
                        disabled={!isMasteringSuccessful}
                        onClick={() => {
                            onDownload();
                            closeMenu();
                        }}
                    >
                        <ListItemIcon>
                            <DownloadIcon />
                        </ListItemIcon>
                        <ListItemText>Download</ListItemText>
                    </MenuItem>
                )}
                {allActionsInMoreMenu && (
                    <MenuItem
                        disabled={!isMasteringSuccessful}
                        onClick={() => {
                            onNewMasterClick();
                        }}
                    >
                        <ListItemIcon>
                            <RemasterIcon />
                        </ListItemIcon>
                        <ListItemText>Remaster</ListItemText>
                    </MenuItem>
                )}
                <MenuItem
                    disabled={!isMasteringSuccessful}
                    onClick={() => {
                        onRate();
                        closeMenu();
                    }}
                >
                    <ListItemIcon>
                        {mastering.feedback === null ? (
                            <StarBorderIcon />
                        ) : (
                            <StarIcon />
                        )}
                    </ListItemIcon>
                    <ListItemText>
                        {mastering.feedback === null ? "Rate" : "View rating"}
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={!isMasteringInFinalStatus(mastering.status)}
                    onClick={() => {
                        openDeleteMasteringDialog();
                        closeMenu();
                    }}
                >
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>

            {deleteMasteringDialogElement}
        </>
    );
}
