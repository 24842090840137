window.dataLayer = window.dataLayer || [];

export default {
    trackSignIn(email: string): void {
        window.dataLayer!.push({
            event: "sign-in",
            currentUserEmail: email,
        });
    },

    trackIncentiveVisible() {
        window.dataLayer!.push({
            event: "incentive-visible",
        });
    },

    trackIncentiveClosed() {
        window.dataLayer!.push({
            event: "incentive-closed",
        });
    },

    trackIncentiveClicked() {
        window.dataLayer!.push({
            event: "incentive-clicked",
        });
    },
};
