import { Autocomplete, TextField } from "@mui/material";
import { Country } from "../../../model/Country";

export default function CountrySelect({
    countries,
    countryName,
    setCountry,
    disabled,
    required,
    error,
    helperText,
}: {
    countries: Country[];
    countryName: string | null;
    setCountry: (country: Country) => void;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    helperText?: string | null;
}) {
    function findCountry(theCountryName: string | null) {
        return countries?.find((country) => country.name === theCountryName);
    }

    return (
        <Autocomplete
            fullWidth
            disabled={disabled}
            value={countryName}
            onChange={(_event, value) => {
                const country = findCountry(value);
                if (country) {
                    setCountry(country);
                }
            }}
            renderInput={(params) => (
                <TextField
                    label="Country"
                    required={required}
                    error={error}
                    helperText={helperText}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                />
            )}
            options={countries.map((country) => country.name)}
        />
    );
}
