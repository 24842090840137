import { Divider, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import smallLogoPath from "../assets/logo-small.svg";
import logoPath from "../assets/logo.svg";
import colors from "../theme/colors";

export default function Branding() {
    const theme = useTheme();
    const shouldDisplaySmallLogo = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Link
            to="/"
            style={{
                marginRight: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                textDecoration: "none",
            }}
        >
            {shouldDisplaySmallLogo ? (
                <img
                    style={{
                        height: "44px",
                    }}
                    src={smallLogoPath}
                    alt="Virtu's Logo"
                />
            ) : (
                <>
                    <img
                        style={{
                            height: "19px",
                        }}
                        src={logoPath}
                        alt="Virtu's Logo"
                    />
                    <Divider orientation="vertical" variant="fullWidth" />
                    <h1
                        style={{
                            color: colors.neutrals.n1,
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "17px",
                            lineHeight: "20px",
                            letterSpacing: "0.04em",
                        }}
                    >
                        Assisted Mastering by Slate Digital
                    </h1>
                </>
            )}
        </Link>
    );
}
