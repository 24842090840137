import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.6927 8.83566C15.7283 8.42298 16.0916 8.11724 16.5043 8.15277C18.4163 8.31741 19.9137 8.90536 20.9074 10.1299C21.8842 11.3338 22.26 13.0163 22.26 15.11V15.24C22.26 17.5492 21.8022 19.361 20.5866 20.5766C19.3709 21.7922 17.5592 22.25 15.25 22.25H8.73998C6.4308 22.25 4.61903 21.7922 3.4034 20.5766C2.18777 19.361 1.72998 17.5492 1.72998 15.24V15.11C1.72998 13.0315 2.10069 11.3592 3.06183 10.1577C4.04031 8.93463 5.51591 8.33891 7.40037 8.16325C7.81279 8.1248 8.1783 8.42797 8.21674 8.84039C8.25519 9.25282 7.95202 9.61832 7.53959 9.65677C5.88405 9.8111 4.86465 10.3054 4.23313 11.0948C3.58427 11.9059 3.22998 13.1685 3.22998 15.11V15.24C3.22998 17.4008 3.66719 18.7191 4.46406 19.5159C5.26093 20.3128 6.57916 20.75 8.73998 20.75H15.25C17.4108 20.75 18.729 20.3128 19.5259 19.5159C20.3228 18.7191 20.76 17.4008 20.76 15.24V15.11C20.76 13.1537 20.4007 11.8862 19.7426 11.0751C19.1012 10.2847 18.0637 9.7926 16.3756 9.64724C15.9629 9.61171 15.6572 9.24835 15.6927 8.83566Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2V14.88C12.75 15.2942 12.4142 15.63 12 15.63C11.5858 15.63 11.25 15.2942 11.25 14.88V2C11.25 1.58579 11.5858 1.25 12 1.25Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.11969 12.1197C8.41259 11.8268 8.88746 11.8268 9.18035 12.1197L12 14.9393L14.8197 12.1197C15.1126 11.8268 15.5875 11.8268 15.8804 12.1197C16.1732 12.4126 16.1732 12.8874 15.8804 13.1803L12.5304 16.5303C12.2375 16.8232 11.7626 16.8232 11.4697 16.5303L8.11969 13.1803C7.8268 12.8874 7.8268 12.4126 8.11969 12.1197Z"
        />
    </>,
    "DownloadIcon",
);
