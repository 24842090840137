import config from "../config";

export default function getAuthenticationRedirectUrl(props?: {
    allowAutoLogin?: boolean;
    authenticationType?: "sign-in" | "sign-up";
}) {
    const serverUrl = config.get().authenticationServerUrl;
    const sourceUrl = encodeURIComponent(window.location.href);
    const authenticationType = props?.authenticationType || "sign-in";
    if (serverUrl) {
        return `${serverUrl}/authenticate/${authenticationType}?externalRedirectUrl=${sourceUrl}${
            props?.allowAutoLogin ? "&allowAutoLogin=true" : ""
        }`;
    }

    return `/authentication-stub?externalRedirectUrl=${sourceUrl}`;
}
