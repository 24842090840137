import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg fill="none" viewBox="0 0 15 15">
        <path
            d="M1 1C1 1 3 2 7.5 2C12 2 14 1 14 1"
            stroke="#4DA1FF"
            strokeLinecap="round"
        />
        <path
            d="M14 14C14 14 12 13 7.5 13C3 13 1 14 1 14"
            stroke="#4DFDDF"
            strokeLinecap="round"
        />
        <path
            d="M7.5 3.5V11.5M5 4.5V10.5M10 4.5V10.5M12.5 5.5V9.5M2.5 5.5V9.5"
            stroke="url(#paint0_linear_240_204)"
            strokeLinecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_240_204"
                x1="7.5"
                y1="3.5"
                x2="7.5"
                y2="11.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4DA1FF" />
                <stop offset="1" stopColor="#4DFEDF" />
            </linearGradient>
        </defs>
    </svg>,
    "CompressionBalance",
);
