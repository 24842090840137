import { Box, CircularProgress, Tooltip } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";
import { MasteringStatus } from "../../../model/Mastering";
import ErrorIcon from "../../icons/ErrorIcon";
import TickCircleIcon from "../../icons/TickCircleIcon";
import colors from "../../theme/colors";

const StatusIconImage = forwardRef(
    (
        {
            isMasteringSuccessful,
            isMasteringFinished,
            hasUnexpectedError,
            ...otherProps
        }: {
            isMasteringSuccessful: boolean;
            isMasteringFinished: boolean;
            hasUnexpectedError: boolean;
        },
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const size = 16;
        let component;

        if (isMasteringSuccessful) {
            component = (
                <TickCircleIcon
                    sx={{
                        fill: colors.green.g2,
                        width: size,
                        height: size,
                    }}
                />
            );
        } else if (
            (isMasteringFinished && !isMasteringSuccessful) ||
            hasUnexpectedError
        ) {
            component = (
                <ErrorIcon
                    sx={{
                        fill: colors.red.r2,
                        width: size,
                        height: size,
                    }}
                />
            );
        } else {
            component = (
                <CircularProgress data-testid="Loading Icon" size={size} />
            );
        }

        return (
            <Box
                ref={ref}
                sx={{
                    width: { size },
                    height: { size },
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                {...otherProps}
            >
                {component}
            </Box>
        );
    },
);

export default function StatusIcon({
    masteringStatus,
    isMasteringSuccessful,
    isMasteringFinished,
    hasUnexpectedError,
}: {
    masteringStatus: MasteringStatus | null;
    isMasteringSuccessful: boolean;
    isMasteringFinished: boolean;
    hasUnexpectedError: boolean;
}) {
    const getStatus = () => {
        let statusLabel: string | undefined;
        if (masteringStatus === "MASTERING" || masteringStatus === "CREATED") {
            statusLabel = "Mastering";
        } else if (masteringStatus === "MASTERED") {
            statusLabel = "Mastered";
        } else if (
            masteringStatus === "MASTERING_OTHER_ERROR" ||
            hasUnexpectedError
        ) {
            statusLabel = "Failed";
        } else if (masteringStatus === "MASTERING_VERSION_MISMATCH_ERROR") {
            statusLabel =
                "The audio analysis algorithm version has changed since you uploaded the original file. Please upload it again to start a new analysis.";
        }

        return statusLabel;
    };

    return (
        <Box aria-label="status">
            <Tooltip role="status" title={getStatus() || ""}>
                <StatusIconImage
                    isMasteringSuccessful={isMasteringSuccessful}
                    isMasteringFinished={isMasteringFinished}
                    hasUnexpectedError={hasUnexpectedError}
                />
            </Tooltip>
        </Box>
    );
}
