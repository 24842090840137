import { useAtom } from "jotai";
import backend from "../../Backend";
import atoms from "./atoms";

export default function usePayPerUse() {
    const [payPerUse, setPayPerUse] = useAtom(atoms.payPerUse);

    return {
        payPerUse,
        async enablePayPerUse(paymentMethodNonce: string) {
            await backend.enablePayPerUse({ paymentMethodNonce });
            setPayPerUse({ enabled: true });
        },
        async disablePayPerUse() {
            await backend.disablePayPerUse();
            setPayPerUse({ enabled: false });
        },
    };
}
