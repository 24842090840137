import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LinearProgress, styled, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";

import Backend from "../../../Backend";
import { OriginalTrack } from "../../../model/OriginalTrack";
import poll from "../../../poll";
import AudioFileIcon from "../../icons/AudioFileIcon";
import FailureIcon from "../../icons/FailureIcon";
import useNotifications from "../../state/useNotifications";
import colors from "../../theme/colors";
import getTrackFileErrorMessage from "./getTrackFileErrorMessage";

const trailingIconStyle = {
    marginLeft: "13px",
    width: "34px",
    height: "34px",
};

const TrailingFailureIcon = styled(FailureIcon)(() => trailingIconStyle);

const SeparatorIcon = styled(ArrowForwardIcon)(() => ({
    marginLeft: "5px",
    marginRight: "5px",
    width: "15px",
    height: "15px",
}));

export default function AudioFileUploadBar({
    input,
}: {
    input: {
        type: "FILE_TO_UPLOAD";
        file: File;
        onUploadComplete: (event: { originalTrack: OriginalTrack }) => void;
    };
}) {
    type Status = { filename: string } & Phase;

    type Phase =
        | { phase: "PREPARING" }
        | { phase: "UPLOADING"; progress: number }
        | { phase: "UPLOAD_FAILED" }
        | { phase: "ANALYSIS_FAILED" }
        | { phase: "ANALYZING" }
        | { phase: "UPLOAD_AND_ANALYSIS_COMPLETE" }
        | { phase: "UPLOAD_OK" };

    const { addNotification } = useNotifications();

    const [status, setStatus] = useState<Status>();

    const statusRef = useRef<Status>();
    statusRef.current = status;

    useEffect(() => {
        function listenForUpdates(originalTrackId: number) {
            return poll({
                fn: () => Backend.getOriginalTrack(originalTrackId),
                stopCondition: (it) => it.status !== "ANALYZING",
                interval: 1000,
                maxAttempts: 15 * 60,
            });
        }

        if (input.type === "FILE_TO_UPLOAD") {
            const abortController = new AbortController();

            let uploadTimeoutId: ReturnType<typeof setTimeout>;

            (async () => {
                try {
                    setStatus({
                        phase: "PREPARING",
                        filename: input.file.name,
                    });

                    const originalTrack = await Backend.createOriginalTrack(
                        input.file.name,
                    );

                    const uploadUrl = await Backend.getOriginalTrackUploadUrl(
                        originalTrack.id,
                    );

                    setStatus({
                        phase: "UPLOADING",
                        progress: 0,
                        filename: input.file.name,
                    });

                    await axios.put(uploadUrl, input.file, {
                        signal: abortController.signal,

                        headers: {
                            "Content-Type": input.file.type,
                        },

                        onUploadProgress(progressEvent) {
                            const currentStatus = statusRef.current;

                            const progress = Math.round(
                                (progressEvent.loaded / progressEvent.total!) *
                                    100,
                            );

                            if (
                                currentStatus?.phase === "UPLOADING" &&
                                currentStatus.progress < progress
                            ) {
                                setStatus({
                                    phase: "UPLOADING",
                                    progress,
                                    filename: input.file.name,
                                });
                            }
                        },
                    });

                    setStatus({
                        phase: "ANALYZING",
                        filename: input.file.name,
                    });

                    await Backend.analyzeOriginalTrack(originalTrack.id);

                    const updatedOriginalTrack = await listenForUpdates(
                        originalTrack.id,
                    );

                    if (updatedOriginalTrack.status === "ANALYZED") {
                        setStatus({
                            phase: "UPLOAD_AND_ANALYSIS_COMPLETE",
                            filename: input.file.name,
                        });

                        input.onUploadComplete({
                            originalTrack: updatedOriginalTrack,
                        });

                        uploadTimeoutId = setTimeout(() => {
                            setStatus({
                                phase: "UPLOAD_OK",
                                filename: input.file.name,
                            });
                        }, 3000);
                    } else {
                        setStatus({
                            phase: "ANALYSIS_FAILED",
                            filename: input.file.name,
                        });
                    }
                } catch (e: any) {
                    if (e.message === "canceled") {
                        return;
                    }

                    const apiCode = e.response?.data?.code;

                    const apiCodeErrorMessage =
                        getTrackFileErrorMessage(apiCode);
                    if (apiCodeErrorMessage != null) {
                        addNotification({
                            severity: "error",
                            message: apiCodeErrorMessage,
                            manualDismiss: true,
                        });
                    } else if (
                        e.isAxiosError &&
                        e.message === "Network Error"
                    ) {
                        addNotification({
                            severity: "error",
                            message:
                                "There was an error during the upload. Please make sure to use a stable connection.",
                            manualDismiss: true,
                        });
                    } else {
                        console.error("Unable to upload original track", e);
                        addNotification({
                            severity: "error",
                            message:
                                "An unexpected error occurred. Please contact support if this persists.",
                            manualDismiss: true,
                        });
                    }
                    setStatus({
                        phase: "UPLOAD_FAILED",
                        filename: input.file.name,
                    });
                }
            })();

            return () => {
                abortController.abort();

                if (uploadTimeoutId) {
                    clearTimeout(uploadTimeoutId);
                }
            };
        }

        setStatus({
            phase: "UPLOAD_OK",
            filename: input.file.name,
        });

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    return (
        <div
            style={{
                width: "100%",
                color: colors.newColors.neutrals.n1,
            }}
        >
            {status &&
                (status.phase === "PREPARING" ||
                    status.phase === "ANALYZING") && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                fontSize: "12px",
                                fontWeight: "700",
                            }}
                        >
                            <span
                                style={{ color: colors.newColors.primary }}
                                id="analyzing-original-audio"
                            >
                                {status.phase === "PREPARING"
                                    ? "UPLOADING"
                                    : "ANALYZING"}
                            </span>
                            <SeparatorIcon
                                style={{ color: colors.newColors.primary }}
                            />
                            <ResponsiveText text={status.filename} />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                height: "19.5px",
                            }}
                        >
                            <LinearProgress
                                aria-labelledby="analyzing-original-audio"
                                style={{ flexGrow: "1" }}
                                variant="indeterminate"
                            />
                        </div>
                    </>
                )}
            {status && status.phase === "UPLOADING" && (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            fontSize: "12px",
                            fontWeight: "700",
                        }}
                    >
                        <span
                            style={{ color: colors.newColors.primary }}
                            id="uploading-original-audio"
                        >
                            UPLOADING
                        </span>
                        <SeparatorIcon
                            style={{ color: colors.newColors.primary }}
                        />
                        <ResponsiveText text={status.filename} />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            height: "19.5px",
                        }}
                    >
                        <LinearProgress
                            aria-labelledby="uploading-original-audio"
                            style={{ flexGrow: "1" }}
                            variant="determinate"
                            value={status.progress}
                        />
                        <div
                            style={{
                                marginLeft: "13px",
                                width: "36px",
                                fontSize: "13px",
                                fontWeight: "700",
                                color: colors.newColors.primary,
                                textAlign: "right",
                            }}
                        >
                            {status.progress}%
                        </div>
                    </div>
                </>
            )}
            {status &&
                (status.phase === "UPLOAD_FAILED" ||
                    status.phase === "ANALYSIS_FAILED") && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            fontSize: "17px",
                            fontWeight: "700",
                        }}
                    >
                        <LeadingAudioFileIcon />
                        <span style={{ color: "#FF564B", flexShrink: 0 }}>
                            UH OH,{" "}
                            {status.phase === "UPLOAD_FAILED"
                                ? "UPLOAD FAILED"
                                : "ANALYSIS FAILED"}
                        </span>
                        <SeparatorIcon style={{ color: "#FF564B" }} />
                        <ResponsiveText text={status.filename} />
                        <TrailingFailureIcon />
                    </div>
                )}
        </div>
    );
}

function ResponsiveText({ text }: { text: string }) {
    return (
        <Tooltip title={text}>
            <span
                style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }}
            >
                {text}
            </span>
        </Tooltip>
    );
}

function LeadingAudioFileIcon() {
    return (
        <div
            style={{
                marginRight: "16px",
                width: "32px",
                height: "32px",
                borderRadius: "4px",
                backgroundColor: colors.newColors.neutrals.n3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexShrink: 0,
            }}
        >
            <AudioFileIcon
                style={{
                    width: "22px",
                    height: "22px",
                }}
            />
        </div>
    );
}
