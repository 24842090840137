import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg
        width="24"
        height="24"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6776 3.14467C12.6458 3.67968 13.1253 4.80747 13.1253 6.17505V13.825C13.1253 15.1893 12.6454 16.3161 11.6782 16.8519C10.7108 17.3879 9.50091 17.197 8.34342 16.4713C8.34339 16.4713 8.34346 16.4713 8.34342 16.4713L5.91709 14.9507C5.73991 14.8468 5.5431 14.7917 5.35866 14.7917H4.16699C3.23732 14.7917 2.42527 14.5589 1.85005 13.9837C1.27483 13.4084 1.04199 12.5964 1.04199 11.6667V8.33338C1.04199 7.40371 1.27483 6.59165 1.85005 6.01644C2.42527 5.44122 3.23732 5.20838 4.16699 5.20838H5.35866C5.5431 5.20838 5.73992 5.15328 5.91711 5.04939L8.34332 3.52885C8.34336 3.52883 8.34329 3.52888 8.34332 3.52885C9.49982 2.80378 10.7097 2.60985 11.6776 3.14467ZM11.0731 4.23876C10.6535 4.00693 9.9508 3.99636 9.00733 4.58791L6.56753 6.11696L6.56108 6.12079C6.20643 6.33136 5.78887 6.45838 5.35866 6.45838H4.16699C3.43 6.45838 2.99205 6.6422 2.73393 6.90032C2.47582 7.15844 2.29199 7.59639 2.29199 8.33338V11.6667C2.29199 12.4037 2.47582 12.8417 2.73393 13.0998C2.99205 13.3579 3.43 13.5417 4.16699 13.5417H5.35866C5.78887 13.5417 6.20643 13.6687 6.56108 13.8793L6.56753 13.8831L9.00723 15.4121C9.94971 16.003 10.6524 15.9913 11.0724 15.7586C11.4928 15.5257 11.8753 14.9358 11.8753 13.825V6.17505C11.8753 5.05928 11.4923 4.47042 11.0731 4.23876Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6247 6.16692C14.9007 5.95962 15.2925 6.01532 15.4998 6.29133C17.1501 8.48868 17.1501 11.5113 15.4998 13.7087C15.2925 13.9847 14.9007 14.0404 14.6247 13.8331C14.3487 13.6258 14.293 13.234 14.5003 12.958C15.8166 11.2054 15.8166 8.79464 14.5003 7.042C14.293 6.766 14.3487 6.37421 14.6247 6.16692Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.1502 4.08357C16.4263 3.87634 16.818 3.93215 17.0253 4.2082C19.6005 7.63884 19.6005 12.3613 17.0253 15.792C16.818 16.068 16.4263 16.1238 16.1502 15.9166C15.8742 15.7094 15.8184 15.3176 16.0256 15.0415C18.267 12.0555 18.267 7.94464 16.0256 4.95862C15.8184 4.68256 15.8742 4.29079 16.1502 4.08357Z"
            fill="white"
        />
    </svg>,
    "VolumeHigh",
);
