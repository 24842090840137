import { useCallback, useState } from "react";
import {
    ConfirmationDialog,
    ConfirmationDialogProps,
} from "./ConfirmationDialog";

export default function useConfirmationDialog(props: ConfirmationDialogProps) {
    const [open, setOpen] = useState(false);

    return {
        confirmationDialogElement: (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ConfirmationDialog open={open} setOpen={setOpen} {...props} />
        ),
        openConfirmationDialog: useCallback(() => setOpen(true), [setOpen]),
    };
}
