import { ThemeOptions } from "@mui/material";
import ChevronDown from "../icons/ChevronDown";
import colors from "./colors";
import fonts from "./fonts";

const baseThemeOptions: ThemeOptions = {
    typography: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        h1: {
            fontSize: "34px",
            fontWeight: 600,
            lineHeight: "110%",
        },
        h2: {
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "150%",
        },
        h3: {
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "150%",
        },
        body1: {
            fontSize: 14,
        },
        body2: {
            fontSize: 12,
        },
        button: {
            fontWeight: "normal",
            lineHeight: 1.5,
            textTransform: "none",
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                focusRipple: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    transition:
                        "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    padding: "10px 24px",
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                centerRipple: false,
            },
        },
        MuiTab: {
            defaultProps: {
                sx: {
                    ".MuiTab-iconWrapper": {
                        marginRight: "13px",
                    },
                },
            },
            styleOverrides: {
                root: {
                    minHeight: "60px",
                    "&:first-of-type": {
                        borderTopLeftRadius: 4,
                    },
                    "&:last-of-type": {
                        borderTopRightRadius: 4,
                    },
                },
            },
        },
        MuiDialogTitle: {
            defaultProps: {
                sx: {
                    padding: "14px 25px",
                    fontSize: "21px",
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                },
            },
        },
        MuiDialogContent: {
            defaultProps: {
                sx: {
                    "@supports (-moz-appearance:none)": {
                        overflowY: "scroll",
                    },
                    padding: "14px 25px",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "14px",
                    "> :not(:first-child) /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */":
                        {
                            marginLeft: "14px",
                        },
                },
            },
        },
        MuiDivider: {
            defaultProps: {
                sx: {
                    margin: "0px 16px",
                },
            },
            styleOverrides: {
                root: {
                    "&.MuiDivider-vertical": {
                        height: "24px",
                    },
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                placement: "top",
            },
            styleOverrides: {
                tooltip: {
                    fontSize: 14,
                    maxWidth: "500px",
                    ".MuiTooltip-arrow::before": {
                        backgroundColor: "black",
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    height: "24px",
                },
                label: {
                    fontSize: "12px",
                    paddingLeft: "11px",
                    paddingRight: "11px",
                },
            },
        },
        MuiMenu: {
            defaultProps: {
                // We have to disable menu animations to prevent issues when running E2E tests from Puppeteer
                transitionDuration:
                    navigator.userAgent === "puppeteer" ? 0 : "auto",
                elevation: 0,
                PaperProps: {
                    style: {
                        padding: "0 0.5rem",
                    },
                },
            },
        },
        MuiMenuItem: {
            defaultProps: {
                sx: {
                    ".MuiListItemIcon-root": {
                        minWidth: "23px",
                    },
                },
            },
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    padding: 9,
                    width: 33 + 18,
                    height: 19 + 18,
                },
                track: {
                    width: 33,
                    height: 19,
                    borderRadius: 16,
                    background: "none",
                    opacity: "100%",
                },
                thumb: {
                    width: 11,
                    height: 11,
                    boxShadow: "none",
                },
                switchBase: {
                    top: 8,
                    left: 8,
                    padding: 5,
                    "&.Mui-checked": {
                        transform: "translateX(14px)",
                        "& + .MuiSwitch-track": {
                            opacity: "100%",
                        },
                    },
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    width: "calc(100% + 8px)",
                    margin: "-4px",
                    justifyContent: "space-between",
                },
                grouped: {
                    "&:not(:first-of-type), &:first-of-type": {
                        margin: "4px",
                        height: "70px",
                        borderRadius: "10px",
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    fontSize: 15,
                    fontWeight: 700,
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: "8px",
                    borderRadius: "5px",
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
        },
        MuiPopover: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                paper: {
                    padding: "20px 16px",
                    backgroundColor: colors.neutrals.n3,
                    boxShadow: "-2px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                },
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                popupIcon: <ChevronDown />,
                disableClearable: true,
                color: colors.neutrals.n6,
            },
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-root": {
                        padding: 0,
                        "& .MuiAutocomplete-input": {
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingRight: "16px",
                            paddingLeft: "16px",
                        },
                        "& .MuiAutocomplete-endAdornment": {
                            height: "100%",
                            top: 0,
                            right: "16px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            "& .MuiSvgIcon-root": {
                                width: "20px",
                                height: "20px",
                            },
                        },
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    "& .MuiInputLabel-root": {
                        ...fonts["h12/regular"],
                        position: "relative",
                        transform: "none",
                        marginBottom: "8px",
                    },

                    "& .MuiOutlinedInput-root": {
                        padding: "0px",

                        "& .MuiOutlinedInput-notchedOutline": {
                            top: 0,
                            "& legend": {
                                display: "none",
                            },
                        },
                        "& .MuiOutlinedInput-input": {
                            ...fonts["h16/regular"],
                            padding: "12px",
                            outlineColor: colors.neutrals.n2,
                            "&::placeholder": {
                                opacity: "1 !important",
                                ...fonts["h16/regular"],
                            },
                        },

                        "&:hover .Mui-disabled": {
                            cursor: "not-allowed !important",
                        },
                    },

                    "& .MuiFormHelperText-root": {
                        margin: "7px 0 0 0 ",
                        ...fonts["h12/regular"],
                    },
                },
            },
        },
        MuiSkeleton: {
            defaultProps: {
                animation: "wave",
            },
            styleOverrides: {
                root: {
                    margin: 0,
                    transform: "none",
                },
            },
        },
    },
};
export default baseThemeOptions;
