import { Badge, ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import BonusIcon from "../icons/BonusIcon";
import MasterIcon from "../icons/MasterIcon";
import RemasterIcon from "../icons/RemasterIcon";
import useServiceDetails from "../state/useServiceDetails";
import colors from "../theme/colors";
import QuotaDetailsPopover from "./QuotaDetailsPopover";

export default function AllQuotaDisplay() {
    const [initialMasterPopoverAnchorEl, setInitialMasterPopoverAnchorEl] =
        useState<HTMLButtonElement | null>(null);
    const initialMasterPopoverAnchorElRef = useRef<HTMLButtonElement | null>(
        null,
    );

    const [remasterPopoverAnchorEl, setRemasterPopoverAnchorEl] =
        useState<HTMLButtonElement | null>(null);
    const remasterPopoverAnchorElRef = useRef<HTMLButtonElement | null>(null);

    const [displayBonusTooltip, setDisplayBonusTooltip] =
        useState<boolean>(false);

    const { serviceDetails } = useServiceDetails();
    if (serviceDetails.status === "LOADING") {
        return null;
    }

    return (
        <div data-testid="all-quotas">
            {serviceDetails.hasAap && (
                <>
                    <IconButton
                        aria-label="Master quota"
                        color="default"
                        ref={initialMasterPopoverAnchorElRef}
                        onClick={() => {
                            setInitialMasterPopoverAnchorEl(
                                initialMasterPopoverAnchorElRef.current,
                            );
                        }}
                    >
                        <Badge
                            role="status"
                            aria-label="Master quota remaining"
                            badgeContent={
                                serviceDetails.initialMasterQuotaRemaining
                            }
                            showZero
                            sx={{
                                "& .MuiBadge-badge": {
                                    color: "black",
                                    fontWeight: "bold",
                                    background:
                                        colors.newColors.gradients.orangeToPink,
                                },
                            }}
                        >
                            <MasterIcon />
                        </Badge>
                    </IconButton>
                    <QuotaDetailsPopover
                        quotaType="INITIAL_MASTER"
                        anchorEl={initialMasterPopoverAnchorEl}
                        onClose={() => {
                            setInitialMasterPopoverAnchorEl(null);
                        }}
                    />

                    <IconButton
                        aria-label="Remaster quota"
                        color="default"
                        ref={remasterPopoverAnchorElRef}
                        onClick={() =>
                            setRemasterPopoverAnchorEl(
                                remasterPopoverAnchorElRef.current,
                            )
                        }
                    >
                        <Badge
                            role="status"
                            aria-label="Remaster quota remaining"
                            badgeContent={serviceDetails.remasterQuotaRemaining}
                            showZero
                            sx={{
                                "& .MuiBadge-badge": {
                                    color: "black",
                                    fontWeight: "bold",
                                    background:
                                        colors.newColors.gradients.orangeToPink,
                                },
                            }}
                        >
                            <RemasterIcon />
                        </Badge>
                    </IconButton>
                    <QuotaDetailsPopover
                        quotaType="REMASTER"
                        anchorEl={remasterPopoverAnchorEl}
                        onClose={() => setRemasterPopoverAnchorEl(null)}
                    />
                </>
            )}

            {serviceDetails.bonusRemaining > 0 && (
                <ClickAwayListener
                    onClickAway={() => setDisplayBonusTooltip(false)}
                >
                    <Tooltip title="Bonus masters" open={displayBonusTooltip}>
                        <IconButton
                            aria-label="Bonus masters"
                            color="default"
                            onClick={() =>
                                setDisplayBonusTooltip(!displayBonusTooltip)
                            }
                        >
                            <Badge
                                role="status"
                                aria-label="Bonus masters remaining"
                                badgeContent={serviceDetails.bonusRemaining}
                                sx={{
                                    "& .MuiBadge-badge": {
                                        color: "white",
                                        fontWeight: "bold",
                                        background:
                                            colors.newColors.gradients
                                                .pinkToPurple,
                                    },
                                }}
                            >
                                <BonusIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </ClickAwayListener>
            )}
        </div>
    );
}
