import { z } from "zod";
import { masteringPresetsSchema } from "../../../Backend";
import { originalTrackSchema } from "../../../model/OriginalTrack";
import { referenceTrackSchema } from "../../../model/ReferenceTrack";

const studioStateSchema = z.object({
    userId: z.number(),
    originalTrack: originalTrackSchema,
    referenceTrack: referenceTrackSchema.nullable(),
    masteringPresets: masteringPresetsSchema,
    masteringType: z.union([
        z.literal("INITIAL_MASTER"),
        z.literal("REMASTER"),
    ]),
});

const studioStateKey = "studioState";

export type StudioState = z.infer<typeof studioStateSchema>;

function save(state: StudioState) {
    window.localStorage.setItem(studioStateKey, JSON.stringify(state));
}

function retrieve(userId: number): StudioState | null {
    const state = window.localStorage.getItem(studioStateKey);
    if (state === null) return null;
    const safeParseResult = studioStateSchema.safeParse(JSON.parse(state));
    if (!safeParseResult.success) {
        console.warn(
            "The saved studio state has a different schema than the current one",
            safeParseResult.error,
        );
        return null;
    }

    if (safeParseResult.data.userId !== userId) {
        console.warn("The saved studio state relates to another user");
        return null;
    }

    return safeParseResult.data;
}

function drop() {
    window.localStorage.removeItem(studioStateKey);
}

export default { save, retrieve, drop };
