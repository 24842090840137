import { FunctionComponent, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
    addAuthenticatedBackendErrorListener,
    removeAuthenticatedBackendErrorListener,
} from "../AxiosConfiguration";
import config from "../config";
import { RegisteredUser } from "../model/User";
import useAuthentication from "./state/useAuthentication";
import useNotifications from "./state/useNotifications";

export default function Protected({
    component,
}: {
    component: FunctionComponent<{
        user: RegisteredUser;
    }>;
}) {
    const location = useLocation();
    useIsUnauthorized();
    const { authenticationState } = useAuthentication();
    switch (authenticationState.type) {
        case "REGISTERED_USER_AUTHENTICATING":
        case "GUEST_USER_AUTHENTICATING":
        case "NOT_INITIALIZED":
            throw new Error(
                `Unexpected authentication state: ${authenticationState.type}`,
            );
        case "NOT_AUTHENTICATED":
        case "GUEST_USER_AUTHENTICATED": {
            let redirectTo: string;
            if (config.get().guestPreviewEnabled) {
                redirectTo = "/home";
            } else {
                redirectTo = "/authentication";
            }

            return <Navigate to={`${redirectTo}${location.search}`} replace />;
        }
        case "REGISTERED_USER_AUTHENTICATED": {
            const Component = component;
            return <Component user={authenticationState.authenticatedUser} />;
        }
    }
}

function useIsUnauthorized() {
    const { addNotification } = useNotifications();
    const { logout } = useAuthentication();

    useEffect(() => {
        let errorReceived = false;
        const errorHandler = (error: any) => {
            if (errorReceived) {
                return;
            }
            errorReceived = true;

            if (error?.response?.status === 401) {
                addNotification({
                    severity: "error",
                    message: "Session expired",
                });
                logout();
            }
        };
        addAuthenticatedBackendErrorListener(errorHandler);

        return function cleanUp() {
            removeAuthenticatedBackendErrorListener(errorHandler);
        };
    }, [logout, addNotification]);
}
