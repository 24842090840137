import {
    Box,
    Button,
    Container,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import PageContent from "./PageContent";

export default function AuthenticationStubPage() {
    type SignInUser = {
        label: string;
        fakeShopCallbackToken: string;
    };

    const users: SignInUser[] = [
        {
            label: "Standard user",
            fakeShopCallbackToken: "no-aap-token",
        },
        {
            label: "AAP user",
            fakeShopCallbackToken: "success-token",
        },
        {
            label: "Administrator",
            fakeShopCallbackToken: "success-admin-token",
        },
        {
            label: "Non-existing user",
            fakeShopCallbackToken: "bad-token",
        },
        {
            label: "Create new standard user",
            fakeShopCallbackToken: "new-no-aap-user-token",
        },
        {
            label: "Create new AAP user",
            fakeShopCallbackToken: "new-user-token",
        },
    ];

    const [selectedUser, setSelectedUser] = useState(users[0]);

    const [searchParams] = useSearchParams();

    function signIn(user: SignInUser) {
        window.location.href = `${searchParams.get(
            "externalRedirectUrl",
        )}?token=${user.fakeShopCallbackToken}`;
    }

    return (
        <PageContent>
            <Container>
                <h1>Authentication stub</h1>
                <Box component="form" noValidate autoComplete="off">
                    <FormLabel>Select the user to sign in with:</FormLabel>
                    <RadioGroup
                        value={selectedUser.label}
                        onChange={(event) => {
                            const label = event.target.value;
                            setSelectedUser(
                                users.find((it) => it.label === label)!,
                            );
                        }}
                    >
                        {users.map((user) => (
                            <FormControlLabel
                                value={user.label}
                                label={user.label}
                                control={<Radio />}
                                key={user.label}
                            />
                        ))}
                    </RadioGroup>
                    <Button
                        sx={{ marginTop: "16px" }}
                        onClick={() => signIn(selectedUser)}
                        variant="contained"
                    >
                        Sign In
                    </Button>
                </Box>
            </Container>
        </PageContent>
    );
}
