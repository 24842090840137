import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import AudioCachingService from "../../../AudioCachingService";
import Backend, { MasteringPresets, PreviewResponse } from "../../../Backend";
import { MasteringType } from "../../../model/MasteringType";
import { isPreviewInFinalStatus } from "../../../model/Preview";
import { ReferenceTrack } from "../../../model/ReferenceTrack";
import poll from "../../../poll";
import sentryLogger from "../../../sentryLogger";
import playerAtoms, { AudioType } from "../../state/atoms/playerAtoms";
import useNotifications from "../../state/useNotifications";
import AudioWaveform from "../AudioWaveform";
import MasteringPlayerAudioSource from "../MasteringPlayerAudioSource";
import MatchingGainToggle, { MatchingGain } from "../MatchingGainToggle";
import OriginalMasteredToggle from "../OriginalMasteredToggle";
import PlayPauseButton from "../PlayPauseButton";

export default function Preview({
    originalTrackId,
    referenceTrack,
    presetParameters,
    previewFeatureAvailable,
    trackName,
    masteringType,
}: {
    originalTrackId: number;
    referenceTrack: ReferenceTrack | null;
    presetParameters: MasteringPresets;
    previewFeatureAvailable: boolean;
    trackName: string;
    masteringType: MasteringType;
}) {
    if (
        referenceTrack != null &&
        presetParameters.style !== "REFERENCE_TRACK"
    ) {
        throw new Error(
            "The reference track must not be defined when the style is not REFERENCE_TRACK",
        );
    }

    const [isLevelMatchingActive] = useAtom(playerAtoms.levelMatching);

    const { addNotification } = useNotifications();
    const waveformRef = useRef<HTMLDivElement>(null);
    const [waveformWidth, setWaveformWidth] = useState<number>();
    const handleResize = () => {
        if (waveformRef.current) {
            setWaveformWidth(waveformRef.current!.offsetWidth);
        }
    };
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const [isPlayRequested, setIsPlayRequested] = useState(false);
    const [previewDurationInSeconds, setPreviewDurationInSeconds] =
        useState<number>();
    const [previewAudioLoaded, setPreviewAudioLoaded] = useState(false);
    const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(0);
    const [originalWaveform, setOriginalWaveform] = useState([0.0]);
    const [masteredWaveform, setMasteredWaveform] = useState([0.0]);

    const [selectedAudioType, setSelectedAudioType] =
        useState<AudioType>("MASTERED");
    const [matchingGain, setMatchingGain] = useState<MatchingGain>({
        type: "NO_TRACK_LOADED_YET",
    });

    const [currentPreview, setCurrentPreview] =
        useState<PreviewResponse | null>(null);

    // this state fixes the error handler changes
    // when the parent component re-renders
    const [error, setError] = useState<any | null>();
    useEffect(() => {
        if (error) {
            const message =
                error.name === "NotAllowedError"
                    ? "The auto-play is blocked, you can enable it in Safari > Settings > Website > Auto-Play"
                    : "The preview failed due to an unexpected error";

            addNotification({
                severity: "error",
                message,
            });
            setError(null);
        }
    }, [error, addNotification]);

    const [requestedPlayOnce, setRequestedPlayOnce] = useState(false);
    const [
        requestedPlayOnceForCurrentPresets,
        setRequestedPlayOnceForCurrentPresets,
    ] = useState(false);

    let playButtonTooltipText;
    if (!previewFeatureAvailable) {
        playButtonTooltipText =
            "The preview feature is not available on older audio files. You can upload your audio file again to use this feature.";
    } else if (!isPlayRequested && !requestedPlayOnce) {
        playButtonTooltipText = "Load preview";
    } else if (
        !isPlayRequested &&
        requestedPlayOnce &&
        !requestedPlayOnceForCurrentPresets
    ) {
        playButtonTooltipText = "Reload preview";
    } else {
        playButtonTooltipText = null;
    }

    useEffect(() => {
        if (isPlayRequested) {
            setRequestedPlayOnce(true);
            setRequestedPlayOnceForCurrentPresets(true);
        }
    }, [isPlayRequested]);

    useEffect(() => {
        setIsPlayRequested(false);
        setRequestedPlayOnceForCurrentPresets(false);
        setPreviewAudioLoaded(false);
        setPreviewDurationInSeconds(undefined);
        setCurrentTimeInSeconds(0);
    }, [presetParameters]);

    function handleFinishedPreviewResponse(preview: PreviewResponse) {
        setCurrentPreview(preview);
        setMasteredWaveform(preview.waveform ?? [0.0]);
        setOriginalWaveform(preview.originalWaveform ?? [0.0]);

        if (preview.matchingGain == null) {
            setMatchingGain({ type: "NOT_AVAILABLE_ON_THIS_TRACK" });
        } else {
            setMatchingGain({ type: "AVAILABLE", value: preview.matchingGain });
        }

        setPreviewDurationInSeconds(preview.previewDurationInSeconds);
    }

    async function requestToPlayPreview() {
        setCurrentPreview(null);
        setIsPlayRequested(true);
        try {
            const preview = await Backend.createOrGetPreview(
                originalTrackId!,
                presetParameters,
                referenceTrack?.id ?? null,
            );

            if (preview.status === "CREATED") {
                await Backend.startPreview(preview.id);
            }

            if (isPreviewInFinalStatus(preview.status)) {
                handleFinishedPreviewResponse(preview);
            } else {
                const previewResponse = await poll({
                    fn: async () => {
                        const result = await Backend.getPreview(preview.id);
                        if (isPreviewInFinalStatus(result.status)) {
                            handleFinishedPreviewResponse(result);
                        }
                        return result;
                    },
                    stopCondition: (it: PreviewResponse) =>
                        isPreviewInFinalStatus(it.status),
                    interval: 1000,
                    maxAttempts: 15 * 60,
                });
                setPreviewDurationInSeconds(
                    previewResponse.previewDurationInSeconds,
                );
            }
        } catch (e) {
            setIsPlayRequested(false);
            sentryLogger.captureException(e);
            setError(e);
        }
    }

    function requestToPausePreview() {
        setIsPlayRequested(false);
    }

    const verticalLayout = useMediaQuery(useTheme().breakpoints.down("sm"));

    return (
        <div>
            <div style={{ display: "flex", marginBottom: "8px" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        maxWidth: "100%",
                        overflow: "hidden",
                    }}
                >
                    <div
                        style={{
                            fontSize: "14px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {trackName}
                    </div>
                </div>
                {masteringType === "REMASTER" && (
                    <Tooltip
                        title="All previous master versions of this track will be kept"
                        sx={{ marginLeft: "10px" }}
                    >
                        <InfoIcon />
                    </Tooltip>
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: verticalLayout ? "column" : "row",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Tooltip title={playButtonTooltipText ?? ""}>
                        <div
                            style={{
                                marginRight: "8px",
                            }}
                        >
                            <PlayPauseButton
                                playRequested={isPlayRequested}
                                isLoadingAudioSource={
                                    isPlayRequested && !previewAudioLoaded
                                }
                                onPauseClick={requestToPausePreview}
                                onPlayClick={requestToPlayPreview}
                                disabled={
                                    originalTrackId == null ||
                                    !previewFeatureAvailable ||
                                    (presetParameters.style ===
                                        "REFERENCE_TRACK" &&
                                        referenceTrack?.status !== "ANALYZED")
                                }
                                gradientBackground
                            />
                        </div>
                    </Tooltip>
                    <div
                        style={{
                            width: "55px",
                            marginRight: "8px",
                        }}
                    >
                        Preview
                    </div>
                    {currentPreview != null && (
                        <MasteringPlayerAudioSource
                            getOriginalFileUrl={
                                AudioCachingService.getPreviewOriginalFileUrl
                            }
                            getMasteredFileUrl={
                                AudioCachingService.getPreviewMasteredFileUrl
                            }
                            audioEntityId={currentPreview.id}
                            matchingGain={currentPreview.matchingGain}
                            loop
                            playRequested={isPlayRequested}
                            setPlayRequested={setIsPlayRequested}
                            setIsAudioSourceLoaded={setPreviewAudioLoaded}
                            currentTimeInSeconds={currentTimeInSeconds}
                            setCurrentTimeInSeconds={setCurrentTimeInSeconds}
                            selectedAudioType={selectedAudioType}
                            isLevelMatchingActive={isLevelMatchingActive}
                        />
                    )}
                    <div
                        ref={waveformRef}
                        style={{
                            borderRadius: "12px",
                            flexGrow: 1,
                            flexBasis: 0,
                            overflow: "hidden",
                        }}
                    >
                        {waveformWidth && (
                            <AudioWaveform
                                disabled={!previewAudioLoaded}
                                waveformWidth={waveformWidth - 100}
                                waveformHeight={40}
                                dataPoints={
                                    selectedAudioType === "ORIGINAL"
                                        ? originalWaveform
                                        : masteredWaveform
                                }
                                dataTestId={`preview-${
                                    selectedAudioType === "ORIGINAL"
                                        ? "original"
                                        : "mastered"
                                }`}
                                durationInSeconds={previewDurationInSeconds}
                                currentTimeInSeconds={currentTimeInSeconds}
                                onCurrentTimeChanged={setCurrentTimeInSeconds}
                            />
                        )}
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: verticalLayout ? "row" : "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <OriginalMasteredToggle
                        selectedAudioType={selectedAudioType}
                        changeSelectedAudioType={(newAudioType) =>
                            setSelectedAudioType(newAudioType)
                        }
                    />
                    <MatchingGainToggle matchingGain={matchingGain} />
                </div>
            </div>
        </div>
    );
}
