import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import colors from "./theme/colors";

export default function CloseButton({ onClose }: { onClose: () => void }) {
    return (
        <IconButton
            sx={{
                width: "34px",
                height: "34px",
                backgroundColor: colors.newColors.neutrals.n4,
                border: `1px ${colors.newColors.neutrals.n3} solid`,
                borderRadius: "3px",
            }}
            aria-label="close"
            onClick={() => onClose()}
        >
            <CloseIcon />
        </IconButton>
    );
}
