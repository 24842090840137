import { z } from "zod";

export type RegisteredUser = z.infer<typeof registeredUserSchema>;

const registeredUserSchema = z.object({
    id: z.number(),
    email: z.string(),
    slateDigitalUserId: z.number(),
    payPerUseEnabled: z.boolean(),
    permissions: z.array(z.enum(["SUPPORT"])),
});

export type GuestUser = z.infer<typeof guestUserSchema>;

const guestUserSchema = z.object({
    id: z.number(),
});

export type User = z.infer<typeof userSchema>;

export const userSchema = z.union([registeredUserSchema, guestUserSchema]);
