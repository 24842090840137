import { ForwardedRef, forwardRef } from "react";
import useAudioFilesValidation from "./useAudioFilesValidation";

export default forwardRef(
    (
        { onFileSelected }: { onFileSelected: (file: File) => void },
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        const { validateAudioFiles } = useAudioFilesValidation();

        return (
            <input
                ref={ref}
                type="file"
                accept=".wav"
                style={{ display: "none" }}
                onChange={(event) => {
                    const { target } = event;
                    validateAudioFiles(target.files, onFileSelected);

                    // Fix an issue on Chrome-based browsers
                    // eslint-disable-next-line no-param-reassign
                    target.value = "";
                }}
                onClick={(e) => e.stopPropagation()}
            />
        );
    },
);
