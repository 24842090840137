import axios from "axios";

import Backend from "./Backend";
import config, { Config } from "./config";
import gtm from "./gtm";
import main from "./view/main";

// eslint-disable-next-line no-underscore-dangle
(window as any).__VIRTU_DBG__ = { backend: Backend };

axios.get<Config>("/config.json").then((response) => {
    config.set(response.data);

    gtm.initialize();
    main.renderApplication();
});
