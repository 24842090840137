import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg fill="none" viewBox="0 0 15 15">
        <path
            d="M7.5 2.00002V13M5 3.37501V11.625M10 3.37501V11.625M12.5 4.75001V10.25M2.5 4.75001V10.25"
            stroke="#B0B0B0"
            strokeLinecap="round"
        />
    </svg>,
    "CompressionOff",
);
