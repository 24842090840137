import CreditCardIcon from "@mui/icons-material/CreditCard";
import EventIcon from "@mui/icons-material/Event";
import {
    Alert,
    Box,
    CircularProgress,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { create as createDropin } from "braintree-web-drop-in";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import Backend from "../Backend";
import colors from "./theme/colors";

export default function BraintreeDropinWrapper({
    onPaymentMethodRequestable,
    onNoPaymentMethodRequestable,
}: {
    onPaymentMethodRequestable: (nonceGetter: () => Promise<string>) => void;
    onNoPaymentMethodRequestable: () => void;
}) {
    const [braintreeClientTokenStatus, setBraintreeClientTokenStatus] =
        useState<
            | { type: "LOADING" }
            | { type: "LOADED"; token: string }
            | { type: "ERROR" }
        >({ type: "LOADING" });

    useEffect(() => {
        async function fetchBraintreeClientToken() {
            try {
                const token = await Backend.getBraintreeClientToken();
                setBraintreeClientTokenStatus({ type: "LOADED", token });
            } catch (error) {
                setBraintreeClientTokenStatus({ type: "ERROR" });
            }
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchBraintreeClientToken();
    }, []);

    if (braintreeClientTokenStatus.type === "LOADING") {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress aria-label="loading payment methods" />
            </div>
        );
    }

    if (braintreeClientTokenStatus.type === "ERROR") {
        return (
            <Alert severity="error">
                Cannot load payment form. Please try again later.
            </Alert>
        );
    }

    return isBraintreeStubToken(braintreeClientTokenStatus.token) ? (
        <BraintreeDropinStub
            onPaymentMethodRequestable={onPaymentMethodRequestable}
            onNoPaymentMethodRequestable={onNoPaymentMethodRequestable}
        />
    ) : (
        <BraintreeDropin
            braintreeClientToken={braintreeClientTokenStatus.token}
            onPaymentMethodRequestable={onPaymentMethodRequestable}
            onNoPaymentMethodRequestable={onNoPaymentMethodRequestable}
        />
    );
}

function isBraintreeStubToken(token: string): boolean {
    return (
        token === "STUBBED-BRAINTREE-CLIENT-TOKEN" ||
        token === "STUBBED-SHOP-CLIENT-TOKEN"
    );
}

function BraintreeDropinStub({
    onPaymentMethodRequestable,
    onNoPaymentMethodRequestable,
}: {
    onPaymentMethodRequestable: (nonceGetter: () => Promise<string>) => void;
    onNoPaymentMethodRequestable: () => void;
}) {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [cardNumber, setCardNumber] = useState("");
    const [date, setDate] = useState("");

    useEffect(() => {
        const strippedCardNumber = cardNumber.replace(/ /g, "");
        if (
            strippedCardNumber.match(/^\d{16}$/) &&
            date.match(/^(0[1-9]|1[012])\/\d\d$/)
        ) {
            onPaymentMethodRequestable(() =>
                Promise.resolve(`${strippedCardNumber}/${date}`),
            );
        } else {
            onNoPaymentMethodRequestable();
        }
    }, [
        cardNumber,
        date,
        onPaymentMethodRequestable,
        onNoPaymentMethodRequestable,
    ]);

    return (
        <Box>
            <InputMask
                value={cardNumber}
                onChange={(event) => {
                    setCardNumber(event.target.value);
                }}
                mask="9999 9999 9999 9999"
                maskPlaceholder={null}
            >
                <TextField
                    label="Card Number"
                    placeholder="●●●● ●●●● ●●●● ●●●●"
                    fullWidth
                    margin={smallScreen ? "normal" : "none"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CreditCardIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </InputMask>
            <InputMask
                value={date}
                onChange={(event) => {
                    setDate(event.target.value);
                }}
                mask="99/99"
                maskPlaceholder={null}
                beforeMaskedStateChange={({ previousState, nextState }) => {
                    let { value, selection } = nextState;
                    if (/^[2-9]/.test(value)) {
                        value = `0${value}`;
                        if (selection) {
                            selection = {
                                start: selection.start + 1,
                                end: selection.end + 1,
                            };
                        }
                    } else if (/^(00|1[3-9])/.test(value)) {
                        value = previousState.value;
                        selection = previousState.selection;
                    }
                    return {
                        value,
                        selection,
                    };
                }}
            >
                <TextField
                    label="Expiration date"
                    placeholder="MM/YY"
                    fullWidth
                    margin="normal"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EventIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </InputMask>
        </Box>
    );
}

function BraintreeDropin({
    braintreeClientToken,
    onPaymentMethodRequestable,
    onNoPaymentMethodRequestable,
}: {
    braintreeClientToken: string;
    onPaymentMethodRequestable: (nonceGetter: () => Promise<string>) => void;
    onNoPaymentMethodRequestable: () => void;
}) {
    const [braintreeContainer, setBraintreeContainer] =
        useState<HTMLDivElement | null>(null);

    useEffect(() => {
        async function loadBraintreeDropIn() {
            if (braintreeContainer != null) {
                const dropin = await createDropin({
                    authorization: braintreeClientToken,
                    container: braintreeContainer,
                    paypal: {
                        flow: "vault",
                    },
                });

                const nonceGetter = async () =>
                    (await dropin.requestPaymentMethod()).nonce;

                dropin.on("paymentMethodRequestable", () => {
                    onPaymentMethodRequestable(nonceGetter);
                });

                dropin.on("noPaymentMethodRequestable", () => {
                    onNoPaymentMethodRequestable();
                });

                if (dropin.isPaymentMethodRequestable()) {
                    onPaymentMethodRequestable(nonceGetter);
                }
            }
        }

        // noinspection JSIgnoredPromiseFromCall
        loadBraintreeDropIn();
    }, [
        braintreeContainer,
        braintreeClientToken,
        // in the next React version, with useEffectEvent we won't need to declare these dependencies,
        // and also we will be able to remove useCallback on the parent component
        onPaymentMethodRequestable,
        onNoPaymentMethodRequestable,
    ]);

    return (
        <Box
            data-testid="braintree-container"
            ref={setBraintreeContainer}
            sx={{
                color: "black",
                '[data-braintree-id="methods-label"], [data-braintree-id="other-ways-to-pay"], [data-braintree-id="choose-a-way-to-pay"]':
                    { color: colors.newColors.neutrals.n1 },
            }}
        />
    );
}
