import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    IconButton,
    Slide,
    Slider,
    SliderValueLabelProps,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { Mastering } from "../../model/Mastering";
import VolumeHighIcon from "../icons/VolumeHighIcon";
import playerAtoms from "../state/atoms/playerAtoms";
import colors from "../theme/colors";
import toSecondsAndMinutes from "../toSecondsAndMinutes";

import MasteringInformation from "./MasteringInformation";
import DownloadAction from "./MasteringListEntry/DownloadAction";
import MatchingGainToggle, { MatchingGain } from "./MatchingGainToggle";
import OriginalMasteredToggle from "./OriginalMasteredToggle";
import PlayPauseButton from "./PlayPauseButton";
import RateAction from "./RateAction";
import RemasterAction from "./RemasterAction";

export type StickyPlayerProps = {
    mastering: Mastering | undefined;
    isLoadingAudioSource: boolean;
    onPlayClick: () => void;
    onPauseClick: () => void;
    onDownloadClick: (mastering: Mastering) => void;
    onRemasterClick: (mastering: Mastering) => void;
    openFeedbackDialog: (mastering: Mastering) => void;
};

export default function StickyPlayer({
    mastering,
    isLoadingAudioSource,
    onPlayClick,
    onPauseClick,
    onDownloadClick,
    onRemasterClick,
    openFeedbackDialog,
}: StickyPlayerProps) {
    const [selectedAudioType, setSelectedAudioType] = useAtom(
        playerAtoms.selectedAudioType,
    );
    const [playRequested] = useAtom(playerAtoms.play);

    const theme = useTheme();
    const actionButtonsVisible = useMediaQuery(() =>
        theme.breakpoints.up("lg"),
    );
    const verticalLayout = useMediaQuery(useTheme().breakpoints.down("sm"));

    let matchingGain: MatchingGain;
    if (mastering == null) {
        matchingGain = { type: "NO_TRACK_LOADED_YET" };
    } else if (mastering.matchingGain == null) {
        matchingGain = { type: "NOT_AVAILABLE_ON_THIS_TRACK" };
    } else {
        matchingGain = { type: "AVAILABLE", value: mastering.matchingGain };
    }

    const originalMasteredToggle = (
        <div
            style={{
                display: "flex",
                flexDirection: verticalLayout ? "row" : "column",
                marginLeft: "34px",
                marginRight: "34px",
                alignItems: "center",
                width: verticalLayout ? "100%" : undefined,
                justifyContent: "space-between",
            }}
        >
            <OriginalMasteredToggle
                selectedAudioType={selectedAudioType}
                changeSelectedAudioType={setSelectedAudioType}
            />
            <MatchingGainToggle matchingGain={matchingGain} />
        </div>
    );

    const previousMastering = useRef<Mastering>();

    useEffect(() => {
        previousMastering.current = mastering;
    }, [mastering]);

    const displayedMastering = mastering ?? previousMastering.current;

    return (
        <Slide direction="up" in={mastering != null} mountOnEnter unmountOnExit>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    top: "auto",
                    left: 0,
                    bottom: 0,
                    right: 0,
                    backgroundColor: colors.neutrals.n4,
                    padding: "15.5px 0",
                }}
                data-testid="sticky-player"
            >
                {displayedMastering && (
                    <>
                        <Timeline
                            durationInSeconds={
                                displayedMastering.originalTrack
                                    .inputAudioFileMetadata!.durationInSeconds
                            }
                        />
                        <div
                            style={{
                                display: "flex",
                                flexGrow: 1,
                                maxWidth: "1400px",
                                alignItems: "center",
                                overflow: "hidden",
                                height: "100%",
                                flexDirection: verticalLayout
                                    ? "column"
                                    : "row",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexGrow: 1,
                                    flexBasis: 0,
                                    justifyContent: "center",
                                    maxWidth: "680px",
                                    overflow: "hidden",
                                    padding: "0px 32px",
                                }}
                            >
                                <MasteringInformation
                                    mastering={displayedMastering}
                                    variant="primary"
                                />
                                {actionButtonsVisible && originalMasteredToggle}
                            </div>
                            <PlayPauseButton
                                playRequested={playRequested}
                                isLoadingAudioSource={isLoadingAudioSource}
                                onPauseClick={onPauseClick}
                                onPlayClick={onPlayClick}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexGrow: 1,
                                    flexBasis: 0,
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                {!actionButtonsVisible &&
                                    originalMasteredToggle}
                                {actionButtonsVisible && (
                                    <>
                                        <VolumeController
                                            style={{ marginLeft: "34px" }}
                                        />
                                        <div
                                            style={{
                                                marginLeft: "32px",
                                                minWidth: "100px",
                                            }}
                                        >
                                            <RateAction
                                                mastering={displayedMastering}
                                                onClick={() =>
                                                    openFeedbackDialog(
                                                        displayedMastering,
                                                    )
                                                }
                                                fontSize={18}
                                            />
                                        </div>
                                        <DownloadAction
                                            onClick={() =>
                                                onDownloadClick(
                                                    displayedMastering,
                                                )
                                            }
                                            fontSize={18}
                                        />
                                        <RemasterAction
                                            onClick={() =>
                                                onRemasterClick(
                                                    displayedMastering,
                                                )
                                            }
                                            fontSize={18}
                                        />
                                    </>
                                )}
                            </Box>
                        </div>
                        <CloseButton
                            style={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                            }}
                        />
                    </>
                )}
            </div>
        </Slide>
    );
}

function Timeline({ durationInSeconds }: { durationInSeconds: number }) {
    const [currentTimeInSeconds, onCurrentTimeChanged] = useAtom(
        playerAtoms.currentTimeInSeconds,
    );
    const [sliderTimeInSeconds, setSliderTimeInSeconds] = useState<number>(0);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    useEffect(() => {
        if (!isDragging) {
            setSliderTimeInSeconds(currentTimeInSeconds);
        }
    }, [currentTimeInSeconds, isDragging]);

    return (
        <Slider
            sx={{
                position: "absolute",
                top: "-14px",
                "& .MuiSlider-rail": {
                    color: colors.neutrals.n2,
                    height: "1px",
                    opacity: "100%",
                },
                ".MuiSlider-track": {
                    color: colors.primary.p3,
                    height: "2px",
                },
                "@media (pointer: coarse)": {
                    top: "-21px",
                },
            }}
            size="small"
            max={durationInSeconds}
            value={sliderTimeInSeconds}
            onChange={(_event, value) => {
                // Called on mousedown and mousemove events
                setIsDragging(true);
                setSliderTimeInSeconds(value as number);
            }}
            onChangeCommitted={(_event, value) => {
                // Called on mouseup events
                onCurrentTimeChanged(value as number);
                setIsDragging(false);
            }}
            aria-label="current time cursor"
            valueLabelDisplay="auto"
            components={{
                ValueLabel: TooltipValueLabel,
            }}
            valueLabelFormat={toSecondsAndMinutes}
        />
    );
}

function TooltipValueLabel(props: SliderValueLabelProps) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} title={value}>
            {children}
        </Tooltip>
    );
}

function VolumeController({ style }: { style: CSSProperties }) {
    const [volume, setVolume] = useAtom(playerAtoms.volume);

    return (
        <div style={{ ...style, display: "flex", alignItems: "center" }}>
            <VolumeHighIcon style={{ padding: "2px" }} />
            <Slider
                aria-label="volume"
                value={volume * 100}
                onChange={(_event, value) => setVolume((value as number) / 100)}
                sx={{
                    width: "160px",
                    marginLeft: "8px",
                    color: colors.neutrals.n2,
                    "& .MuiSlider-rail": {
                        color: colors.neutrals.n0,
                        height: "4px",
                        opacity: "10%",
                    },
                    ".MuiSlider-track": {
                        color: colors.neutrals.n2,
                        height: "4px",
                        opacity: "100%",
                        border: "none",
                    },
                    "& .MuiSlider-thumb": {
                        opacity: 0,
                    },
                    '&:hover .MuiSlider-thumb, &:active .MuiSlider-thumb, & .MuiSlider-thumb[data-focusvisible="true"]':
                        {
                            opacity: 1,
                        },
                }}
            />
        </div>
    );
}

function CloseButton({ style }: { style: CSSProperties }) {
    const setCurrentMastering = useSetAtom(playerAtoms.currentMastering);
    const setCurrentTimeInSeconds = useSetAtom(
        playerAtoms.currentTimeInSeconds,
    );

    const closePlayer = useCallback(() => {
        setCurrentMastering(undefined);
        setCurrentTimeInSeconds(0);
    }, [setCurrentMastering, setCurrentTimeInSeconds]);

    return (
        <IconButton style={style} onClick={closePlayer} aria-label="close">
            <CloseIcon />
        </IconButton>
    );
}
