import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import SupportedAudioFileFormatSpecs from "../SupportedAudioFileFormatSpecs";
import FirstMasteringIcon from "../icons/FirstMasteringIcon";
import colors from "../theme/colors";
import NewMasteringButton from "./NewMasteringButton";
import useAudioFilesValidation from "./useAudioFilesValidation";

export default function FirstMasteringCard({
    onNewMasterFileSelected,
}: {
    onNewMasterFileSelected: (file: File) => void;
}) {
    const { validateAudioFiles } = useAudioFilesValidation();

    const [highlighted, setHighlighted] = useState(false);

    const className = classNames({ highlighted });

    const shouldHandleDragAndDrop = useMediaQuery(
        useTheme().breakpoints.up("md"),
    );

    return (
        <Container
            sx={{
                textAlign: "center",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                transition: "all 250ms",
                border: shouldHandleDragAndDrop
                    ? `1px dashed ${colors.newColors.neutrals.n1}`
                    : undefined,
                borderRadius: "10px",
                "&.highlighted": {
                    backgroundColor: "rgba(22, 129, 255, 0.1)",
                    border: `1px dashed ${colors.newColors.neutrals.n0}`,
                },
            }}
            aria-label="Upload your track here"
            className={className}
            onDragOver={(event) => {
                event.preventDefault();
                setHighlighted(true);
            }}
            onDragLeave={(event) => {
                event.preventDefault();
                setHighlighted(false);
            }}
            onDrop={(event) => {
                event.preventDefault();
                setHighlighted(false);

                validateAudioFiles(event.dataTransfer.files, (file) => {
                    onNewMasterFileSelected(file);
                });
            }}
        >
            {shouldHandleDragAndDrop && (
                <Typography
                    sx={{
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "27px",
                        color: colors.newColors.neutrals.n0,
                    }}
                >
                    Drag & drop your track here
                </Typography>
            )}
            <NewMasteringButton
                aria-label="Master your first track"
                sx={{ marginTop: "21px" }}
                variant="contained"
                size="large"
                startIcon={<FirstMasteringIcon />}
                onFileSelected={onNewMasterFileSelected}
            >
                {shouldHandleDragAndDrop && "or"} upload audio file to master
            </NewMasteringButton>
            <Typography
                sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "19px",
                    color: colors.newColors.neutrals.n1,
                    marginTop: "47px",
                }}
            >
                <SupportedAudioFileFormatSpecs />
            </Typography>
        </Container>
    );
}
