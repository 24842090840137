import { styled, Typography } from "@mui/material";

export default styled(Typography)(() => ({
    width: "max-content",
    padding: "2px 5px",
    borderRadius: "2px 2px 2px 2px",
    background: "white",
    transform: "skewY(-8deg) rotate(8deg)",
    color: "black",
    fontSize: "inherit",
    fontWeight: 600,
    textTransform: "uppercase",
}));
