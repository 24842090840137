import { Button, Collapse, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import storageService from "../StorageService";
import googleTagManager from "../gtm/googleTagManager";
import externalUrls from "./externalUrls";
import InformationIcon from "./icons/InformationIcon";
import colors from "./theme/colors";

export const AAP_PROMOTION_BANNER_IGNORE_DATE = "aapPromotionBannerIgnoreDate";
const ttlBeforeNewDisplay = 90 * 24 * 60 * 60 * 1000; // 90 days

export default function AapPromotionBanner() {
    const [display, setDisplay] = useState<boolean>(() => {
        const aapPromotionBannerIgnoreDate = storageService.getItem<number>(
            AAP_PROMOTION_BANNER_IGNORE_DATE,
        );

        const hideBannerUntilTimestamp = aapPromotionBannerIgnoreDate
            ? aapPromotionBannerIgnoreDate + ttlBeforeNewDisplay
            : null;

        return (
            hideBannerUntilTimestamp == null ||
            Date.now() > hideBannerUntilTimestamp
        );
    });

    useEffect(() => {
        if (display) {
            googleTagManager.trackIncentiveVisible();
        }
    }, [display]);

    function ignore() {
        setDisplay(false);
        googleTagManager.trackIncentiveClosed();
        storageService.setItem(AAP_PROMOTION_BANNER_IGNORE_DATE, Date.now());
    }

    return (
        <Collapse in={display}>
            <div
                aria-label="AAP Promotion"
                style={{
                    background: colors.primary.p6,
                    color: colors.neutrals.n0,
                    padding: "10px 24px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                }}
            >
                <Stack
                    direction="row"
                    spacing="8px"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <InformationIcon />
                    <Typography fontWeight="600">
                        Get more masters for less! Join All Access and save on
                        VIRTU.
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    spacing="8px"
                    style={{
                        display: "flex",
                        flexGrow: "1",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={ignore}
                    >
                        Ignore
                    </Button>
                    <Button
                        onClick={() => {
                            googleTagManager.trackIncentiveClicked();
                        }}
                        role="link"
                        size="small"
                        color="secondary"
                        variant="contained"
                        href={externalUrls.landingPage}
                    >
                        <Typography fontWeight="600">Learn More</Typography>
                    </Button>
                </Stack>
            </div>
        </Collapse>
    );
}
