import { LoudnessPreset } from "../../../model/Mastering";
import loudnessPresetMapping from "../loudnessPresetMapping";

export default function getClosestLoudnessPreset(
    referenceTrackLoudness: number,
): LoudnessPreset {
    const loudnessPresets: LoudnessPreset[] = ["LOW", "MEDIUM", "HIGH"];
    return loudnessPresets.sort((a, b) => {
        const aLoudnessDistance = Math.abs(
            loudnessPresetMapping[a].lufs - referenceTrackLoudness,
        );
        const bLoudnessDistance = Math.abs(
            loudnessPresetMapping[b].lufs - referenceTrackLoudness,
        );
        return aLoudnessDistance - bLoudnessDistance;
    })[0];
}
