export default <T>({
    fn,
    stopCondition,
    interval,
    maxAttempts,
}: {
    fn: () => Promise<T>;
    stopCondition: (result: T) => boolean;
    interval: number;
    maxAttempts: number;
}) => {
    let attempts = 0;

    const executePoll = async (
        resolve: (result: T) => void,
        reject: (reason?: any) => void,
    ) => {
        try {
            const result = await fn();
            attempts += 1;

            if (stopCondition(result)) {
                resolve(result);
                return;
            }
        } catch (e) {
            reject(e);
            return;
        }

        if (maxAttempts && attempts === maxAttempts) {
            reject(new Error("Exceeded max attempts"));
            return;
        }

        setTimeout(executePoll, interval, resolve, reject);
    };

    return new Promise(executePoll);
};
