import { IconButton, Tooltip } from "@mui/material";
import RemasterIcon from "../icons/RemasterIcon";

export default function RemasterAction({
    onClick,
    fontSize,
}: {
    onClick: () => void;
    fontSize?: number;
}) {
    return (
        <Tooltip title="Remaster">
            <IconButton aria-label="Remaster" onClick={onClick}>
                <RemasterIcon
                    style={{ fontSize: fontSize ? `${fontSize}px` : "inherit" }}
                />
            </IconButton>
        </Tooltip>
    );
}
