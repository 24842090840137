import { Box } from "@mui/material";
import { ReactNode, useMemo } from "react";
import {
    CompressionPreset,
    HighPreset,
    LowPreset,
    Mastering,
    StereoPreset,
} from "../../../model/Mastering";
import CompressionAggressiveIcon from "../../icons/presets/CompressionAggressiveIcon";
import CompressionBalanceIcon from "../../icons/presets/CompressionBalanceIcon";
import CompressionOffIcon from "../../icons/presets/CompressionOffIcon";
import CompressionPunchIcon from "../../icons/presets/CompressionPunchIcon";
import HighFrequencyDefaultIcon from "../../icons/presets/HighFrequencyDefaultIcon";
import HighFrequencyLessIcon from "../../icons/presets/HighFrequencyLessIcon";
import HighFrequencyMoreIcon from "../../icons/presets/HighFrequencyMoreIcon";
import LowFrequencyDefaultIcon from "../../icons/presets/LowFrequencyDefaultIcon";
import LowFrequencyLessIcon from "../../icons/presets/LowFrequencyLessIcon";
import LowFrequencyMoreIcon from "../../icons/presets/LowFrequencyMoreIcon";
import StereoDefaultIcon from "../../icons/presets/StereoDefaultIcon";
import StereoMoreIcon from "../../icons/presets/StereoMoreIcon";
import StereoOffIcon from "../../icons/presets/StereoOffIcon";
import AdvancedPresetIcon from "./AdvancedPresetIcon";

export default function AdvancedPresetIconList({
    mastering,
}: {
    mastering: Mastering;
}) {
    const lowPresetIcon = useMemo(
        () => computeLowPresetIcon(mastering.lowPreset),
        [mastering.lowPreset],
    );

    const highPresetIcon = useMemo(
        () => computeHighPresetIcon(mastering.highPreset),
        [mastering.highPreset],
    );

    const compressionPresetIcon = useMemo(
        () => computeCompressionPresetIcon(mastering.compressionPreset),
        [mastering.compressionPreset],
    );

    const stereoPresetIcon = useMemo(
        () => computeStereoPresetIcon(mastering.stereoPreset),
        [mastering.stereoPreset],
    );

    return (
        <Box role="list" display="flex">
            {lowPresetIcon}
            {highPresetIcon}
            {compressionPresetIcon}
            {stereoPresetIcon}
        </Box>
    );
}

function computeLowPresetIcon(lowPreset: LowPreset | null): ReactNode {
    switch (lowPreset) {
        case null:
            return null;
        case "DEFAULT":
            return (
                <AdvancedPresetIcon description="Default low frequencies">
                    <LowFrequencyDefaultIcon />
                </AdvancedPresetIcon>
            );
        case "MORE":
            return (
                <AdvancedPresetIcon description="Deepened low frequencies">
                    <LowFrequencyMoreIcon />
                </AdvancedPresetIcon>
            );
        case "LESS":
            return (
                <AdvancedPresetIcon description="Gentled low frequencies">
                    <LowFrequencyLessIcon />
                </AdvancedPresetIcon>
            );
    }
}

function computeHighPresetIcon(highPreset: HighPreset | null): ReactNode {
    switch (highPreset) {
        case null:
            return null;
        case "DEFAULT":
            return (
                <AdvancedPresetIcon description="Default high frequencies">
                    <HighFrequencyDefaultIcon />
                </AdvancedPresetIcon>
            );
        case "MORE":
            return (
                <AdvancedPresetIcon description="Brightened high frequencies">
                    <HighFrequencyMoreIcon />
                </AdvancedPresetIcon>
            );
        case "LESS":
            return (
                <AdvancedPresetIcon description="Smoothed high frequencies">
                    <HighFrequencyLessIcon />
                </AdvancedPresetIcon>
            );
    }
}

function computeCompressionPresetIcon(
    compressionPreset: CompressionPreset | null,
): ReactNode {
    switch (compressionPreset) {
        case null:
            return null;
        case "OFF":
            return (
                <AdvancedPresetIcon description="Unchanged compression">
                    <CompressionOffIcon />
                </AdvancedPresetIcon>
            );
        case "BALANCE":
            return (
                <AdvancedPresetIcon description="Balanced compression">
                    <CompressionBalanceIcon />
                </AdvancedPresetIcon>
            );
        case "PUNCH":
            return (
                <AdvancedPresetIcon description="Punchy compression">
                    <CompressionPunchIcon />
                </AdvancedPresetIcon>
            );
        case "AGGRESSIVE":
            return (
                <AdvancedPresetIcon description="Aggressive compression">
                    <CompressionAggressiveIcon />
                </AdvancedPresetIcon>
            );
    }
}

function computeStereoPresetIcon(stereoPreset: StereoPreset | null): ReactNode {
    switch (stereoPreset) {
        case null:
            return null;
        case "OFF":
            return (
                <AdvancedPresetIcon description="Unchanged stereo">
                    <StereoOffIcon inheritViewBox />
                </AdvancedPresetIcon>
            );
        case "DEFAULT":
            return (
                <AdvancedPresetIcon description="Balanced stereo">
                    <StereoDefaultIcon inheritViewBox />
                </AdvancedPresetIcon>
            );
        case "MORE":
            return (
                <AdvancedPresetIcon description="Widened stereo">
                    <StereoMoreIcon inheritViewBox />
                </AdvancedPresetIcon>
            );
    }
}
