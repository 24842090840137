import LoadingButton from "@mui/lab/LoadingButton";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { ButtonTypeMap } from "@mui/material/Button/Button";
import { useState } from "react";
import CloseButton from "./CloseButton";
import colors from "./theme/colors";

export type ConfirmationDialogProps = {
    question: string;
    details: string | null;
    confirmationButtonLabel: string;
    confirmationButtonColor: ButtonTypeMap["props"]["color"];
    confirm: () => Promise<"KEEP_DIALOG_OPEN" | "CLOSE_DIALOG">;
};

export function ConfirmationDialog({
    open,
    setOpen,
    question,
    details,
    confirmationButtonLabel,
    confirmationButtonColor,
    confirm,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
} & ConfirmationDialogProps) {
    const [loading, setLoading] = useState(false);

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            onClose={() => setOpen(false)}
            sx={{ textAlign: "center" }}
        >
            <DialogTitle style={{ border: "none" }}>
                <div>{question}</div>
                <div
                    style={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseButton onClose={() => setOpen(false)} />
                </div>
            </DialogTitle>
            {details && (
                <DialogContent
                    sx={{
                        color: colors.newColors.neutrals.n1,
                    }}
                >
                    {details}
                </DialogContent>
            )}
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    color={confirmationButtonColor}
                    loading={loading}
                    onClick={async (e) => {
                        e.stopPropagation();
                        setLoading(true);
                        try {
                            if ((await confirm()) === "CLOSE_DIALOG") {
                                setOpen(false);
                            }
                        } finally {
                            setLoading(false);
                        }
                    }}
                >
                    {confirmationButtonLabel}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
