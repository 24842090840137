import { Stack } from "@mui/material";
import logoPath from "./assets/logo.svg";
import colors from "./theme/colors";

export default function FullLogo() {
    return (
        <Stack
            spacing="24px"
            sx={{
                alignItems: "center",
            }}
        >
            <img
                style={{
                    display: "block",
                    width: "100%",
                }}
                src={logoPath}
                alt="Virtu"
            />
            <h1
                style={{
                    color: colors.neutrals.n1,
                    fontWeight: "500",
                    fontSize: "21px",
                    letterSpacing: "0.02rem",
                }}
            >
                Assisted&nbsp;Mastering by&nbsp;Slate&nbsp;Digital
            </h1>
        </Stack>
    );
}
