import { z } from "zod";

export const masteringPriceWithTaxSchema = z.object({
    amountExcludingTaxInCents: z.number(),
    tax: z.union([
        z.object({
            status: z.literal("CALCULATED"),
            taxInCents: z.number(),
        }),
        z.object({
            status: z.literal("NOT_CALCULATED_YET"),
        }),
    ]),
});

export type MasteringPriceWithTax = z.infer<typeof masteringPriceWithTaxSchema>;
