import LoadingButton from "@mui/lab/LoadingButton";
import {
    Box,
    Button,
    ButtonBase,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Link,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import axios from "axios";
import React, {
    CSSProperties,
    Dispatch,
    MutableRefObject,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useId,
    useRef,
    useState,
} from "react";
import Backend, { MasteringPresets } from "../../../Backend";
import {
    CompressionPreset,
    HighPreset,
    LoudnessPreset,
    LowPreset,
    Mastering,
    StereoPreset,
    StylePreset,
} from "../../../model/Mastering";
import { MasteringType } from "../../../model/MasteringType";
import { OriginalTrack } from "../../../model/OriginalTrack";
import { ReferenceTrack } from "../../../model/ReferenceTrack";
import poll from "../../../poll";
import sentryLogger from "../../../sentryLogger";
import CloseButton from "../../CloseButton";
import useEnablePayPerUseDialog from "../../EnablePayPerUseDialog/useEnablePayPerUseDialog";
import compressionAggressive from "../../assets/compression_aggressive.svg";
import compressionBalance from "../../assets/compression_balance.svg";
import compressionPunch from "../../assets/compression_punch.svg";
import compressionUnchanged from "../../assets/compression_unchanged.svg";
import eqHighDefault from "../../assets/eq-high-default.svg";
import eqHighLess from "../../assets/eq-high-less.svg";
import eqHighMore from "../../assets/eq-high-more.svg";
import eqLowDefault from "../../assets/eq-low-default.svg";
import eqLowLess from "../../assets/eq-low-less.svg";
import eqLowMore from "../../assets/eq-low-more.svg";
import getAuthenticationRedirectUrl from "../../getAuthenticationRedirectUrl";
import AddTrackIcon from "../../icons/AddTrackIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import InformationIcon from "../../icons/InformationIcon";
import TracksIcon from "../../icons/TracksIcon";
import useAuthentication from "../../state/useAuthentication";
import useNotifications from "../../state/useNotifications";
import usePricing from "../../state/usePricing";
import {
    LoadedServiceDetailsComputed,
    ServiceDetailsComputed,
} from "../../state/useServiceDetails";
import colors from "../../theme/colors";
import HiddenWavFileInput from "../HiddenWavFileInput";
import stylePresetMapping from "../stylePresetMapping";
import AudioFileUploadBar from "./AudioFileUploadBar";
import FAQLink from "./FAQLink";
import LoudnessSelection from "./LoudnessSelection";
import MasteringCostInformation from "./MasteringCostInformation/MasteringCostInformation";
import ParameterToggleSwitch from "./ParameterToggleSwitch";
import Preview from "./Preview";
import StereoDefaultImage from "./StereoDefaultImage";
import StereoMoreImage from "./StereoMoreImage";
import StereoOffImage from "./StereoOffImage";
import VerticalSlider from "./VerticalSlider";
import getTrackFileErrorMessage from "./getTrackFileErrorMessage";
import studioState, { StudioState } from "./studioState";
import useDeleteReferenceTrackDialog from "./useDeleteReferenceTrackDialog";
import usePayPerUseUtils from "./usePayPerUseUtils";

type LowPresetControl = {
    name: "Deep" | "Default" | "Gentle";
    value: LowPreset;
    label: string;
    image: { url: string; left: number; top: number };
};

type HighPresetControl = {
    name: "Default" | "Bright" | "Smooth";
    value: HighPreset;
    label: string;
    image: { url: string; left: number; top: number };
};

const lowPresets: LowPresetControl[] = [
    {
        name: "Deep",
        value: "MORE",
        label: "Boost low frequencies",
        image: { url: eqLowMore, left: -65, top: 19 },
    },
    {
        name: "Default",
        value: "DEFAULT",
        label: "Keep low frequencies unchanged",
        image: { url: eqLowDefault, left: -63, top: 36 },
    },
    {
        name: "Gentle",
        value: "LESS",
        label: "Reduce low frequencies",
        image: { url: eqLowLess, left: -66, top: 21 },
    },
];
const highPresets: HighPresetControl[] = [
    {
        name: "Bright",
        value: "MORE",
        label: "Boost high frequencies",
        image: { url: eqHighMore, left: -66, top: 19 },
    },
    {
        name: "Default",
        value: "DEFAULT",
        label: "Keep high frequencies unchanged",
        image: { url: eqHighDefault, left: -63, top: 36 },
    },
    {
        name: "Smooth",
        value: "LESS",
        label: "Reduce high frequencies",
        image: { url: eqHighLess, left: -66, top: 21 },
    },
];

const compressionSliderValues = [
    {
        value: 0,
        label: "Aggressive",
        presetValue: "AGGRESSIVE",
    },
    {
        value: 50,
        label: "Punchy",
        presetValue: "PUNCH",
    },
    {
        value: 100,
        label: "Balanced",
        presetValue: "BALANCE",
    },
] as const;

export type NewMasteringDialogInput =
    | { type: "REMASTER"; originalTrack: OriginalTrack }
    | { type: "RESTORED_STATE"; restoredState: StudioState }
    | {
          type: "SELECTED_FILE";
          selectedFile: File;
      };

export default function Studio({
    input,
    onNewMastering,
    onClose,
    serviceDetails,
}: {
    input: NewMasteringDialogInput | null;
    onNewMastering?: (mastering: Mastering) => void;
    onClose: () => void;
    serviceDetails?: ServiceDetailsComputed;
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

    function closeDialog() {
        studioState.drop();
        onClose();
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth="lg"
            scroll="paper"
            open={input != null}
            onClose={(_event, reason) => {
                if (reason === "backdropClick") return;
                closeDialog();
            }}
            sx={{
                ".MuiPaper-root": {
                    width: fullScreen ? undefined : "982px",
                    height: fullScreen ? undefined : "709px",
                },
            }}
        >
            {input !== null && (
                <StudioDialogContent
                    input={input}
                    onNewMastering={onNewMastering}
                    onClose={closeDialog}
                    computedServiceDetails={serviceDetails}
                />
            )}
        </Dialog>
    );
}

type ReferenceTrackState =
    | {
          type: "NO_SELECTION";
      }
    | {
          type: "UPLOADING";
          file: File;
      }
    | {
          type: "UPLOADED";
          referenceTrack: ReferenceTrack;
      };

function StudioDialogContent({
    input,
    onNewMastering,
    onClose,
    computedServiceDetails,
}: {
    input: NewMasteringDialogInput;
    onNewMastering?: (mastering: Mastering) => void;
    onClose: () => void;
    computedServiceDetails?: ServiceDetailsComputed;
}) {
    let restoredState: StudioState | null;
    if (input.type === "RESTORED_STATE") {
        restoredState = input.restoredState;
    } else {
        restoredState = null;
    }

    const restoredPresets = restoredState?.masteringPresets;
    const defaultStyle = restoredPresets?.style ?? "UNIVERSAL";

    const [selectedStyle, setSelectedStyle] =
        useState<StylePreset>(defaultStyle);

    const [referenceTrackState, setReferenceTrackState] =
        useState<ReferenceTrackState>(
            restoredState == null || restoredState.referenceTrack == null
                ? { type: "NO_SELECTION" }
                : {
                      type: "UPLOADED",
                      referenceTrack: restoredState.referenceTrack,
                  },
        );

    const defaultLoudness = restoredPresets?.loudness ?? "LOW";

    const [selectedLoudness, setSelectedLoudness] =
        useState<LoudnessPreset>(defaultLoudness);

    const [advancedControlsEnabled, setAdvancedControlsEnabled] = useState(
        restoredPresets?.low != null,
    );

    const [lowPreset, setLowPreset] = useState<LowPreset>(
        restoredPresets?.low ?? "DEFAULT",
    );
    const [highPreset, setHighPreset] = useState<HighPreset>(
        restoredPresets?.high ?? "DEFAULT",
    );
    const [stereoPreset, setStereoPreset] = useState<StereoPreset>(
        restoredPresets?.stereo ?? "DEFAULT",
    );

    const [compressionPreset, setCompressionPreset] = useState<
        Exclude<CompressionPreset, "OFF">
    >(
        restoredPresets?.compression === "OFF" ||
            restoredPresets == null ||
            restoredPresets.compression == null
            ? "PUNCH"
            : restoredPresets!.compression,
    );

    const [compressionEnabled, setCompressionEnabled] = useState(
        restoredPresets?.compression !== "OFF",
    );

    let defaultOriginalTrack: OriginalTrack | null;
    if (input.type === "REMASTER") {
        defaultOriginalTrack = input.originalTrack;
    } else {
        defaultOriginalTrack = restoredState?.originalTrack ?? null;
    }

    const [originalTrack, setOriginalTrack] = useState<OriginalTrack | null>(
        defaultOriginalTrack,
    );

    const [audioFileUploadBarInput, setAudioFileUploadBarInput] = useState<
        | {
              type: "ALREADY_UPLOADED_FILE";
              filename: string;
          }
        | {
              type: "FILE_TO_UPLOAD";
              file: File;
              onUploadComplete: (event: {
                  originalTrack: OriginalTrack;
              }) => void;
          }
    >();

    useEffect(() => {
        setAudioFileUploadBarInput(
            (() => {
                if (
                    input.type === "REMASTER" ||
                    input.type === "RESTORED_STATE"
                ) {
                    return {
                        type: "ALREADY_UPLOADED_FILE",
                        filename: originalTrack!.fileName,
                    };
                }
                return {
                    type: "FILE_TO_UPLOAD",
                    file: input.selectedFile,
                    onUploadComplete: (event) => {
                        setOriginalTrack(event.originalTrack);
                    },
                };
            })(),
        );
    }, [input, originalTrack]);

    function handleLoudnessChange(value: string | null) {
        if (value != null) {
            setSelectedLoudness(value as LoudnessPreset);
        }
    }

    const computePresetsRequest = useCallback(() => {
        let compressionValue: CompressionPreset | null = null;
        if (advancedControlsEnabled) {
            compressionValue = compressionEnabled ? compressionPreset : "OFF";
        }

        return {
            style: selectedStyle,
            loudness: selectedLoudness,
            low: advancedControlsEnabled ? lowPreset : null,
            high: advancedControlsEnabled ? highPreset : null,
            compression: compressionValue,
            stereo: advancedControlsEnabled ? stereoPreset : null,
        };
    }, [
        advancedControlsEnabled,
        selectedStyle,
        selectedLoudness,
        lowPreset,
        highPreset,
        compressionEnabled,
        compressionPreset,
        stereoPreset,
    ]);

    const [masteringPresetRequest, setMasteringPresetRequest] =
        useState<MasteringPresets>(computePresetsRequest());

    useEffect(() => {
        setMasteringPresetRequest(computePresetsRequest());
    }, [computePresetsRequest]);

    const { enablePayPerUseDialogElement, openEnablePayPerUseDialog } =
        useEnablePayPerUseDialog();

    const { authenticationState } = useAuthentication();

    let masteringType: MasteringType;
    switch (input.type) {
        case "REMASTER":
            masteringType = "REMASTER";
            break;
        case "RESTORED_STATE":
            masteringType = input.restoredState.masteringType;
            break;
        case "SELECTED_FILE":
            masteringType = "INITIAL_MASTER";
            break;
    }

    useEffect(() => {
        if (
            originalTrack != null &&
            (authenticationState.type === "REGISTERED_USER_AUTHENTICATED" ||
                authenticationState.type === "GUEST_USER_AUTHENTICATED")
        ) {
            studioState.save({
                userId: authenticationState.authenticatedUser.id,
                originalTrack,
                referenceTrack:
                    referenceTrackState?.type === "UPLOADED"
                        ? referenceTrackState.referenceTrack
                        : null,
                masteringPresets: masteringPresetRequest,
                masteringType,
            });
        }
    }, [
        authenticationState,
        masteringPresetRequest,
        originalTrack,
        referenceTrackState,
        masteringType,
    ]);

    const trackName =
        input.type === "SELECTED_FILE"
            ? input.selectedFile.name
            : originalTrack!.fileName;

    return (
        <>
            <StudioDialogTitle onClose={onClose} />
            <DialogContent
                style={{
                    padding: "0",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        padding: "8px 24px",
                        minHeight: "100px",
                        backgroundColor: colors.newColors.neutrals.n5,
                        flexShrink: "0",
                    }}
                >
                    {!originalTrack &&
                        audioFileUploadBarInput &&
                        audioFileUploadBarInput.type === "FILE_TO_UPLOAD" && (
                            <div
                                style={{
                                    display: originalTrack ? "none" : "block",
                                    width: "100%",
                                }}
                            >
                                <AudioFileUploadBar
                                    input={audioFileUploadBarInput}
                                />
                            </div>
                        )}
                    {originalTrack && (
                        <Preview
                            originalTrackId={originalTrack.id}
                            referenceTrack={
                                masteringPresetRequest.style ===
                                    "REFERENCE_TRACK" &&
                                referenceTrackState.type === "UPLOADED"
                                    ? referenceTrackState.referenceTrack
                                    : null
                            }
                            presetParameters={masteringPresetRequest}
                            previewFeatureAvailable={
                                originalTrack.previewFeatureAvailable
                            }
                            trackName={trackName}
                            masteringType={masteringType}
                        />
                    )}
                </div>

                <div
                    style={{
                        padding: "0px 16px",
                        flexGrow: 1,
                        overflowY: "scroll",
                    }}
                >
                    <StyleSection
                        selectedStyle={selectedStyle}
                        setSelectedStyle={setSelectedStyle}
                        referenceTrackState={referenceTrackState}
                        setReferenceTrackState={setReferenceTrackState}
                    />
                    <LoudnessSection
                        selectedLoudness={selectedLoudness}
                        onChange={handleLoudnessChange}
                        referenceTrackLoudness={
                            selectedStyle === "REFERENCE_TRACK" &&
                            referenceTrackState.type === "UPLOADED"
                                ? referenceTrackState.referenceTrack.loudness
                                : null
                        }
                    />
                    <AdvancedControlsSection
                        advancedControlsEnabled={advancedControlsEnabled}
                        setAdvancedControlsEnabled={setAdvancedControlsEnabled}
                        compressionEnabled={compressionEnabled}
                        setCompressionEnabled={setCompressionEnabled}
                        compressionPreset={compressionPreset}
                        setCompressionPreset={setCompressionPreset}
                        lowPreset={lowPreset}
                        setLowPreset={setLowPreset}
                        highPreset={highPreset}
                        setHighPreset={setHighPreset}
                        stereoPreset={stereoPreset}
                        setStereoPreset={setStereoPreset}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {computedServiceDetails != null &&
                    computedServiceDetails.status === "LOADED" && (
                        <MasteringCostInformation
                            serviceDetails={computedServiceDetails}
                            masteringType={masteringType}
                        />
                    )}

                <Button variant="outlined" color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                {onNewMastering != null &&
                computedServiceDetails != null &&
                computedServiceDetails.status === "LOADED" ? (
                    <SubmitButton
                        originalTrackId={originalTrack?.id ?? null}
                        referenceTrack={
                            referenceTrackState.type === "UPLOADED"
                                ? referenceTrackState.referenceTrack
                                : null
                        }
                        openEnablePayPerUseDialog={openEnablePayPerUseDialog}
                        onNewMastering={onNewMastering}
                        onClose={onClose}
                        serviceDetails={computedServiceDetails}
                        masteringPresets={masteringPresetRequest}
                        masteringType={masteringType}
                    />
                ) : (
                    <Button
                        component={Link}
                        href={getAuthenticationRedirectUrl({
                            authenticationType: "sign-up",
                        })}
                        color="primary"
                        variant="contained"
                    >
                        Sign Up to Master
                    </Button>
                )}
            </DialogActions>
            {enablePayPerUseDialogElement}
        </>
    );
}

function StudioDialogTitle({ onClose }: { onClose: () => void }) {
    return (
        <DialogTitle>
            <Box display="flex" alignItems="center" maxWidth="100%" mr={2}>
                <div>Mastering Studio</div>
            </Box>
            <FAQLink />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "22px",
                    marginLeft: "auto",
                    marginRight: "-11px",
                }}
            >
                <CloseButton onClose={onClose} />
            </div>
        </DialogTitle>
    );
}

function StyleSection({
    selectedStyle,
    setSelectedStyle,
    referenceTrackState,
    setReferenceTrackState,
}: {
    selectedStyle: StylePreset;
    setSelectedStyle: (newStyle: StylePreset) => void;
    referenceTrackState: ReferenceTrackState;
    setReferenceTrackState: Dispatch<SetStateAction<ReferenceTrackState>>;
}) {
    const styles = [
        "UNIVERSAL",
        "POP",
        "ROCK",
        "HIPHOP",
        "EDM",
        "INSTRUMENTAL",
        "REFERENCE_TRACK",
    ] as const;

    return (
        <Section title="Choose your style">
            <RadioGroup aria-label="Mastering presets" sx={{ margin: "-4px" }}>
                <Grid container spacing={1} justifyContent="center">
                    {styles.map((style) => (
                        <Grid
                            item
                            md={12 / 7}
                            sm={12 / 4}
                            xs={4}
                            display="flex"
                            justifyContent="space-evenly"
                            key={style}
                        >
                            <StyleButton
                                style={style}
                                selectedStyle={selectedStyle}
                                setSelectedStyle={setSelectedStyle}
                                referenceTrackState={referenceTrackState}
                                setReferenceTrackState={setReferenceTrackState}
                            />
                        </Grid>
                    ))}
                </Grid>
            </RadioGroup>
        </Section>
    );
}

function StyleButton({
    style,
    selectedStyle,
    setSelectedStyle,
    referenceTrackState,
    setReferenceTrackState,
}: {
    style: StylePreset;
    selectedStyle: StylePreset;
    setSelectedStyle: (newStyle: StylePreset) => void;
    referenceTrackState: ReferenceTrackState;
    setReferenceTrackState: Dispatch<SetStateAction<ReferenceTrackState>>;
}) {
    const stylePresetId = useId();

    const [isReferenceHovered, setReferenceHovered] = useState(false);

    const isReferenceTrack = style === "REFERENCE_TRACK";

    const referenceTrackInput = useRef<HTMLInputElement | null>(null);

    const handleMouseEnter = () => {
        setReferenceHovered(true);
    };

    const handleMouseLeave = () => {
        setReferenceHovered(false);
    };

    const imageStyle = {
        border: `1px ${colors.newColors.neutrals.n3} solid`,
        borderRadius: "4px",
        overflow: "hidden",
        flexBasis: 0,
        flexGrow: 1,
    };

    const referenceTrackAbortController = useRef<AbortController | null>(null);

    return (
        <ButtonBase
            aria-labelledby={stylePresetId}
            onMouseEnter={() => {
                if (isReferenceTrack) {
                    handleMouseEnter();
                }
            }}
            onMouseLeave={() => {
                if (isReferenceTrack) {
                    handleMouseLeave();
                }
            }}
            onClick={(e) => {
                if (
                    isReferenceTrack &&
                    referenceTrackState.type === "NO_SELECTION"
                ) {
                    referenceTrackInput.current?.click();
                } else {
                    setSelectedStyle(style);
                }
                e.preventDefault();
            }}
            sx={{
                width: "100%",
                height: "140px",
                borderRadius: "10px",
                ...(selectedStyle === style
                    ? {
                          border: `2px ${colors.newColors.primary} solid`,
                          padding: "0px",
                          backgroundColor: colors.newColors.backgrounds.blur,
                      }
                    : {
                          border: `1px ${colors.newColors.neutrals.n3} solid`,
                          padding: "1px",
                          backgroundColor: colors.newColors.backgrounds.blur,
                          "&:hover": {
                              border: `1px ${colors.newColors.primary} solid`,
                              backgroundColor:
                                  colors.newColors.backgrounds.hover,
                          },
                      }),
            }}
        >
            <FormControlLabel
                value={style}
                sx={{
                    position: "relative",
                    padding: "6px",
                    marginLeft: 0,
                    marginRight: 0,
                    width: "100%",
                    height: "100%",
                    ".MuiFormControlLabel-label": {
                        width: "100%",
                        height: "100%",
                    },
                }}
                control={
                    <Radio
                        style={{ display: "none" }}
                        checked={style === selectedStyle}
                    />
                }
                label={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                        }}
                    >
                        {isReferenceTrack ? (
                            <ReferenceTrackStylePresetButtonContent
                                style={imageStyle}
                                referenceTrackState={referenceTrackState}
                                setReferenceTrackState={setReferenceTrackState}
                                setSelectedStyle={setSelectedStyle}
                                fileInput={referenceTrackInput}
                                abortControllerReference={
                                    referenceTrackAbortController
                                }
                            />
                        ) : (
                            <img
                                alt=""
                                src={stylePresetMapping[style].image}
                                style={{ ...imageStyle, objectFit: "cover" }}
                            />
                        )}
                        <div
                            id={stylePresetId}
                            style={{
                                marginTop: "5px",
                                fontSize: 12,
                                fontWeight: 400,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "3px",
                            }}
                        >
                            <span>{stylePresetMapping[style].label}</span>
                            {isReferenceTrack && (
                                <Tooltip title="Upload a track to use as the EQ target">
                                    <InformationIcon
                                        role="img"
                                        style={{
                                            fontSize: 14,
                                            fill: colors.neutrals.n2,
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                }
            />
            {isReferenceTrack &&
                (referenceTrackState.type === "UPLOADING" ||
                    referenceTrackState.type === "UPLOADED") && (
                    <DeleteReferenceTrackButton
                        resetReferenceTrack={() => {
                            referenceTrackAbortController.current?.abort();
                            if (selectedStyle === "REFERENCE_TRACK") {
                                setSelectedStyle("UNIVERSAL");
                            }
                            setReferenceTrackState({
                                type: "NO_SELECTION",
                            });
                        }}
                        isReferenceHovered={isReferenceHovered}
                    />
                )}
        </ButtonBase>
    );
}

function ReferenceTrackStylePresetButtonContent({
    style,
    referenceTrackState,
    setReferenceTrackState,
    setSelectedStyle,
    fileInput,
    abortControllerReference,
}: {
    style: React.CSSProperties;
    referenceTrackState: ReferenceTrackState;
    setReferenceTrackState: Dispatch<SetStateAction<ReferenceTrackState>>;
    setSelectedStyle: (newStyle: StylePreset) => void;
    fileInput: MutableRefObject<HTMLInputElement | null>;
    abortControllerReference: MutableRefObject<AbortController | null>;
}) {
    const { addNotification } = useNotifications();

    useEffect(
        () => () => {
            abortControllerReference.current?.abort();
        },
        [abortControllerReference],
    );

    const fileReady =
        referenceTrackState.type === "UPLOADED" &&
        referenceTrackState.referenceTrack.status === "ANALYZED";

    const fileUploadingOrAnalyzing =
        referenceTrackState.type !== "NO_SELECTION" && !fileReady;

    let referenceTrackFileName: string | null;
    if (referenceTrackState.type === "UPLOADED") {
        referenceTrackFileName = referenceTrackState.referenceTrack.fileName;
    } else if (referenceTrackState.type === "UPLOADING") {
        referenceTrackFileName = referenceTrackState.file.name;
    } else {
        referenceTrackFileName = null;
    }

    return (
        <div
            style={{
                ...style,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                background: fileReady
                    ? "linear-gradient(0deg, #9B4DFF 25%, #4DA1FF 100%)"
                    : undefined,
            }}
        >
            {/* eslint-disable-next-line no-nested-ternary */}
            {fileUploadingOrAnalyzing ? (
                <CircularProgress size={20} />
            ) : fileReady ? (
                <TracksIcon />
            ) : (
                <AddTrackIcon />
            )}

            {referenceTrackFileName && (
                <Typography
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        fontSize: "10px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                        textWrap: "nowrap",
                        padding: "4px",
                    }}
                >
                    {referenceTrackFileName}
                </Typography>
            )}
            <HiddenWavFileInput
                ref={fileInput}
                onFileSelected={async (file) => {
                    abortControllerReference.current?.abort();

                    function listenForUpdates(referenceTrackId: number) {
                        return poll({
                            fn: () =>
                                Backend.getReferenceTrack(referenceTrackId),
                            stopCondition: (it) => it.status !== "ANALYZING",
                            interval: 1000,
                            maxAttempts: 15 * 60,
                        });
                    }

                    try {
                        setSelectedStyle("REFERENCE_TRACK");
                        const uploadingState = {
                            type: "UPLOADING",
                            file,
                        } as const;
                        setReferenceTrackState(uploadingState);

                        const referenceTrack =
                            await Backend.createReferenceTrack(file.name);

                        const uploadUrl =
                            await Backend.getReferenceTrackUploadUrl(
                                referenceTrack.id,
                            );

                        // eslint-disable-next-line no-param-reassign
                        abortControllerReference.current =
                            new AbortController();
                        await axios.put(uploadUrl, file, {
                            signal: abortControllerReference.current.signal,
                            headers: {
                                "Content-Type": file.type,
                            },
                        });

                        setReferenceTrackState((oldValue) => ({
                            ...oldValue,
                            referenceTrack,
                            file,
                            type: "UPLOADED",
                        }));

                        await Backend.analyzeReferenceTrack(referenceTrack.id);

                        const updatedReferenceTrack = await listenForUpdates(
                            referenceTrack.id,
                        );

                        if (updatedReferenceTrack.status === "ANALYZED") {
                            setReferenceTrackState((oldValue) => ({
                                ...oldValue,
                                referenceTrack: updatedReferenceTrack,
                            }));
                        } else {
                            addNotification({
                                severity: "error",
                                message:
                                    "The analysis failed. Please ensure that your reference track follows the tracks requirements (see FAQ).",
                                manualDismiss: true,
                            });
                            setReferenceTrackState({
                                type: "NO_SELECTION",
                            });
                            setSelectedStyle("UNIVERSAL");
                        }
                    } catch (e: any) {
                        if (e.message === "canceled") {
                            return;
                        }

                        const apiCode = e.response?.data?.code;

                        const apiCodeErrorMessage =
                            getTrackFileErrorMessage(apiCode);
                        if (apiCodeErrorMessage != null) {
                            addNotification({
                                severity: "error",
                                message: apiCodeErrorMessage,
                                manualDismiss: true,
                            });
                        } else if (
                            e.isAxiosError &&
                            e.message === "Network Error"
                        ) {
                            addNotification({
                                severity: "error",
                                message:
                                    "There was an error during the upload. Please make sure to use a stable connection.",
                                manualDismiss: true,
                            });
                        } else {
                            console.error(
                                "Unable to upload reference track",
                                e,
                            );
                            addNotification({
                                severity: "error",
                                message:
                                    "An unexpected error occurred. Please contact support if this persists.",
                                manualDismiss: true,
                            });
                        }
                        setReferenceTrackState({ type: "NO_SELECTION" });
                        setSelectedStyle("UNIVERSAL");
                    }
                }}
            />
        </div>
    );
}

function DeleteReferenceTrackButton({
    resetReferenceTrack,
    isReferenceHovered,
}: {
    resetReferenceTrack: () => void;
    isReferenceHovered: boolean;
}) {
    const {
        deleteReferenceTrackDialogElement,
        openDeleteReferenceTrackDialog,
    } = useDeleteReferenceTrackDialog({
        onReferenceTrackDeleted: () => {
            resetReferenceTrack();
        },
    });

    return (
        <>
            <Box
                role="button"
                aria-label="delete the reference track"
                onMouseDown={(e) => {
                    // prevent ripple effect on parent button
                    e.stopPropagation();
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    openDeleteReferenceTrackDialog();
                }}
                sx={{
                    display: "flex",
                    padding: "6px",
                    alignItems: "flex-start",
                    gap: "8px",
                    position: "absolute",
                    right: "1px",
                    top: "1px",
                    borderRadius: "8px",
                    background: isReferenceHovered ? "#2c3033" : "#1b2025",
                    "&:hover": {
                        background: colors.red.r2,
                    },
                }}
            >
                <DeleteIcon
                    sx={{
                        display: "flex",
                        width: "16px",
                        height: "16px",
                        padding: "1px 2px",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                />
            </Box>
            {deleteReferenceTrackDialogElement}
        </>
    );
}

function LoudnessSection({
    selectedLoudness,
    onChange,
    referenceTrackLoudness,
}: {
    selectedLoudness: "LOW" | "MEDIUM" | "HIGH";
    onChange: (value: string) => void;
    referenceTrackLoudness: number | null;
}) {
    return (
        <Section title="Select the loudness target">
            <LoudnessSelection
                selectedLoudness={selectedLoudness}
                onChange={onChange}
                referenceTrackLoudness={referenceTrackLoudness}
            />
        </Section>
    );
}

function Section({ title, children }: { title: string; children: ReactNode }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <>
            <StepTitle
                style={{
                    marginTop: "18px",
                    marginBottom: "18px",
                    fontSize: "16px",
                    fontWeight: 600,
                }}
                title={title}
            />
            <div
                style={
                    fullScreen
                        ? {}
                        : {
                              backgroundColor: colors.newColors.neutrals.n5,
                              padding: "14px",
                              borderRadius: "6px",
                          }
                }
            >
                {children}
            </div>
        </>
    );
}

function AdvancedControlsSection({
    advancedControlsEnabled,
    setAdvancedControlsEnabled,
    compressionEnabled,
    setCompressionEnabled,
    compressionPreset,
    setCompressionPreset,
    lowPreset,
    setLowPreset,
    highPreset,
    setHighPreset,
    stereoPreset,
    setStereoPreset,
}: {
    advancedControlsEnabled: boolean;
    setAdvancedControlsEnabled: (enabled: boolean) => void;
    compressionEnabled: boolean;
    setCompressionEnabled: (enabled: boolean) => void;
    compressionPreset: Exclude<CompressionPreset, "OFF">;
    setCompressionPreset: (preset: Exclude<CompressionPreset, "OFF">) => void;
    lowPreset: LowPreset;
    setLowPreset: (preset: LowPreset) => void;
    highPreset: HighPreset;
    setHighPreset: (preset: HighPreset) => void;
    stereoPreset: StereoPreset;
    setStereoPreset: (preset: StereoPreset) => void;
}) {
    const verticalLayout = useMediaQuery(useTheme().breakpoints.down("md"));

    const advancedControlsPanelRef = useRef<HTMLDivElement>(null);
    const [shouldScrollToAdvancedControls, setShouldScrollToAdvancedControls] =
        useState(false);

    useEffect(() => {
        if (shouldScrollToAdvancedControls) {
            advancedControlsPanelRef.current!.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
            setShouldScrollToAdvancedControls(false);
        }
    }, [shouldScrollToAdvancedControls, advancedControlsPanelRef]);

    function handleAdvancedControls(checked: boolean) {
        setAdvancedControlsEnabled(checked);
        setShouldScrollToAdvancedControls(true);
    }

    function getCompressionSliderValue(): number {
        return compressionSliderValues.find(
            ({ presetValue }) => presetValue === compressionPreset,
        )!.value;
    }

    function getCompressionImage() {
        if (!compressionEnabled) {
            return compressionUnchanged;
        }

        switch (compressionPreset) {
            case "PUNCH":
                return compressionPunch;
            case "AGGRESSIVE":
                return compressionAggressive;
            case "BALANCE":
                return compressionBalance;
            default:
                return compressionUnchanged;
        }
    }

    function handleCompressionPreset(value: number) {
        setCompressionPreset(
            compressionSliderValues.find((preset) => preset.value === value)!
                .presetValue,
        );
    }

    return (
        <div
            ref={advancedControlsPanelRef}
            style={{
                marginBottom: "16px",
                scrollMargin: "16px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    marginTop: "18px",
                    marginBottom: "18px",
                }}
            >
                <FormControlLabel
                    sx={{ marginLeft: 0 }}
                    control={
                        <Switch
                            checked={advancedControlsEnabled}
                            onChange={(_event, checked) =>
                                handleAdvancedControls(checked)
                            }
                        />
                    }
                    label={
                        <span
                            style={{
                                fontSize: "16px",
                                fontWeight: 600,
                            }}
                        >
                            Advanced controls
                        </span>
                    }
                    labelPlacement="start"
                />
            </div>
            {advancedControlsEnabled && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: verticalLayout ? "column" : "row",
                        gap: "10px",
                    }}
                >
                    <CardSection title="Adjust the frequency balance">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: verticalLayout
                                    ? "column"
                                    : "row",
                                alignItems: "center",
                                gap: "12px",
                            }}
                        >
                            <EQSelector
                                setPreset={setLowPreset}
                                selected={lowPreset}
                                title="LOW"
                                presets={lowPresets}
                            />
                            <EQSelector
                                setPreset={setHighPreset}
                                selected={highPreset}
                                title="HIGH"
                                presets={highPresets}
                            />
                        </div>
                    </CardSection>
                    <CardSection title="Select the compression preset">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                flexGrow: 1,
                            }}
                        >
                            <ParameterToggleSwitch
                                dataTestid="compression-switch"
                                leftLabel="Unchanged"
                                rightLabel="Compressed"
                                ariaLabel="Enable compression"
                                switchProps={{
                                    checked: compressionEnabled,
                                    onChange: (event) => {
                                        setCompressionEnabled(
                                            event.target.checked,
                                        );
                                    },
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    overflow: "hidden",
                                    flexGrow: 1,
                                }}
                            >
                                <img
                                    style={{
                                        width: "192px",
                                    }}
                                    alt="compression-control"
                                    src={getCompressionImage()}
                                />
                                <div
                                    style={{
                                        marginLeft: "20px",
                                    }}
                                >
                                    <VerticalSlider
                                        data-testid="compression-preset-slide"
                                        style={{
                                            height: "82px",
                                            width: "93px",
                                        }}
                                        value={getCompressionSliderValue()}
                                        marks={compressionSliderValues}
                                        onChange={(value: number) => {
                                            setCompressionEnabled(true);
                                            handleCompressionPreset(value);
                                        }}
                                        grayedOut={!compressionEnabled}
                                    />
                                </div>
                            </div>
                        </div>
                    </CardSection>
                    <CardSection title="Expand the stereo image">
                        <StereoExpansionSelector
                            stereoPreset={stereoPreset}
                            onStereoPresetChange={setStereoPreset}
                        />
                    </CardSection>
                </div>
            )}
        </div>
    );
}

function CardSection({
    title,
    children,
}: {
    title: string;
    children: ReactNode;
}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                height: "411px",
                width: "100%",
                flexBasis: "max-content",
                flexGrow: 1,
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    borderRadius: "6px",
                    alignItems: "center",
                    border: `1px ${colors.newColors.neutrals.n3} solid`,
                }}
            >
                <StepTitle
                    style={{
                        width: "100%",
                        alignItems: "start",
                        fontSize: "16px",
                        fontWeight: 600,
                        marginBottom: "26px",
                    }}
                    title={title}
                />
                {children}
            </div>
        </div>
    );
}

function StepTitle({
    title,
    style = undefined,
}: {
    title: string;
    style: CSSProperties | undefined;
}) {
    return (
        <div style={style}>
            <span style={{ color: colors.newColors.neutrals.n2 }}>{title}</span>
        </div>
    );
}

function EQSelector({
    title,
    presets,
    selected,
    setPreset,
}: {
    title: string;
    presets: LowPresetControl[] | HighPresetControl[];
    selected: string;
    setPreset: (eqValue: LowPreset | HighPreset) => void;
}) {
    const horizontalLayout = useMediaQuery(useTheme().breakpoints.down("md"));

    return (
        <div>
            <div
                style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    textAlign: horizontalLayout ? "left" : "center",
                    marginBottom: "6px",
                }}
            >
                {title}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: horizontalLayout ? "row" : "column",
                    alignItems: "center",
                    gap: "6px",
                }}
            >
                {presets.map((preset: LowPresetControl | HighPresetControl) => (
                    <EQPresetCard
                        key={`${title}-${preset.name}`}
                        valueName={preset.name}
                        label={preset.label}
                        image={preset.image}
                        selected={preset.value === selected}
                        setPreset={() => setPreset(preset.value)}
                    />
                ))}
            </div>
        </div>
    );
}

function EQPresetCard({
    valueName,
    label,
    image,
    selected,
    setPreset,
}: {
    valueName: string;
    label: string;
    image: { url: string; left: number; top: number };
    selected: boolean;
    setPreset: () => void;
}) {
    return (
        <ButtonBase
            aria-label={label}
            aria-pressed={selected}
            onClick={() => setPreset()}
            sx={{
                display: "flex",
                width: "85px",
                height: "85px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "end",
                backgroundImage: `url(${image.url})`,
                backgroundPositionX: selected ? image.left - 1 : image.left,
                backgroundPositionY: selected ? image.top - 1 : image.top,
                ...(selected
                    ? {
                          border: `2px ${colors.newColors.primary} solid`,
                          backgroundColor: colors.newColors.backgrounds.blur,
                      }
                    : {
                          border: `1px ${colors.newColors.neutrals.n3} solid`,
                          backgroundColor: colors.newColors.backgrounds.blur,
                          "&:hover": {
                              border: `1px ${colors.newColors.primary} solid`,
                              backgroundColor:
                                  colors.newColors.backgrounds.hover,
                          },
                      }),
            }}
        >
            <div
                style={{
                    fontFamily: ["Inter", "sans-serif"].join(","),
                    fontSize: "12px",
                    fontWeight: 600,
                    borderTop: `1px ${colors.newColors.neutrals.n3} solid`,
                    padding: "4px",
                    width: "100%",
                    height: selected ? "23px" : "24px",
                }}
            >
                {valueName}
            </div>
        </ButtonBase>
    );
}

function StereoExpansionSelector({
    stereoPreset,
    onStereoPresetChange,
}: {
    stereoPreset: StereoPreset;
    onStereoPresetChange: (preset: StereoPreset) => void;
}) {
    const stereoControlImage = (
        <>
            {stereoPreset === "OFF" && <StereoOffImage />}
            {stereoPreset === "DEFAULT" && <StereoDefaultImage />}
            {stereoPreset === "MORE" && <StereoMoreImage />}
        </>
    );

    function handleControlImageClick() {
        if (stereoPreset === "OFF") {
            onStereoPresetChange("DEFAULT");
        } else if (stereoPreset === "DEFAULT") {
            onStereoPresetChange("MORE");
        } else {
            onStereoPresetChange("DEFAULT");
        }
    }

    function getLabel() {
        if (stereoPreset === "OFF") {
            return "Unchanged";
        }
        if (stereoPreset === "DEFAULT") {
            return "Balanced";
        }
        return "Wider";
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
            }}
        >
            <ParameterToggleSwitch
                dataTestid="stereo-switch"
                style={{
                    marginBottom: "10px",
                }}
                leftLabel="Unchanged"
                rightLabel="Expanded"
                ariaLabel="Enable stereo image expansion"
                switchProps={{
                    checked: stereoPreset !== "OFF",
                    onChange: (_event, checked) => {
                        onStereoPresetChange(checked ? "DEFAULT" : "OFF");
                    },
                }}
            />
            <div
                role="button"
                onClick={handleControlImageClick}
                onKeyUp={(event) => {
                    if (event.key === " ") {
                        handleControlImageClick();
                    }
                }}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        handleControlImageClick();
                    }
                }}
                style={{
                    cursor: "pointer",
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                }}
                tabIndex={0}
                aria-label={`selected stereo expansion: ${getLabel()}`}
            >
                {stereoControlImage}
            </div>
        </div>
    );
}

function SubmitButton({
    originalTrackId,
    referenceTrack,
    openEnablePayPerUseDialog,
    onNewMastering,
    onClose,
    serviceDetails,
    masteringPresets,
    masteringType,
}: {
    originalTrackId: number | null;
    referenceTrack: ReferenceTrack | null;
    openEnablePayPerUseDialog: (callbacks?: {
        onActivate?: () => void;
        onClose?: () => void;
    }) => void;
    onNewMastering: (mastering: Mastering) => void;
    onClose: () => void;
    serviceDetails: LoadedServiceDetailsComputed;
    masteringPresets: MasteringPresets;
    masteringType: MasteringType;
}) {
    const [pricing] = usePricing();

    const { userShouldEnablePayPerUse } = usePayPerUseUtils(serviceDetails);

    const [submitting, setSubmitting] = useState(false);

    const { addNotification } = useNotifications();

    async function handleSubmit(originalTrackIdSubmitted: number) {
        setSubmitting(true);
        try {
            const sentReferenceTrackId =
                masteringPresets.style === "REFERENCE_TRACK"
                    ? referenceTrack!!.id
                    : null;

            const mastering = await Backend.createMastering(
                originalTrackIdSubmitted,
                masteringPresets,
                sentReferenceTrackId,
            );
            onNewMastering(mastering);
            onClose();
        } catch (e: any) {
            const errorCode = e.response?.data?.code;
            if (errorCode === "CANNOT_CREATE_MASTER") {
                addNotification({
                    severity: "error",
                    message:
                        "You have reached the total number of authorized masters.",
                });
            } else if (errorCode === "MASTERING_PAYMENT_FAILURE") {
                addNotification({
                    severity: "error",
                    message: "The payment has failed",
                });
            } else {
                sentryLogger.captureException(e);
                addNotification({
                    severity: "error",
                    message:
                        "The mastering could not be created due to an unexpected error",
                });
            }
            setSubmitting(false);
        }
    }

    return (
        <LoadingButton
            loading={submitting}
            variant="contained"
            disabled={
                !originalTrackId ||
                pricing.status === "ERROR" ||
                (masteringPresets.style === "REFERENCE_TRACK" &&
                    referenceTrack?.status !== "ANALYZED")
            }
            onClick={async () => {
                if (userShouldEnablePayPerUse(masteringType)) {
                    openEnablePayPerUseDialog({
                        onActivate: async () => {
                            if (originalTrackId) {
                                await handleSubmit(originalTrackId);
                            }
                        },
                    });
                } else if (originalTrackId) {
                    await handleSubmit(originalTrackId);
                }
            }}
        >
            Master
        </LoadingButton>
    );
}
