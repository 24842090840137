import { MutableRefObject, useEffect, useRef, useState } from "react";
import colors from "../theme/colors";
import toSecondsAndMinutes from "../toSecondsAndMinutes";

export type AudioWaveformDataTestId =
    | "original"
    | "mastered"
    | "preview-original"
    | "preview-mastered";

export default function AudioWaveform({
    waveformWidth,
    waveformHeight,
    disabled = true,
    dataTestId,
    dataPoints = null,
    durationInSeconds,
    currentTimeInSeconds,
    onCurrentTimeChanged,
}: {
    waveformWidth: number;
    waveformHeight: number;
    disabled: boolean;
    dataTestId: AudioWaveformDataTestId;
    dataPoints: number[] | null;
    durationInSeconds: number | undefined;
    currentTimeInSeconds: number;
    onCurrentTimeChanged: (newCurrentTimeInSeconds: number) => void;
}) {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [mousePosition, setMousePosition] = useState(0);

    useEffect(() => {
        const canvas = canvasRef.current!;

        if (!disabled) {
            const hover = (e: MouseEvent) => {
                setMousePosition(e.offsetX);
            };

            const changeTime = (e: MouseEvent) => {
                if (durationInSeconds) {
                    onCurrentTimeChanged(
                        (e.offsetX / waveformWidth) * durationInSeconds,
                    );
                }
            };

            const resetMousePosition = () => {
                setMousePosition(0);
            };

            canvas.addEventListener("mousemove", hover);
            canvas.addEventListener("mouseleave", resetMousePosition);
            canvas.addEventListener("click", changeTime);
            return () => {
                canvas.removeEventListener("mousemove", hover);
                canvas.removeEventListener("click", changeTime);
                canvas.removeEventListener("mouseleave", resetMousePosition);
            };
        }
        return () => {};
    }, [
        canvasRef,
        disabled,
        durationInSeconds,
        onCurrentTimeChanged,
        waveformWidth,
    ]);

    useEffect(() => {
        drawWaveform(
            waveformWidth,
            waveformHeight,
            dataPoints,
            currentTimeInSeconds,
            durationInSeconds,
            mousePosition,
            canvasRef,
            disabled,
        );
    }, [
        currentTimeInSeconds,
        dataPoints,
        disabled,
        durationInSeconds,
        mousePosition,
        waveformHeight,
        waveformWidth,
    ]);

    return (
        <div
            style={{
                fontSize: "12px",
                fontVariantNumeric: "tabular-nums",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <div
                aria-label="current time"
                style={{
                    width: "35px",
                    textAlign: "center",
                }}
            >
                {toSecondsAndMinutes(currentTimeInSeconds)}
            </div>
            <canvas
                data-testid={`audio-waveform-${dataTestId}`}
                ref={canvasRef}
                style={{
                    width: `${waveformWidth}px`,
                    height: `${waveformHeight}px`,
                    margin: "0px 10px",
                    cursor: "pointer",
                }}
            />
            <div
                aria-label="duration"
                style={{
                    width: "35px",
                    textAlign: "center",
                }}
            >
                {durationInSeconds
                    ? toSecondsAndMinutes(durationInSeconds)
                    : "--:--"}
            </div>
        </div>
    );
}

type DrawWaveformProperties = {
    barList: number[];
    waveformHeight: number;
    waveformWidth: number;
    centerHeight: number;
    numberOfBars: number;
    disabled: boolean;
};

const barWidth = 2;
const barSpacing = 3;

function drawWaveform(
    waveformWidth: number,
    waveformHeight: number,
    dataPoints: number[] | null,
    currentTimeInSeconds: number,
    durationInSeconds: number | undefined,
    mousePosition: number,
    canvasRef: MutableRefObject<HTMLCanvasElement | null>,
    disabled: boolean,
) {
    const numberOfBars = Math.floor(waveformWidth / (barWidth + barSpacing));
    const barList: number[] = Array.from({ length: numberOfBars }, () => 0);

    const centerHeight = waveformHeight / 2;

    const drawWaveformProperties: DrawWaveformProperties = {
        barList,
        waveformHeight,
        waveformWidth,
        centerHeight,
        numberOfBars,
        disabled,
    };

    computeBarList(drawWaveformProperties, dataPoints);

    const context = prepareContext(canvasRef, drawWaveformProperties);

    for (let index = 0; index < barList.length; index += 1) {
        drawBar(context, drawWaveformProperties, index);
    }
    if (!disabled && durationInSeconds) {
        drawPlayerPosition(
            context,
            drawWaveformProperties,
            currentTimeInSeconds,
            durationInSeconds,
        );
        drawMousePosition(context, waveformHeight, mousePosition);
    }
}

function computeBarList(
    drawWaveformProperties: DrawWaveformProperties,
    dataPoints: number[] | null,
) {
    const { barList } = drawWaveformProperties;

    let currentBarIndex = 0;
    if (dataPoints) {
        const valuesPerBar = Math.floor(
            dataPoints.length / drawWaveformProperties.numberOfBars,
        );

        dataPoints.forEach((value, index) => {
            if (barList[currentBarIndex] < value) {
                barList[currentBarIndex] = value;
            }
            if (index % valuesPerBar === 0) {
                currentBarIndex += 1;
            }
        });
    }
}

function prepareContext(
    canvasRef: MutableRefObject<HTMLCanvasElement | null>,
    drawWaveformProperties: DrawWaveformProperties,
) {
    const { devicePixelRatio } = window;
    const canvas = canvasRef.current!;
    canvas.height = drawWaveformProperties.waveformHeight * devicePixelRatio;
    canvas.width = drawWaveformProperties.waveformWidth * devicePixelRatio;

    const context = canvas.getContext("2d")!;
    context.scale(devicePixelRatio, devicePixelRatio);
    context.imageSmoothingQuality = "high";

    context.lineCap = "round";
    context.lineWidth = barWidth;
    context.strokeStyle = drawWaveformProperties.disabled
        ? colors.neutrals.n2
        : colors.neutrals.n1;

    return context;
}

function drawBar(
    context: CanvasRenderingContext2D,
    drawWaveformProperties: DrawWaveformProperties,
    index: number,
) {
    const currentPointHeight =
        (drawWaveformProperties.barList[index] *
            drawWaveformProperties.waveformHeight) /
        2;

    context.beginPath();
    context.moveTo(
        (barWidth + barSpacing) * index + barSpacing,
        drawWaveformProperties.centerHeight - currentPointHeight,
    );
    context.lineTo(
        (barWidth + barSpacing) * index + barSpacing,
        drawWaveformProperties.centerHeight + currentPointHeight,
    );
    context.stroke();
}

function drawPlayerPosition(
    context: CanvasRenderingContext2D,
    drawWaveformProperties: DrawWaveformProperties,
    currentTimeInSeconds: number,
    durationInSeconds: number,
) {
    const position =
        (currentTimeInSeconds / durationInSeconds) *
        drawWaveformProperties.waveformWidth;
    drawPosition(
        context,
        drawWaveformProperties.waveformHeight,
        position,
        colors.primary.p4,
    );
}

function drawMousePosition(
    context: CanvasRenderingContext2D,
    waveformHeight: number,
    mousePosition: number,
) {
    drawPosition(context, waveformHeight, mousePosition, "#FFFFFF77");
}

function drawPosition(
    context: CanvasRenderingContext2D,
    waveformHeight: number,
    yPos: number,
    color: string | CanvasGradient | CanvasPattern,
) {
    context.globalCompositeOperation = "source-atop";
    context.fillStyle = color;
    context.fillRect(0, 0, yPos, waveformHeight);
}
