import { z } from "zod";
import { feedbackSchema } from "./Feedback";
import { originalTrackSchema } from "./OriginalTrack";

const masteringStatusSchema = z.enum([
    "CREATED",
    "MASTERING",
    "MASTERED",
    "MASTERING_VERSION_MISMATCH_ERROR",
    "MASTERING_OTHER_ERROR",
]);

export type MasteringStatus = z.infer<typeof masteringStatusSchema>;

export const loudnessPresetSchema = z.enum(["LOW", "MEDIUM", "HIGH"]);

export type LoudnessPreset = z.infer<typeof loudnessPresetSchema>;

export const stylePresetSchema = z.enum([
    "POP",
    "ROCK",
    "HIPHOP",
    "EDM",
    "UNIVERSAL",
    "INSTRUMENTAL",
    "REFERENCE_TRACK",
]);

export type StylePreset = z.infer<typeof stylePresetSchema>;

export const lowPresetSchema = z.enum(["LESS", "DEFAULT", "MORE"]);

export type LowPreset = z.infer<typeof lowPresetSchema>;

export const highPresetSchema = z.enum(["LESS", "DEFAULT", "MORE"]);

export type HighPreset = z.infer<typeof highPresetSchema>;

export const compressionPresetSchema = z.enum([
    "OFF",
    "BALANCE",
    "PUNCH",
    "AGGRESSIVE",
]);

export type CompressionPreset = z.infer<typeof compressionPresetSchema>;

export const stereoPresetSchema = z.enum(["OFF", "DEFAULT", "MORE"]);

export type StereoPreset = z.infer<typeof stereoPresetSchema>;

export const masteringSchema = z.object({
    id: z.number(),
    referenceTrackFileName: z.string().nullable(),
    createdDate: z.string(),
    status: masteringStatusSchema,
    loudnessPreset: loudnessPresetSchema,
    stylePreset: stylePresetSchema,
    lowPreset: z.nullable(lowPresetSchema),
    highPreset: z.nullable(highPresetSchema),
    compressionPreset: z.nullable(compressionPresetSchema),
    stereoPreset: z.nullable(stereoPresetSchema),
    feedback: z.nullable(feedbackSchema),
    waveform: z.nullable(z.array(z.number())),
    matchingGain: z.nullable(z.number()),
    originalTrack: originalTrackSchema,
});

export type Mastering = z.infer<typeof masteringSchema>;

export function isMasteringInFinalStatus(status: MasteringStatus): boolean {
    const finalStatuses = [
        "MASTERED",
        "MASTERING_VERSION_MISMATCH_ERROR",
        "MASTERING_OTHER_ERROR",
    ];
    return finalStatuses.includes(status);
}
