import {
    Box,
    CircularProgress,
    Divider,
    IconButton,
    IconButtonProps,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import classNames from "classnames";
import { useAtom } from "jotai";
import { ForwardedRef, forwardRef } from "react";
import { Mastering, isMasteringInFinalStatus } from "../../../model/Mastering";
import musicNoteIconDark from "../../assets/music-note-icon-dark.svg";
import musicNoteIconLight from "../../assets/music-note-icon-light.svg";
import dates from "../../dates";
import PauseIcon from "../../icons/PauseIcon";
import PlayIcon from "../../icons/PlayIcon";
import playerAtoms from "../../state/atoms/playerAtoms";
import colors from "../../theme/colors";
import AudioWaveform from "../AudioWaveform";
import MasteringInformation from "../MasteringInformation";
import RemasterAction from "../RemasterAction";
import AdvancedPresetIconList from "./AdvancedPresetIconList";
import DownloadAction from "./DownloadAction";
import MasteringListEntryMenu from "./MasteringListEntryMenu";
import StatusIcon from "./StatusIcon";

const AccessibleIconButton = forwardRef(
    (props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
        const { disabled, ...finalProps } = props;

        // The following makes the component accessible when disabled.
        // This feature is also requested on MUI's github
        // https://github.com/mui/material-ui/issues/32917
        if (disabled) {
            delete finalProps.onClick;
            finalProps["aria-disabled"] = true;
            finalProps.className = classNames(
                finalProps.className,
                "Mui-disabled",
            );
        }

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <IconButton ref={ref} {...finalProps} />;
    },
);

export type PropsMasteringListEntry = {
    mastering: Mastering;
    index: number;
    onDeleteMastering: (mastering: Mastering) => void;
    hasUnexpectedError: boolean;
    openFeedbackDialog: (mastering: Mastering) => void;
    isCurrentSelectedMastering: boolean;
    masteredSelected: boolean;
    onSelectionRequest: (mastering: Mastering) => void;
    showRemasterDialog: (mastering: Mastering) => void;
    onPlayClick: (mastering: Mastering) => void;
    onPauseClick: () => void;
    isLoadingAudioSource: boolean;
    currentTimeInSeconds: number;
    onCurrentTimeChanged: (newCurrentTimeInSeconds: number) => void;
    downloadMasteredFile: (mastering: Mastering) => void;
};

export default function MasteringListEntry({
    index,
    mastering,
    onDeleteMastering,
    hasUnexpectedError,
    openFeedbackDialog,
    isCurrentSelectedMastering,
    masteredSelected,
    onSelectionRequest,
    showRemasterDialog,
    onPlayClick,
    onPauseClick,
    isLoadingAudioSource,
    currentTimeInSeconds,
    onCurrentTimeChanged,
    downloadMasteredFile,
}: PropsMasteringListEntry) {
    const [playRequested] = useAtom(playerAtoms.play);

    const theme = useTheme();

    const shouldRoundButtons = useMediaQuery("(min-width:600px)");
    const shouldReducePresetSize = useMediaQuery("(max-width:600px)");
    const shouldShowDate = useMediaQuery(theme.breakpoints.up("lg"));

    const shouldShowIndex = useMediaQuery(theme.breakpoints.up("lg"));
    const shouldShowReducedMasteringInformation = useMediaQuery(
        theme.breakpoints.up("sm"),
    );
    const shouldShowChipNextToTitle = useMediaQuery(theme.breakpoints.up("lg"));
    const shouldShowWaveform = useMediaQuery(theme.breakpoints.up("md"));

    const masteringStatus = hasUnexpectedError ? null : mastering.status;
    const isMasteringFinished =
        masteringStatus != null && isMasteringInFinalStatus(masteringStatus);
    const isMasteringSuccessful =
        !hasUnexpectedError && masteringStatus === "MASTERED";

    function handleDeleteSelf() {
        onDeleteMastering(mastering);
    }

    const playRequestedForThisMastering =
        playRequested && isCurrentSelectedMastering;

    function handlePlay(clickedMastering: Mastering) {
        if (playRequestedForThisMastering) {
            onPauseClick();
        } else if (isCurrentSelectedMastering) {
            onPlayClick(clickedMastering);
        } else {
            onSelectionRequest(clickedMastering);
        }
    }

    const playPauseIconStyle = { width: 18, height: 18 };
    let playPauseButtonIcon;
    if (!isLoadingAudioSource && playRequestedForThisMastering) {
        playPauseButtonIcon = <PauseIcon style={playPauseIconStyle} />;
    } else {
        playPauseButtonIcon = <PlayIcon style={playPauseIconStyle} />;
    }

    const isPlayLoading = isLoadingAudioSource && playRequestedForThisMastering;
    const isMasteringPlayable = isMasteringSuccessful && !isPlayLoading;

    const isOriginalWaveformDisplayed =
        isCurrentSelectedMastering && !masteredSelected;

    return (
        <Box
            role="row"
            aria-label="master"
            sx={{
                display: "flex",
                alignItems: "center",
                transition: "all 250ms",
                whiteSpace: "nowrap",
                background: isCurrentSelectedMastering
                    ? colors.neutrals.n3
                    : "transparent",
                borderRadius: shouldRoundButtons ? "6px" : "0px",
                hr: {
                    borderColor: isCurrentSelectedMastering
                        ? colors.neutrals.n1
                        : undefined,
                    margin: "3px",
                },
                ".index": {
                    cursor: "pointer",
                    marginTop: "-12px",
                    opacity: 1,
                },
                ".play-pause-button:focus-visible + .index": {
                    opacity: 0,
                },
                ".play-pause-button": {
                    borderRadius: "50%",
                    opacity: 0,
                },
                ".play-pause-button:focus-visible": {
                    opacity: 1,
                },
                ".music-note-icon": {
                    width: "32px",
                    height: "32px",
                    background: `url(${musicNoteIconLight}) no-repeat`,
                },
                "&:hover": {
                    backgroundColor: colors.neutrals.n4,
                    ".index": {
                        opacity: 0,
                    },
                    ".play-pause-button": {
                        opacity: 1,
                        "&:hover": {
                            backgroundColor: colors.neutrals.n4,
                        },
                    },
                    ".music-note-icon": {
                        background: `url(${musicNoteIconDark}) no-repeat`,
                    },
                    hr: { borderColor: colors.neutrals.n1 },
                },
            }}
        >
            <Box
                role="cell"
                aria-label="mastered track information"
                onClick={() => {
                    if (isMasteringPlayable) {
                        handlePlay(mastering);
                    }
                }}
                style={{
                    flexGrow: 1,
                    cursor: "pointer",
                    display: "inline-flex",
                    alignItems: "center",
                    padding: "12px",
                    gap: "5px",
                }}
            >
                {shouldShowIndex && (
                    <Box
                        style={{
                            width: "40px",
                            color: isCurrentSelectedMastering
                                ? colors.primary.p3
                                : "inherit",
                            position: "sticky",
                        }}
                    >
                        <AccessibleIconButton
                            sx={{
                                zIndex: 1,
                            }}
                            className="play-pause-button entry"
                            onClick={() => {
                                handlePlay(mastering);
                            }}
                            aria-label={
                                playRequestedForThisMastering &&
                                !isLoadingAudioSource
                                    ? "pause"
                                    : "play"
                            }
                            disabled={!isMasteringPlayable}
                        >
                            {playPauseButtonIcon}
                            {isPlayLoading && (
                                <CircularProgress
                                    sx={{
                                        position: "absolute",
                                    }}
                                    size={40}
                                    color="secondary"
                                    aria-label="loading preview"
                                />
                            )}
                        </AccessibleIconButton>
                        <Box
                            className="index entry"
                            sx={{
                                position: "absolute",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                cursor: "default",
                                top: 13,
                                right: 20,
                            }}
                            width="34px"
                            height="34px"
                        >
                            {index + 1}
                        </Box>
                    </Box>
                )}

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: shouldShowReducedMasteringInformation
                                ? "280px"
                                : "230px",
                            whiteSpace: "nowrap",
                            color: isCurrentSelectedMastering
                                ? colors.primary.p3
                                : "inherit",
                        }}
                    >
                        <MasteringInformation
                            mastering={mastering}
                            reduced={shouldShowReducedMasteringInformation}
                            variant={
                                isCurrentSelectedMastering
                                    ? "primary"
                                    : "default"
                            }
                        />
                    </Box>
                    <Box
                        style={{
                            width: shouldReducePresetSize ? "40px" : "130px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: shouldReducePresetSize
                                ? "20px"
                                : undefined,
                            marginLeft: shouldReducePresetSize
                                ? "-20px"
                                : undefined,
                        }}
                    >
                        <AdvancedPresetIconList mastering={mastering} />
                    </Box>
                </Box>

                {shouldShowWaveform && (
                    <Box
                        onClick={(e) => {
                            if (isCurrentSelectedMastering) {
                                e.stopPropagation();
                            }
                        }}
                        style={{
                            width: "265px",
                        }}
                    >
                        {isMasteringSuccessful && (
                            <AudioWaveform
                                disabled={!isCurrentSelectedMastering}
                                waveformWidth={140}
                                waveformHeight={40}
                                dataPoints={
                                    isOriginalWaveformDisplayed
                                        ? mastering.originalTrack.waveform
                                        : mastering.waveform
                                }
                                dataTestId={
                                    isOriginalWaveformDisplayed
                                        ? "original"
                                        : "mastered"
                                }
                                durationInSeconds={
                                    mastering.originalTrack
                                        .inputAudioFileMetadata!
                                        .durationInSeconds
                                }
                                currentTimeInSeconds={currentTimeInSeconds}
                                onCurrentTimeChanged={onCurrentTimeChanged}
                            />
                        )}
                    </Box>
                )}
                <StatusIcon
                    isMasteringFinished={isMasteringFinished}
                    isMasteringSuccessful={isMasteringSuccessful}
                    hasUnexpectedError={hasUnexpectedError}
                    masteringStatus={masteringStatus}
                />
                {shouldShowDate && (
                    <Box
                        aria-label="creation date"
                        style={{ width: "110px", paddingLeft: "40px" }}
                    >
                        {dates.formatDateTime(mastering.createdDate)}
                    </Box>
                )}
            </Box>

            <div
                role="cell"
                aria-label="actions"
                style={{
                    padding: shouldShowChipNextToTitle ? "12px" : "0px",
                }}
            >
                <MasteringActionSection
                    index={index}
                    onDelete={handleDeleteSelf}
                    mastering={mastering}
                    isMasteringSuccessful={isMasteringSuccessful}
                    onShowRemasterDialog={showRemasterDialog}
                    onRateClick={() => openFeedbackDialog(mastering)}
                    onDownLoadClick={() => downloadMasteredFile(mastering)}
                />
            </div>
        </Box>
    );
}

function MasteringActionSection({
    index,
    mastering,
    onDownLoadClick,
    onRateClick,
    onDelete,
    onShowRemasterDialog,
    isMasteringSuccessful,
}: {
    index: number;
    mastering: Mastering;
    onDownLoadClick: () => void;
    onRateClick: () => void;
    onDelete: () => void;
    onShowRemasterDialog: (mastering: Mastering) => void;
    isMasteringSuccessful: boolean;
}) {
    const theme = useTheme();
    const shouldShowDivider = useMediaQuery(theme.breakpoints.up("md"));
    const allActionsInMoreMenu = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: allActionsInMoreMenu ? "40px" : "140px",
            }}
        >
            {shouldShowDivider && (
                <Divider orientation="vertical" aria-hidden />
            )}

            {isMasteringSuccessful && !allActionsInMoreMenu && (
                <>
                    <DownloadAction onClick={onDownLoadClick} fontSize={18} />
                    <RemasterAction
                        onClick={() => onShowRemasterDialog(mastering)}
                        fontSize={18}
                    />
                </>
            )}
            <MasteringListEntryMenu
                index={index}
                mastering={mastering}
                onDeleteSuccessful={onDelete}
                isMasteringSuccessful={isMasteringSuccessful}
                showRemasterDialog={onShowRemasterDialog}
                onDownload={onDownLoadClick}
                onRate={onRateClick}
                allActionsInMoreMenu={allActionsInMoreMenu}
            />
        </Box>
    );
}
