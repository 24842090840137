import { atom, createStore, useAtom } from "jotai";
import { Quota } from "../../Backend";

const serviceDetailsStore = createStore();

type ServiceDetails = LoadingServiceDetails | LoadedServiceDetails;

type LoadingServiceDetails = { status: "LOADING" };

export type LoadedServiceDetails =
    | {
          status: "LOADED";
          hasAap: true;
          initialMasterQuota: Quota;
          remasterQuota: Quota;
          bonusRemaining: number;
          canBenefitPpuPromo: boolean;
      }
    | {
          status: "LOADED";
          hasAap: false;
          initialMasterQuota: { value: 0; usage: 0 };
          remasterQuota: { value: 0; usage: 0 };
          bonusRemaining: number;
          canBenefitPpuPromo: boolean;
          shouldDisplayAapPromotionBanner: boolean;
      };

export type ServiceDetailsComputed =
    | LoadingServiceDetails
    | LoadedServiceDetailsComputed;

export type LoadedServiceDetailsComputed = LoadedServiceDetails & {
    initialMasterQuotaRemaining: number;
    remasterQuotaRemaining: number;
    isBonusRemaining: boolean;
    isNextInitialMasterFree: boolean;
    isNextRemasterFree: boolean;
};

const serviceDetailsAtom = atom<ServiceDetails>({
    status: "LOADING",
});

export default function useServiceDetails(): {
    serviceDetails: ServiceDetailsComputed;
    setServiceDetails: (newValue: ServiceDetails) => void;
} {
    const [serviceDetails, setServiceDetails] = useAtom(serviceDetailsAtom, {
        store: serviceDetailsStore,
    });

    const serviceDetailsComputed = addComputedProperties(serviceDetails);

    return {
        serviceDetails: serviceDetailsComputed,
        setServiceDetails,
    };
}

export function setServiceDetailsExternally(newValue: ServiceDetails) {
    serviceDetailsStore.set(serviceDetailsAtom, newValue);
}

function addComputedProperties(
    serviceDetails: ServiceDetails,
): ServiceDetailsComputed {
    if (serviceDetails.status === "LOADED") {
        return addServiceDetailsComputedProperties(serviceDetails);
    }
    return serviceDetails;
}

export function addServiceDetailsComputedProperties(
    input: LoadedServiceDetails,
): LoadedServiceDetailsComputed {
    const initialMasterQuotaRemaining =
        input.initialMasterQuota.value - input.initialMasterQuota.usage;
    const isInitialMasterQuotaRemaining = initialMasterQuotaRemaining > 0;

    const remasterQuotaRemaining =
        input.remasterQuota.value - input.remasterQuota.usage;
    const isRemasterQuotaRemaining = remasterQuotaRemaining > 0;

    const isBonusRemaining = input.bonusRemaining > 0;

    const isNextInitialMasterFree =
        isInitialMasterQuotaRemaining ||
        isBonusRemaining ||
        input.canBenefitPpuPromo;

    const isNextRemasterFree =
        isRemasterQuotaRemaining ||
        isBonusRemaining ||
        input.canBenefitPpuPromo;

    return {
        ...input,
        initialMasterQuotaRemaining,
        remasterQuotaRemaining,
        isBonusRemaining,
        isNextInitialMasterFree,
        isNextRemasterFree,
    };
}
