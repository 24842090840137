import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg fill="none" viewBox="0 0 15 15">
        <path
            d="M1 2.00002C1 2.00002 3 4.00002 7.5 4.00002C12 4.00002 14 2.00002 14 2.00002"
            stroke="#4DA1FF"
            strokeLinecap="round"
        />
        <path
            d="M14 13C14 13 12 11 7.5 11C3 11 1 13 1 13"
            stroke="#9B4DFF"
            strokeLinecap="round"
        />
        <path
            d="M7.5 5.50002V9.50002M5 5.75002V9.25002M10 5.75002V9.25002M12.5 6.00002V9.00002M2.5 6.00002V9.00002"
            stroke="url(#paint0_linear_240_227)"
            strokeLinecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_240_227"
                x1="7.5"
                y1="5.50002"
                x2="7.5"
                y2="9.50002"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#628AFF" />
                <stop offset="1" stopColor="#9B4DFF" />
            </linearGradient>
        </defs>
    </svg>,
    "CompressionAggressive",
);
