import { IconButton, Tooltip } from "@mui/material";
import { Mastering } from "../../model/Mastering";
import StarBorderIcon from "../icons/StarBorderIcon";
import StarIcon from "../icons/StarIcon";

export default function RateAction({
    mastering,
    onClick,
    fontSize,
}: {
    mastering: Mastering;
    onClick: () => void;
    fontSize?: number;
}) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                width: "51px",
            }}
        >
            {mastering.feedback == null ? (
                <Tooltip title="Rate this master">
                    <IconButton
                        onClick={onClick}
                        aria-label="rate experience"
                        style={{
                            padding: "11px",
                        }}
                    >
                        <StarBorderIcon
                            style={{
                                fontSize: fontSize
                                    ? `${fontSize}px`
                                    : "inherit",
                            }}
                        />
                    </IconButton>
                </Tooltip>
            ) : (
                <>
                    <IconButton onClick={onClick} aria-label="rate experience">
                        <StarIcon
                            style={{
                                fontSize: fontSize
                                    ? `${fontSize}px`
                                    : "inherit",
                            }}
                        />
                    </IconButton>
                    <span data-testid="rating" style={{ marginLeft: "2px" }}>
                        {mastering.feedback.rating}
                    </span>
                </>
            )}
        </div>
    );
}
