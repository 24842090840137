export default function VirtuVideo() {
    return (
        <video
            style={{
                border: "none",
                position: "absolute",
                height: "100%",
                width: "100%",
                objectFit: "cover",
                filter: "blur(2px)",
            }}
            autoPlay={
                !window.matchMedia("(prefers-reduced-motion: reduce)").matches
            }
            muted
            loop
            data-testid="virtu-video"
        >
            <source
                src="https://cdn.virtu.slatedigital.com/hero-content-1000kbps-vp8.webm"
                type="video/webm"
            />
        </video>
    );
}
