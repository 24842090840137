import axios from "axios";
import { AuthenticatedUser } from "./model/AuthenticatedUser";
import { setServiceDetailsExternally } from "./view/state/useServiceDetails";

export const authenticatedBackend = axios.create();

export function registerUserForHttpAuthentication(
    newAuthenticatedUser: AuthenticatedUser | null,
) {
    authenticatedBackend.defaults.headers.common = {
        Authorization: newAuthenticatedUser?.authorizationHeader,
    };
}

if (process.env.NODE_ENV === "development") {
    simulateNetworkLatency();
}

function simulateNetworkLatency() {
    const delayMs = 500;
    console.log(
        `Simulating network latency by adding a delay of ${delayMs}ms to all backend requests`,
    );

    authenticatedBackend.interceptors.response.use(async (response) => {
        await sleep(delayMs);
        return response;
    });
}

function sleep(delayMs: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, delayMs);
    });
}

type ErrorListener = (error?: any) => void;
const authenticatedBackendErrorListeners: ErrorListener[] = [];

export function addAuthenticatedBackendErrorListener(
    errorHandler: ErrorListener,
) {
    authenticatedBackendErrorListeners.push(errorHandler);
}

export function removeAuthenticatedBackendErrorListener(
    errorHandler: ErrorListener,
) {
    authenticatedBackendErrorListeners.splice(
        authenticatedBackendErrorListeners.indexOf(errorHandler),
    );
}

authenticatedBackend.interceptors.response.use(
    (response) => {
        if (response && response.headers) {
            const initialMasterQuotaValue = parseInt(
                response.headers["x-initial-master-quota-value"],
                10,
            );
            const initialMasterQuotaUsage = parseInt(
                response.headers["x-initial-master-quota-usage"],
                10,
            );
            const remasterQuotaValue = parseInt(
                response.headers["x-remaster-quota-value"],
                10,
            );
            const remasterQuotaUsage = parseInt(
                response.headers["x-remaster-quota-usage"],
                10,
            );

            const bonusRemaining = parseInt(
                response.headers["x-bonus-mastering-remaining"],
                10,
            );
            const shouldDisplayAapPromotionBanner =
                response.headers["x-display-aap-promotion-banner"] === "true";

            const canBenefitPpuPromo =
                response.headers["x-can-benefit-ppu-promo"] === "true";

            if (initialMasterQuotaValue >= 0) {
                setServiceDetailsExternally({
                    status: "LOADED",
                    ...(initialMasterQuotaValue === 0
                        ? {
                              hasAap: false,
                              initialMasterQuota: { value: 0, usage: 0 },
                              remasterQuota: { value: 0, usage: 0 },
                              shouldDisplayAapPromotionBanner,
                          }
                        : {
                              hasAap: true,
                              initialMasterQuota: {
                                  value: initialMasterQuotaValue,
                                  usage: initialMasterQuotaUsage,
                              },
                              remasterQuota: {
                                  value: remasterQuotaValue,
                                  usage: remasterQuotaUsage,
                              },
                          }),
                    bonusRemaining,
                    canBenefitPpuPromo,
                });
            }
        }

        return response;
    },
    (error) => {
        authenticatedBackendErrorListeners.forEach((it) => it(error));
        return Promise.reject(error);
    },
);
