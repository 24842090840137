import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/react/utils";
import { useCallback } from "react";
import { Notification } from "./Notification";
import atoms from "./atoms";

export default function useNotifications() {
    const setNotifications = useSetAtom(atoms.notifications);

    // This is necessary to avoid glitches if used in stable captured contexts (e.g. setTimeout)
    const getCurrentNotifications = useAtomCallback(
        useCallback((get) => get(atoms.notifications), []),
    );

    const addNotification = useCallback(
        (notification: Notification) => {
            const notifications = getCurrentNotifications();

            const addedNotification = {
                id:
                    notifications
                        .map((it) => it.id)
                        .reduce((x, y) => Math.max(x, y), 0) + 1,
                ...notification,
            };

            setNotifications([...notifications, addedNotification]);

            return addedNotification;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const removeNotification = useCallback(
        (notification: { id: number } & Notification) => {
            const notifications = getCurrentNotifications();

            setNotifications([
                ...notifications.filter((it) => it !== notification),
            ]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return {
        addNotification,
        removeNotification,
    };
}
