import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Avatar, Button, Link, Paper, useTheme } from "@mui/material";
import config from "../config";
import { RegisteredUser } from "../model/User";

export default function ProfilePage({ user }: { user: RegisteredUser }) {
    const theme = useTheme();

    const serverUrl = config.get().authenticationServerUrl;

    return (
        <Paper
            sx={{
                padding: "32px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "16px",
                }}
            >
                <Avatar
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                    }}
                >
                    {user.email[0].toUpperCase()}
                </Avatar>
                <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                    {user.email}
                </div>
            </div>
            <div
                style={{
                    marginTop: "16px",
                    display: "flex",
                }}
            >
                <Button
                    sx={{ marginLeft: "auto" }}
                    endIcon={<OpenInNewIcon />}
                    variant="contained"
                    component={Link}
                    disabled={serverUrl === null}
                    href={`${serverUrl}/users/${user.slateDigitalUserId}/account-info`}
                    target="_blank"
                    rel="noopener"
                >
                    Manage
                </Button>
            </div>
        </Paper>
    );
}
