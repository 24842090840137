import { Link } from "@mui/material";
import messageQuestion from "../../assets/message-question.svg";
import externalUrls from "../../externalUrls";
import colors from "../../theme/colors";

export default function FAQLink() {
    return (
        <Link
            href={externalUrls.faq}
            underline="hover"
            target="_blank"
            rel="noopener"
            style={{
                color: colors.neutrals.n1,
                fontSize: 14,
                fontWeight: 400,
                display: "flex",
                alignItems: "center",
            }}
        >
            <img
                style={{
                    display: "flex",
                    width: 18,
                    height: 18,
                    marginRight: "9px",
                }}
                src={messageQuestion}
                alt="FAQ icon"
            />
            FAQ
        </Link>
    );
}
