import * as Sentry from "@sentry/browser";
import { useAtom } from "jotai";
import { useCallback } from "react";
import { registerUserForHttpAuthentication } from "../../AxiosConfiguration";
import Backend from "../../Backend";
import StorageService from "../../StorageService";
import googleTagManager from "../../gtm/googleTagManager";
import { AuthenticatedUser } from "../../model/AuthenticatedUser";
import atoms from "./atoms";
import { setServiceDetailsExternally } from "./useServiceDetails";

const AUTHENTICATED_USER_KEY = "authenticatedUser";

export default function useAuthentication() {
    const [authenticationState, setAuthenticationState] = useAtom(
        atoms.authenticationState,
    );
    const [
        attemptedAuthenticationRefreshFromCache,
        setAttemptedAuthenticationRefreshFromCache,
    ] = useAtom(atoms.attemptedAuthenticationRefreshFromCache);

    const updateAuthenticatedUser = useCallback(
        (updatedAuthenticatedUser: AuthenticatedUser | null) => {
            if (updatedAuthenticatedUser == null) {
                StorageService.removeItem(AUTHENTICATED_USER_KEY);
                Sentry.setUser(null);
                setAuthenticationState({
                    type: "NOT_AUTHENTICATED",
                });
            } else {
                StorageService.setItem(
                    AUTHENTICATED_USER_KEY,
                    updatedAuthenticatedUser,
                );
                if ("slateDigitalUserId" in updatedAuthenticatedUser) {
                    googleTagManager.trackSignIn(
                        updatedAuthenticatedUser.email,
                    );

                    setAuthenticationState({
                        type: "REGISTERED_USER_AUTHENTICATED",
                        authenticatedUser: updatedAuthenticatedUser,
                    });
                } else {
                    setAuthenticationState({
                        type: "GUEST_USER_AUTHENTICATED",
                        authenticatedUser: updatedAuthenticatedUser,
                    });
                }
            }

            Sentry.setUser({
                username: updatedAuthenticatedUser?.id?.toString(),
            });
            registerUserForHttpAuthentication(updatedAuthenticatedUser);
        },
        [setAuthenticationState],
    );

    const initializeAuthentication = useCallback(
        (abortSignal: AbortSignal) => {
            const cachedAuthenticatedUser = StorageService.getItem(
                AUTHENTICATED_USER_KEY,
            ) as AuthenticatedUser | null;
            updateAuthenticatedUser(cachedAuthenticatedUser);

            async function refreshUser() {
                if (cachedAuthenticatedUser != null) {
                    const user = await Backend.getCurrentUser(abortSignal);
                    const updatedAuthenticatedUser = {
                        ...cachedAuthenticatedUser,
                        ...user,
                    };
                    updateAuthenticatedUser(updatedAuthenticatedUser);
                }
            }

            refreshUser()
                .catch((e) => {
                    if (!abortSignal.aborted) {
                        console.log("Unable to refresh user", e);
                    }
                })
                .finally(() => {
                    if (!abortSignal.aborted) {
                        setAttemptedAuthenticationRefreshFromCache(true);
                    }
                });
        },
        [updateAuthenticatedUser, setAttemptedAuthenticationRefreshFromCache],
    );

    const login = useCallback(
        async (token: string | null): Promise<void> => {
            setAuthenticationState({
                type:
                    token == null
                        ? "GUEST_USER_AUTHENTICATING"
                        : "REGISTERED_USER_AUTHENTICATING",
            });
            try {
                const authenticatedUser = await Backend.login(token);
                updateAuthenticatedUser(authenticatedUser);
            } catch (e) {
                updateAuthenticatedUser(null);
                throw e;
            }
        },
        [updateAuthenticatedUser, setAuthenticationState],
    );

    const logout = useCallback(() => {
        updateAuthenticatedUser(null);
        setServiceDetailsExternally({ status: "LOADING" });
    }, [updateAuthenticatedUser]);

    return {
        initializeAuthentication,
        authenticationState,
        attemptedAuthenticationRefreshFromCache,
        login,
        logout,
    };
}
