import sentryLogger from "../../sentryLogger";
import useNotifications from "../state/useNotifications";
import usePayPerUse from "../state/usePayPerUse";
import useConfirmationDialog from "../useConfirmationDialog";

export default function useDisablePayPerUseDialog() {
    const { addNotification } = useNotifications();
    const { disablePayPerUse } = usePayPerUse();

    const { confirmationDialogElement, openConfirmationDialog } =
        useConfirmationDialog({
            question: "Switch off Pay Per Use?",
            details:
                "You will be prompted to link your payment method again the next time a payment is required.",
            confirmationButtonLabel: "Switch off",
            confirmationButtonColor: "primary",

            confirm: async () => {
                try {
                    await disablePayPerUse();
                    return "CLOSE_DIALOG";
                } catch (e) {
                    sentryLogger.captureException(e);
                    addNotification({
                        severity: "error",
                        message:
                            "Pay Per Use cannot be switched off. Please retry later.",
                    });
                    return "KEEP_DIALOG_OPEN";
                }
            },
        });

    return {
        disablePayPerUseDialogElement: confirmationDialogElement,
        openDisablePayPerUseDialog: openConfirmationDialog,
    };
}
