import {
    Button,
    Divider,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import redirectToSignInPage from "../redirectToSignInPage";
import useAuthentication from "../state/useAuthentication";
import AccountMenu from "./AccountMenu";
import AllQuotaDisplay from "./AllQuotaDisplay";
import Branding from "./Branding";
import PayPerUseSwitch from "./PayPerUseSwitch";

export default function MainHeader() {
    const { authenticationState } = useAuthentication();

    const compactMode = useMediaQuery(useTheme().breakpoints.down("md"));

    return (
        <header
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "68px",
                backgroundColor: "#000",
                padding: "3px 12px 3px 20px",
            }}
        >
            <Branding />
            {authenticationState.type === "REGISTERED_USER_AUTHENTICATED" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <AllQuotaDisplay />
                    <PayPerUseSwitch compactMode={compactMode} />
                    <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        style={{
                            marginLeft: "8px",
                            marginRight: "8px",
                        }}
                    />
                    <AccountMenu user={authenticationState.authenticatedUser} />
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                    }}
                >
                    {!compactMode && <Typography>Have an account?</Typography>}
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => redirectToSignInPage()}
                        disabled={
                            authenticationState.type ===
                                "REGISTERED_USER_AUTHENTICATING" ||
                            authenticationState.type ===
                                "GUEST_USER_AUTHENTICATING"
                        }
                    >
                        Sign In
                    </Button>
                </div>
            )}
        </header>
    );
}
