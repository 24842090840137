import {
    Alert,
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { BillingInformation } from "../../model/BillingInformation";
import sentryLogger from "../../sentryLogger";
import BraintreeDropinWrapper from "../BraintreeDropinWrapper";
import CloseButton from "../CloseButton";
import formatTotalPrice from "../formatTotalPrice";
import SquarePayPerUseIcon from "../icons/SquarePayPerUseIcon";
import useNotifications from "../state/useNotifications";
import usePayPerUse from "../state/usePayPerUse";
import usePricing from "../state/usePricing";
import useServiceDetails from "../state/useServiceDetails";
import colors from "../theme/colors";
import BillingInformationSection from "./BillingInformationSection/BillingInformationSection";
import MarketingCard from "./MarketingCard";

export default function useEnablePayPerUseDialog() {
    const { addNotification } = useNotifications();

    const [state, setState] = useState<
        | {
              open: false;
              callbacks?: undefined;
          }
        | {
              open: true;
              callbacks?: { onActivate?: () => void };
          }
    >({ open: false });

    const handleActivate = () => {
        state.callbacks?.onActivate?.call(undefined);
        setState({ open: false });
    };

    const notifyUser = useCallback(() => {
        addNotification({
            severity: "error",
            message:
                "An error occured while trying to fetch data from the server. Please retry later.",
            manualDismiss: true,
        });
    }, [addNotification]);

    const handleClose = useCallback(() => {
        setState({ open: false });
    }, []);

    const handlePricingError = useCallback(() => {
        notifyUser();
        handleClose();
    }, [handleClose, notifyUser]);

    const handleBillingError = useCallback(
        (e: Error) => {
            sentryLogger.captureException(e);
            notifyUser();
            handleClose();
        },
        [handleClose, notifyUser],
    );

    return {
        enablePayPerUseDialogElement: (
            <UseEnablePayPerUseDialog
                open={state.open}
                onActivate={() => {
                    handleActivate();
                }}
                onClose={() => {
                    handleClose();
                }}
                onBillingError={handleBillingError}
                onPricingError={handlePricingError}
            />
        ),
        openEnablePayPerUseDialog: useCallback(
            (callbacks?: { onActivate?: () => void; onClose?: () => void }) => {
                setState({ open: true, callbacks });
            },
            [setState],
        ),
    };
}

function UseEnablePayPerUseDialog({
    open,
    onActivate,
    onClose,
    onBillingError,
    onPricingError,
}: {
    open: boolean;
    onActivate: () => void;
    onClose: () => void;
    onBillingError: (e: Error) => void;
    onPricingError: () => void;
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    return (
        <Dialog
            sx={{
                ".MuiPaper-root": {
                    maxWidth: "1000px",
                },
            }}
            open={open}
            onClose={(_event, reason) => {
                if (reason !== "backdropClick") {
                    onClose();
                }
            }}
            fullScreen={isMobile}
        >
            <EnablePayPerUseDialogContents
                onActivate={onActivate}
                onClose={onClose}
                onBillingError={onBillingError}
                onPricingError={onPricingError}
            />
        </Dialog>
    );
}

function EnablePayPerUseDialogContents({
    onActivate,
    onClose,
    onBillingError,
    onPricingError,
}: {
    onActivate: () => void;
    onClose: () => void;
    onBillingError: (e: Error) => void;
    onPricingError: () => void;
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const { serviceDetails } = useServiceDetails();

    const hasAap = serviceDetails.status === "LOADED" && serviceDetails.hasAap;

    const canBenefitPpuPromo =
        serviceDetails.status === "LOADED" && serviceDetails.canBenefitPpuPromo;

    const { addNotification } = useNotifications();

    const { enablePayPerUse } = usePayPerUse();

    const [pricing, refreshPricing] = usePricing();

    const [submitting, setSubmitting] = useState(false);

    const [billingInfoCollapsed, setBillingInfoCollapsed] = useState(false);
    const [savedBillingInformation, setSavedBillingInformation] =
        useState<BillingInformation>();

    const [nonceGetter, setNonceGetter] = useState<() => Promise<string>>();
    const [dropinDisabled, setDropinDisabled] = useState(true);

    useEffect(() => {
        if (pricing.status === "ERROR") {
            onPricingError();
        }
    }, [onPricingError, pricing.status]);

    async function enable() {
        try {
            const paymentMethodNonce = await nonceGetter!!();
            setSubmitting(true);
            await enablePayPerUse(paymentMethodNonce);
            setDropinDisabled(true);
            onActivate();
        } catch (e: any) {
            sentryLogger.captureException(e);
            addNotification({
                severity: "error",
                message:
                    "Pay Per Use cannot be switched on. Please retry later.",
            });
        } finally {
            setSubmitting(false);
        }
    }

    const onPaymentMethodRequestable = useCallback(
        (newNonceGetter: () => Promise<string>) => {
            setNonceGetter(() => newNonceGetter);
        },
        [],
    );

    const onNoPaymentMethodRequestable = useCallback(() => {
        setNonceGetter(undefined);
    }, []);

    return (
        <>
            <DialogTitle>
                <SquarePayPerUseIcon
                    inheritViewBox
                    style={{ width: 36, height: 36, marginRight: "16px" }}
                />
                <span style={{ marginRight: "10px" }}>
                    Go unlimited! Pay Per Use
                </span>
                <div style={{ marginLeft: "auto", marginRight: "-11px" }}>
                    <CloseButton
                        onClose={() => {
                            onClose();
                        }}
                    />
                </div>
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: "22px",
                    height: "660px",
                    width: isMobile ? "100%" : "956px",
                }}
            >
                {pricing.status === "LOADED" && (
                    <Stack spacing={3}>
                        <Typography
                            style={{
                                marginTop: "16px",
                                fontSize: "16px",
                                color: colors.newColors.neutrals.n1,
                            }}
                        >
                            To access unlimited mastering, activate “Pay Per
                            Use” and link your payment method. All masters{" "}
                            {hasAap && "above your free monthly quota "}
                            will cost{" "}
                            {formatTotalPrice(
                                pricing.masteringPriceWithTax,
                            )}{" "}
                            per track (you can turn this option off at any
                            time).
                        </Typography>

                        {canBenefitPpuPromo && (
                            <Alert severity="info" variant="outlined">
                                You will be granted two free masters by
                                providing your billing information for the first
                                time! (Expires after 30 days)
                                <br />
                            </Alert>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: isMobile
                                    ? "column-reverse"
                                    : "row-reverse",
                                justifyContent: "stretch",
                                alignItems: "stretch",
                                gap: "16px",
                                ...(isMobile && {
                                    flexWrap: "wrap",
                                }),
                            }}
                        >
                            <MarketingCard
                                canBenefitPpuPromo={canBenefitPpuPromo}
                                loading={submitting}
                                onClick={enable}
                                disabled={
                                    nonceGetter === undefined || dropinDisabled
                                }
                                masteringPriceWithTax={
                                    pricing.masteringPriceWithTax
                                }
                            />
                            <Stack spacing={3} sx={{ flexGrow: 1 }}>
                                <BillingInformationSection
                                    initialBillingInformation={
                                        savedBillingInformation
                                    }
                                    onSaveAndContinue={(
                                        newBillingInformation,
                                    ) => {
                                        setSavedBillingInformation(
                                            newBillingInformation,
                                        );
                                        setDropinDisabled(false);
                                        setBillingInfoCollapsed(true);
                                        // noinspection JSIgnoredPromiseFromCall
                                        refreshPricing();
                                    }}
                                    onChange={() => {
                                        setDropinDisabled(true);
                                        setBillingInfoCollapsed(false);
                                    }}
                                    onError={onBillingError}
                                    collapsed={billingInfoCollapsed}
                                />
                                {!dropinDisabled && (
                                    <BraintreeDropinWrapper
                                        onPaymentMethodRequestable={
                                            onPaymentMethodRequestable
                                        }
                                        onNoPaymentMethodRequestable={
                                            onNoPaymentMethodRequestable
                                        }
                                    />
                                )}
                            </Stack>
                        </Box>
                    </Stack>
                )}
                {pricing.status === "LOADING" && (
                    <div
                        style={{
                            marginTop: "48px",
                            marginBottom: "16px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress aria-label="loading pricing details" />
                    </div>
                )}
                {pricing.status === "ERROR" && (
                    <div
                        style={{
                            marginTop: "48px",
                            marginBottom: "16px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Cannot get pricing details, please retry later.
                    </div>
                )}
            </DialogContent>
        </>
    );
}
