import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <>
        <rect
            x="0.5"
            y="0.5"
            width="36"
            height="36"
            rx="8"
            fill="url(#paint0_linear_3576_75229)"
        />
        <path
            d="M12.6559 19.5669H15.7459V25.5669C15.7459 26.4502 17.0659 26.8669 17.7659 26.2002L25.3359 19.0336C25.9959 18.4086 25.4659 17.4336 24.4659 17.4336H21.3759V11.4336C21.3759 10.5502 20.0559 10.1336 19.3559 10.8002L11.7859 17.9669C11.1359 18.5919 11.6659 19.5669 12.6559 19.5669Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_3576_75229"
                x1="328.173"
                y1="1.86321"
                x2="326.06"
                y2="-25.0007"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#9B4DFF" />
                <stop offset="1" stopColor="#4DA1FF" />
            </linearGradient>
        </defs>
    </>,
    "SquarePayPerUse",
);
