import useConfirmationDialog from "../../useConfirmationDialog";

export default function useDeleteReferenceTrackDialog({
    onReferenceTrackDeleted,
}: {
    onReferenceTrackDeleted: () => void;
}) {
    const { confirmationDialogElement, openConfirmationDialog } =
        useConfirmationDialog({
            question: "Delete this reference track ?",
            details: null,
            confirmationButtonLabel: "Delete",
            confirmationButtonColor: "error",

            confirm: () => {
                onReferenceTrackDeleted();
                return Promise.resolve("CLOSE_DIALOG");
            },
        });

    return {
        deleteReferenceTrackDialogElement: confirmationDialogElement,
        openDeleteReferenceTrackDialog: openConfirmationDialog,
    };
}
