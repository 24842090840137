import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Tab,
    Tabs,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import classNames from "classnames";
import { useAtom, useSetAtom } from "jotai";
import React, { useCallback, useEffect, useState } from "react";
import AudioCachingService from "../../AudioCachingService";
import Backend from "../../Backend";
import { Mastering, isMasteringInFinalStatus } from "../../model/Mastering";
import { RegisteredUser } from "../../model/User";
import poll from "../../poll";
import sentryLogger from "../../sentryLogger";
import PageContent from "../PageContent";
import backgroundShapeLeft from "../assets/background-shape-left.png";
import backgroundShapeRight from "../assets/background-shape-right.png";
import forceDownloadFile from "../forceDownloadFile";
import MasterIcon from "../icons/MasterIcon";
import masteringListAtom from "../state/atoms/masteringListAtom";
import playerAtoms from "../state/atoms/playerAtoms";
import useNotifications from "../state/useNotifications";
import useServiceDetails from "../state/useServiceDetails";
import colors from "../theme/colors";
import FeedbackDialog from "./FeedbackDialog/FeedbackDialog";
import FirstMasteringCard from "./FirstMasteringCard";
import MasteringListEntry from "./MasteringListEntry/MasteringListEntry";
import MasteringPlayerAudioSource from "./MasteringPlayerAudioSource";
import NewMasteringButton from "./NewMasteringButton";
import StickyPlayer from "./StickyPlayer";
import Studio, { NewMasteringDialogInput } from "./Studio/Studio";
import studioState from "./Studio/studioState";
import useAudioFilesValidation from "./useAudioFilesValidation";

export default function IndexPage({ user }: { user: RegisteredUser }) {
    const { addNotification, removeNotification } = useNotifications();

    const [, setMasteredSelected] = useAtom(playerAtoms.selectedAudioType);
    const [currentMastering, setCurrentMastering] = useAtom(
        playerAtoms.currentMastering,
    );

    const [isLevelMatchingActive] = useAtom(playerAtoms.levelMatching);
    const [selectedAudioType] = useAtom(playerAtoms.selectedAudioType);
    const [currentTimeInSeconds, setCurrentTimeInSeconds] = useAtom(
        playerAtoms.currentTimeInSeconds,
    );

    const shouldBeFullWidth = useMediaQuery(useTheme().breakpoints.down("lg"));

    useEffect(() => {
        if (currentMastering === undefined) {
            setCurrentTimeInSeconds(0);
        }
    }, [currentMastering, setCurrentTimeInSeconds]);

    const [playRequested, setPlayRequested] = useAtom(playerAtoms.play);
    const [audioSourceLoaded, setAudioSourceLoaded] = useAtom(
        playerAtoms.isAudioSourceLoaded,
    );

    const isLoadingAudioSource = playRequested && !audioSourceLoaded;

    const downloadMasteredFile = useCallback(async (mastering: Mastering) => {
        const outputFileUrl = await Backend.getTrackMasteredFileUrl(
            mastering.id,
        );
        forceDownloadFile(outputFileUrl);
    }, []);

    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
    function closeFeedbackDialog() {
        setFeedbackDialogOpen(false);
    }

    const [feedbackDialogMastering, setFeedbackDialogMastering] =
        useState<Mastering>();
    const openFeedbackDialog = useCallback((mastering: Mastering) => {
        setFeedbackDialogMastering(mastering);
        setFeedbackDialogOpen(true);
    }, []);

    const [dialogInput, setDialogInput] =
        useState<NewMasteringDialogInput | null>(() => {
            const restoredState = studioState.retrieve(user.id);
            return restoredState == null
                ? null
                : {
                      type: "RESTORED_STATE",
                      restoredState,
                  };
        });

    const [masterings, updateMasterings] = useAtom(
        masteringListAtom.masterings,
    );
    const updateMastering = useSetAtom(masteringListAtom.updateOne);
    const addMastering = useSetAtom(masteringListAtom.addOne);
    const deleteMasteringAtom = useSetAtom(masteringListAtom.deleteOne);
    const [masteringsLoadError, setMasteringsLoadError] = useState(false);
    const [masteringsWithUnexpectedError, updateMasteringsWithUnexpectedError] =
        useState<Mastering[]>([]);

    const listenForUpdates = useCallback(async (masteringId: number) => {
        const finalMastering = await poll({
            fn: async () => {
                const mastering = await Backend.getMastering(masteringId);
                updateMastering(mastering);
                return mastering;
            },
            stopCondition: (it) => isMasteringInFinalStatus(it.status),
            interval: 1000,
            maxAttempts: 15 * 60,
        });

        if (finalMastering.status === "MASTERED") {
            console.log(
                `Mastering ${finalMastering.id} (${finalMastering.originalTrack.fileName}) has been processed with algorithm version ${finalMastering.originalTrack.algorithmVersion}`,
            );
            const notification = addNotification({
                severity: "success",
                message:
                    "Your master is now ready! Your files will be available for 30 days.",
                details: (
                    <Button
                        onClick={async () => {
                            forceDownloadFile(
                                await Backend.getTrackMasteredFileUrl(
                                    finalMastering.id,
                                ),
                            );
                            removeNotification(notification);
                        }}
                    >
                        Click here to download it!
                    </Button>
                ),
                manualDismiss: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchUserMasterings = async () => {
            let userMasterings;

            try {
                userMasterings = await Backend.getUserMasterings();
                updateMasterings(userMasterings);
            } catch (e) {
                setMasteringsLoadError(true);
                throw e;
            }

            await Promise.all(
                userMasterings
                    .filter(
                        (mastering) =>
                            !isMasteringInFinalStatus(mastering.status),
                    )
                    .map((mastering) => listenForUpdates(mastering.id)),
            );
        };

        fetchUserMasterings().catch(sentryLogger.captureException);
    }, [listenForUpdates, updateMasterings]);

    const play = useCallback(
        (mastering?: Mastering) => {
            if (mastering != null && mastering !== currentMastering) {
                setMasteredSelected("MASTERED");
                setCurrentMastering(mastering);
            }

            setPlayRequested(true);
        },
        [
            currentMastering,
            setCurrentMastering,
            setMasteredSelected,
            setPlayRequested,
        ],
    );

    const pause = useCallback(() => {
        setPlayRequested(false);
    }, [setPlayRequested]);

    function deleteMastering(mastering: Mastering) {
        setCurrentMastering(undefined);
        deleteMasteringAtom(mastering);
    }

    function openNewMasteringDialog(selectedFile: File) {
        setDialogInput({ type: "SELECTED_FILE", selectedFile });
    }

    function closeNewMasteringDialog() {
        setDialogInput(null);
    }

    async function handleNewMastering(mastering: Mastering) {
        addMastering(mastering);

        try {
            const masteringId = mastering.id;

            await Backend.startMastering(masteringId);

            await listenForUpdates(masteringId);
        } catch (e) {
            sentryLogger.captureException(e);
            updateMasteringsWithUnexpectedError((prevState) => [
                ...prevState,
                mastering,
            ]);
        }
    }

    function showRemasterDialog(mastering: Mastering) {
        setDialogInput({
            type: "REMASTER",
            originalTrack: mastering.originalTrack,
        });
    }

    useEffect(() => {
        const dragOver = (event: DragEvent) => {
            event.preventDefault();
        };
        document.body.addEventListener("dragover", dragOver);
        const drop = (event: DragEvent) => {
            event.preventDefault();
        };
        document.body.addEventListener("drop", drop);
        return () => {
            document.body.removeEventListener("dragover", dragOver);
            document.body.removeEventListener("drop", drop);
        };
    }, []);

    const { serviceDetails } = useServiceDetails();

    if (serviceDetails.status === "LOADING") {
        return <LoadingIndicator />;
    }

    return (
        <>
            <PageContent>
                <Container
                    role="region"
                    aria-label="Index Page"
                    sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: shouldBeFullWidth ? "0" : undefined,
                        paddingRight: shouldBeFullWidth ? "0" : undefined,
                    }}
                >
                    <Box>
                        <Studio
                            input={dialogInput}
                            onNewMastering={handleNewMastering}
                            onClose={closeNewMasteringDialog}
                            serviceDetails={serviceDetails}
                        />
                        <MainBar
                            onNewMasterFileSelected={(file) =>
                                openNewMasteringDialog(file)
                            }
                        />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            paddingTop: "32px",
                            paddingBottom: "32px",
                        }}
                    >
                        {masterings != null && masterings?.length > 0 && (
                            <>
                                <MasteringList
                                    masterings={
                                        masterings ===
                                        "MASTERING_NOT_INITIALIZED"
                                            ? []
                                            : masterings
                                    }
                                    masteringsWithUnexpectedError={
                                        masteringsWithUnexpectedError
                                    }
                                    onAudioFileDropped={(file) =>
                                        openNewMasteringDialog(file)
                                    }
                                    onDeleteMastering={deleteMastering}
                                    showRemasterDialog={showRemasterDialog}
                                    downloadMasteredFile={downloadMasteredFile}
                                    isLoadingAudioSource={isLoadingAudioSource}
                                    openFeedbackDialog={openFeedbackDialog}
                                    onPlayOrSelectClick={play}
                                    onPauseClick={pause}
                                />
                                <StickyPlayer
                                    mastering={currentMastering}
                                    onPlayClick={play}
                                    onPauseClick={pause}
                                    isLoadingAudioSource={isLoadingAudioSource}
                                    onDownloadClick={downloadMasteredFile}
                                    onRemasterClick={showRemasterDialog}
                                    openFeedbackDialog={openFeedbackDialog}
                                />
                                {currentMastering && (
                                    <MasteringPlayerAudioSource
                                        getOriginalFileUrl={
                                            AudioCachingService.getTrackOriginalFileUrl
                                        }
                                        getMasteredFileUrl={
                                            AudioCachingService.getTrackMasteredFileUrl
                                        }
                                        audioEntityId={currentMastering.id}
                                        matchingGain={
                                            currentMastering.matchingGain
                                        }
                                        loop={false}
                                        playRequested={playRequested}
                                        setPlayRequested={setPlayRequested}
                                        setIsAudioSourceLoaded={
                                            setAudioSourceLoaded
                                        }
                                        currentTimeInSeconds={
                                            currentTimeInSeconds
                                        }
                                        setCurrentTimeInSeconds={
                                            setCurrentTimeInSeconds
                                        }
                                        selectedAudioType={selectedAudioType}
                                        isLevelMatchingActive={
                                            isLevelMatchingActive
                                        }
                                    />
                                )}
                                {feedbackDialogMastering && (
                                    <FeedbackDialog
                                        open={feedbackDialogOpen}
                                        mastering={feedbackDialogMastering}
                                        onClose={(updatedMastering) => {
                                            closeFeedbackDialog();
                                            if (updatedMastering) {
                                                updateMastering(
                                                    updatedMastering,
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {masteringsLoadError && (
                            <div style={{ marginTop: 16 }}>
                                Unable to load existing masterings. Please
                                refresh the page to retry.
                            </div>
                        )}
                        {!masteringsLoadError && masterings?.length === 0 && (
                            <FirstMasteringCard
                                onNewMasterFileSelected={(file) =>
                                    openNewMasteringDialog(file)
                                }
                            />
                        )}
                        {!masteringsLoadError &&
                            masterings === "MASTERING_NOT_INITIALIZED" && (
                                <LoadingIndicator />
                            )}
                    </Box>
                </Container>
            </PageContent>
            <div
                style={{
                    zIndex: -1,
                    position: "fixed",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    width: "calc(100vw - var(--scrollbar-width))",
                    background: `url(${backgroundShapeLeft}) left bottom 0/366px no-repeat, url(${backgroundShapeRight}) right bottom 0/607px no-repeat`,
                }}
            />
        </>
    );
}

function LoadingIndicator() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
        >
            <CircularProgress aria-label="loading" />
        </Box>
    );
}

function MainBar({
    onNewMasterFileSelected,
}: {
    onNewMasterFileSelected: (file: File) => void;
}) {
    return (
        <Grid
            container
            style={{
                justifyContent: "space-between",
                alignItems: "end",
                padding: "0px 16px",
            }}
        >
            <Grid item>
                <Tabs
                    value="tracks"
                    textColor="inherit"
                    indicatorColor="secondary"
                >
                    <Tab
                        icon={<MasterIcon />}
                        iconPosition="start"
                        label="Tracks"
                        value="tracks"
                    />
                </Tabs>
            </Grid>
            <Grid item>
                <Tooltip title="Upload a new file to master" enterDelay={500}>
                    <NewMasteringButton
                        variant="contained"
                        startIcon={<MasterIcon />}
                        onFileSelected={onNewMasterFileSelected}
                    >
                        New master
                    </NewMasteringButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
}

const MasteringListEntryMemo = React.memo(MasteringListEntry);

function MasteringList({
    masterings,
    masteringsWithUnexpectedError,
    onAudioFileDropped,
    onDeleteMastering,
    showRemasterDialog,
    openFeedbackDialog,
    onPlayOrSelectClick,
    isLoadingAudioSource,
    onPauseClick,
    downloadMasteredFile,
}: {
    masterings: Mastering[];
    masteringsWithUnexpectedError: Mastering[];
    onAudioFileDropped: (file: File) => void;
    onDeleteMastering: (mastering: Mastering) => void;
    showRemasterDialog: (mastering: Mastering) => void;
    openFeedbackDialog: (mastering: Mastering) => void;
    onPlayOrSelectClick: () => void;
    isLoadingAudioSource: boolean;
    onPauseClick: () => void;
    downloadMasteredFile: (mastering: Mastering) => void;
}) {
    const [currentMastering] = useAtom(playerAtoms.currentMastering);

    const [currentTimeInSeconds, setCurrentTimeInSeconds] = useAtom(
        playerAtoms.currentTimeInSeconds,
    );

    function getCurrentTimeInSecondsForMastering(mastering: Mastering) {
        const isCurrentMastering = mastering === currentMastering;
        return isCurrentMastering && !isLoadingAudioSource
            ? currentTimeInSeconds
            : 0;
    }

    const { validateAudioFiles } = useAudioFilesValidation();

    const shouldDisplayABorder = useMediaQuery(useTheme().breakpoints.up("md"));
    const [dragging, setDragging] = useState(false);
    const [highlighted, setHighlighted] = useState(false);

    useEffect(() => {
        const dragOver = (event: DragEvent) => {
            event.preventDefault();
            setDragging(true);
        };
        document.body.addEventListener("dragover", dragOver);
        const dragleave = () => {
            setDragging(false);
        };
        document.body.addEventListener("dragleave", dragleave);
        const drop = (event: DragEvent) => {
            event.preventDefault();
            setDragging(false);
        };
        document.body.addEventListener("drop", drop);
        return () => {
            document.body.removeEventListener("dragover", dragOver);
            document.body.removeEventListener("dragleave", dragleave);
            document.body.removeEventListener("drop", drop);
        };
    }, []);

    const [isMasteredSelected] = useAtom(playerAtoms.selectedAudioType);

    const className = classNames({ dragging, highlighted });

    return (
        <Box
            role="table"
            aria-label="Mastering list"
            sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "16px",
                marginBottom: "48px",
                paddingBottom: "20px",
                height: "100%",
                transition: "all 250ms",
                borderRadius: "10px",
                border: shouldDisplayABorder
                    ? "1px dashed transparent"
                    : undefined,
                "&.dragging": {
                    borderColor: colors.newColors.neutrals.n2,
                },
                "&.highlighted": {
                    borderColor: colors.newColors.neutrals.n1,
                    backgroundColor: "rgba(22, 129, 255, 0.1)",
                },
            }}
            className={className}
            onDragOver={(event) => {
                event.preventDefault();
                setHighlighted(true);
            }}
            onDragLeave={(event) => {
                event.preventDefault();
                setHighlighted(false);
            }}
            onDrop={(event) => {
                event.preventDefault();
                setHighlighted(false);
                setDragging(false);

                validateAudioFiles(event.dataTransfer.files, (file) => {
                    onAudioFileDropped(file);
                });
            }}
        >
            {masterings.map((mastering, index) => (
                <MasteringListEntryMemo
                    key={mastering.id}
                    mastering={mastering}
                    index={index}
                    onDeleteMastering={onDeleteMastering}
                    hasUnexpectedError={masteringsWithUnexpectedError.includes(
                        mastering,
                    )}
                    openFeedbackDialog={openFeedbackDialog}
                    isCurrentSelectedMastering={mastering === currentMastering}
                    masteredSelected={isMasteredSelected === "MASTERED"}
                    onSelectionRequest={onPlayOrSelectClick}
                    showRemasterDialog={showRemasterDialog}
                    isLoadingAudioSource={isLoadingAudioSource}
                    onPauseClick={onPauseClick}
                    onPlayClick={onPlayOrSelectClick}
                    currentTimeInSeconds={getCurrentTimeInSecondsForMastering(
                        mastering,
                    )}
                    onCurrentTimeChanged={setCurrentTimeInSeconds}
                    downloadMasteredFile={downloadMasteredFile}
                />
            ))}
        </Box>
    );
}
