import { z } from "zod";

const ratingSchema = z.union([
    z.literal(1),
    z.literal(2),
    z.literal(3),
    z.literal(4),
    z.literal(5),
]);

export type Rating = z.infer<typeof ratingSchema>;

export const feedbackSchema = z.object({
    rating: ratingSchema,
    comments: z.nullable(z.string()),
});

export type Feedback = z.infer<typeof feedbackSchema>;
