export default {
    setItem<T extends object | number>(key: string, value: T) {
        const stringValue = JSON.stringify(value);
        window.localStorage.setItem(key, stringValue);
    },

    getItem<T extends number | object>(key: string): T | null {
        const stringValue = window.localStorage.getItem(key);
        if (stringValue == null) {
            return null;
        }

        try {
            return JSON.parse(stringValue);
        } catch (e) {
            return null;
        }
    },

    removeItem(key: string) {
        window.localStorage.removeItem(key);
    },
};
