import { Popover, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import backend, { LicenseQuota, Quota } from "../../Backend";
import { MasteringType } from "../../model/MasteringType";
import sentryLogger from "../../sentryLogger";
import colors from "../theme/colors";
import LicenseQuotaDisplay from "./LicenseQuotaDisplay";

export default function QuotaDetailsPopover({
    quotaType,
    anchorEl,
    onClose,
}: {
    quotaType: MasteringType;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}) {
    const [relevantLicensesQuotas, setRelevantLicensesQuotas] =
        useState<(Quota & { licenseId: number; resetDate: string })[]>();
    const [fetchError, setFetchError] = useState(false);

    const open = Boolean(anchorEl);

    useEffect(() => {
        async function getRelevantLicensesQuotas() {
            setFetchError(false);
            try {
                const allLicensesQuotas = await backend.getUserLicensesQuotas();
                setRelevantLicensesQuotas(
                    allLicensesQuotas.map((licenseQuota: LicenseQuota) => {
                        let quota: Quota;
                        switch (quotaType) {
                            case "INITIAL_MASTER":
                                quota = licenseQuota.initialMasters;
                                break;
                            case "REMASTER":
                                quota = licenseQuota.remasters;
                                break;
                        }

                        return {
                            licenseId: licenseQuota.licenseId,
                            resetDate: licenseQuota.resetDate,
                            ...quota,
                        };
                    }),
                );
            } catch (e) {
                sentryLogger.captureException(e);
                setRelevantLicensesQuotas(undefined);
                setFetchError(true);
            }
        }

        if (open) {
            // noinspection JSIgnoredPromiseFromCall
            getRelevantLicensesQuotas();
        }
    }, [open, quotaType]);

    let title: string;
    switch (quotaType) {
        case "INITIAL_MASTER":
            title = "Master quotas";
            break;
        case "REMASTER":
            title = "Remaster quotas";
            break;
    }

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            slotProps={{
                paper: {
                    sx:
                        fetchError || !relevantLicensesQuotas
                            ? { width: "313px" }
                            : { minWidth: "313px" },
                },
                root: {
                    slotProps: {
                        backdrop: {
                            "aria-label": "backdrop",
                            invisible: true,
                        },
                    },
                },
            }}
        >
            <Stack spacing="16px">
                <Typography
                    sx={{
                        lineHeight: "16px",
                        fontSize: "16px",
                        fontWeight: 600,
                    }}
                >
                    {title}
                </Typography>
                {(() => {
                    if (fetchError) {
                        return (
                            <div style={{ color: colors.neutrals.n1 }}>
                                There was an error fetching details of your
                                quotas. Please try again later.
                            </div>
                        );
                    }
                    if (relevantLicensesQuotas) {
                        return relevantLicensesQuotas.length === 0 ? (
                            <div style={{ color: colors.neutrals.n1 }}>
                                It seems you have no available quotas.
                            </div>
                        ) : (
                            relevantLicensesQuotas.map(
                                (currentLicenseQuota) => (
                                    <LicenseQuotaDisplay
                                        quotaType={quotaType}
                                        key={currentLicenseQuota.licenseId}
                                        quota={currentLicenseQuota}
                                        resetDate={
                                            currentLicenseQuota.resetDate
                                        }
                                    />
                                ),
                            )
                        );
                    }
                    return (
                        <div data-testid="quota-details-skeletons">
                            <Skeleton height={14} />
                            <Skeleton height={4} sx={{ marginTop: "8px" }} />
                        </div>
                    );
                })()}
            </Stack>
        </Popover>
    );
}
