import { Mark } from "@mui/base";
import { Slider, useTheme } from "@mui/material";
import { CSSProperties } from "react";
import colors from "../../theme/colors";

export default function VerticalSlider({
    "data-testid": dataTestId,
    onChange,
    marks,
    value,
    style,
    grayedOut = false,
}: {
    "data-testid"?: string;
    onChange: (value: number) => void;
    marks: readonly Mark[];
    value: number;
    style?: CSSProperties;
    grayedOut?: boolean;
}) {
    const theme = useTheme();
    return (
        <div data-testid={dataTestId} style={style}>
            <div
                style={{
                    height: "100%",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                }}
            >
                <Slider
                    sx={{
                        left: "-8px",
                        "& .MuiSlider-thumb": {
                            color: grayedOut
                                ? colors.newColors.neutrals.n3
                                : theme.palette.primary.main,
                            width: 9,
                            height: 9,
                        },
                        '& .MuiSlider-thumb:hover, & .MuiSlider-thumb[data-focusvisible="true"]':
                            grayedOut
                                ? {
                                      color: theme.palette.primary.main,
                                  }
                                : undefined,
                        "& .MuiSlider-mark": {
                            backgroundColor: grayedOut
                                ? colors.newColors.neutrals.n2
                                : "#C4C4C4",
                        },
                        "& .MuiSlider-markLabel": {
                            color: grayedOut
                                ? colors.newColors.neutrals.n2
                                : undefined,
                            fontFamily: theme.typography.fontFamily,
                            fontSize: "11px",
                            fontWeight: 700,
                            left: "40px",
                        },
                        "& .MuiSlider-rail": {
                            top: "-10px",
                            height: "82px",
                            width: "14px",
                            opacity: 1,
                            backgroundColor: "transparent",
                            border: `2px solid ${
                                grayedOut
                                    ? colors.newColors.neutrals.n2
                                    : "white"
                            }`,
                            borderRadius: "20px",
                        },
                    }}
                    orientation="vertical"
                    value={value}
                    step={null}
                    track={false}
                    marks={[...marks]}
                    onChange={(_event, sliderValue) => {
                        onChange(sliderValue as number);
                    }}
                    onKeyUp={(event) => {
                        if (event.key === " ") {
                            onChange(value as number);
                        }
                    }}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            onChange(value as number);
                        }
                    }}
                />
            </div>
        </div>
    );
}
