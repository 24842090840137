import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import baseThemeOptions from "./baseThemeOptions";
import colors from "./colors";

export default createTheme(
    deepmerge(baseThemeOptions, {
        palette: {
            mode: "dark",
            background: {
                default: colors.neutrals.n5,
                paper: colors.neutrals.n4,
            },
            primary: {
                main: colors.newColors.primary,
            },
            secondary: {
                main: colors.newColors.neutrals.n0,
            },
            warning: {
                main: colors.yellow.y1,
            },
            error: {
                main: colors.red.r3,
            },
            text: {
                primary: colors.neutrals.n0,
            },
        },
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.08)",
                        },
                    },
                },
            },
            MuiButton: {
                variants: [
                    {
                        props: { color: "primary", variant: "contained" },
                        style: {
                            ":hover": {
                                backgroundColor: colors.primary.p3,
                            },
                        },
                    },
                    {
                        props: { color: "error", variant: "contained" },
                        style: {
                            ":hover": {
                                backgroundColor: colors.red.r2,
                            },
                        },
                    },
                    {
                        props: { color: "secondary", variant: "outlined" },
                        style: {
                            color: colors.newColors.neutrals.n0,
                            borderColor: colors.newColors.neutrals.n0,
                            ":hover": {
                                borderColor: colors.newColors.neutrals.n0,
                            },
                        },
                    },
                    {
                        props: { color: "secondary", variant: "contained" },
                        style: {
                            color: colors.primary.p6,
                            ":hover": {
                                backgroundColor: "#e6e6e6",
                            },
                        },
                    },
                ],
                styleOverrides: {
                    root: {
                        color: "white",
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        backgroundColor: colors.newColors.neutrals.n6,
                        backgroundImage: "none",
                    },
                },
            },
            MuiDialogTitle: {
                defaultProps: {
                    sx: {
                        borderBottom: `1px ${colors.newColors.neutrals.n3} solid`,
                    },
                },
            },
            MuiDialogActions: {
                defaultProps: {
                    sx: {
                        borderTop: `1px ${colors.newColors.neutrals.n3} solid`,
                    },
                },
            },
            MuiDivider: {
                defaultProps: {
                    sx: {
                        borderColor: colors.neutrals.n3,
                    },
                },
            },
            MuiBackdrop: {
                defaultProps: {
                    sx: {
                        ":not(.MuiBackdrop-invisible)": {
                            backgroundColor: "#000000cc",
                        },
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: "black",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        color: colors.neutrals.n2,
                    },
                },
            },
            MuiMenu: {
                defaultProps: {
                    PaperProps: {
                        style: {
                            backgroundColor: colors.neutrals.n3,
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        ":hover": {
                            backgroundColor: colors.neutrals.n2,
                        },
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {
                        "& .MuiTouchRipple-root": {
                            color: colors.newColors.primary,
                        },
                    },
                    track: {
                        border: `solid 1px ${colors.newColors.primary}`,
                        background: "none",
                    },
                    thumb: {
                        color: colors.newColors.neutrals.n0,
                    },
                    switchBase: {
                        "&.Mui-checked": {
                            "& + .MuiSwitch-track": {
                                backgroundColor: colors.newColors.primary,
                            },
                            "&:hover": {
                                backgroundColor: `${colors.newColors.primary}42`,
                            },
                        },
                        "&:hover": {
                            backgroundColor: `${colors.newColors.primary}42`,
                        },
                    },
                },
            },
            MuiToggleButtonGroup: {
                styleOverrides: {
                    grouped: {
                        "&:not(:first-of-type), &:first-of-type": {
                            border: `1px ${colors.newColors.neutrals.n3} solid`,
                            backgroundColor: colors.newColors.backgrounds.blur,
                            "&.Mui-selected": {
                                border: `2px ${colors.newColors.primary} solid`,
                                backgroundColor:
                                    colors.newColors.backgrounds.blur,
                            },
                            "&:not(&.Mui-selected):hover": {
                                border: `1px ${colors.newColors.primary} solid`,
                                backgroundColor:
                                    colors.newColors.backgrounds.hover,
                            },
                        },
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        "& .MuiInputLabel-root": {
                            color: colors.neutrals.n1,
                            "&.Mui-focused": {
                                color: colors.neutrals.n1,
                                "& .MuiOutlinedInput-input": {
                                    border: `1px ${colors.neutrals.n3} solid`,
                                },
                            },
                            "&.Mui-disabled": {
                                color: colors.neutrals.n3,
                            },
                            "&.Mui-error": {
                                color: colors.neutrals.n1,
                            },
                        },

                        "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: `1px ${colors.neutrals.n3} solid`,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: `1px ${colors.neutrals.n2} solid`,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: `1px ${colors.neutrals.n2} solid`,
                            },
                            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                                border: `1px ${colors.neutrals.n3} solid`,
                            },

                            "& .MuiOutlinedInput-input": {
                                color: colors.neutrals.n0,
                                "&::placeholder": {
                                    color: colors.neutrals.n2,
                                },
                                "&.Mui-disabled": {
                                    color: colors.neutrals.n3,
                                    WebkitTextFillColor: colors.neutrals.n3,
                                },
                            },
                        },

                        "& .MuiFormHelperText-root": {
                            color: colors.neutrals.n1,
                            "&.Mui-disabled": {
                                color: colors.neutrals.n3,
                            },
                        },
                    },
                },
            },
        },
    }),
);
