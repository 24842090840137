import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg fill="none" viewBox="0 0 15 15">
        <path
            d="M11.9953 2.89371C11.1343 2.87794 8.86813 2.89371 7.59771 7.83347C6.16442 12.6031 3.98415 12.0919 3.00467 12.0919"
            stroke="#EAB03D"
            strokeLinecap="round"
        />
        <rect
            x="-0.5"
            y="0.5"
            width="14"
            height="14"
            rx="3.5"
            transform="matrix(-1 0 0 1 14 0)"
            stroke="#EAB03D"
        />
    </svg>,
    "HighFrequencyMore",
);
