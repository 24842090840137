import { Switch } from "@mui/material";
import { AudioType } from "../state/atoms/playerAtoms";

export default function OriginalMasteredToggle({
    selectedAudioType,
    changeSelectedAudioType,
}: {
    selectedAudioType: AudioType;
    changeSelectedAudioType: (newAudioType: AudioType) => void;
}) {
    return (
        <div
            role="region"
            aria-label="Original / Master toggle"
            style={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <div>Original</div>
            <Switch
                checked={selectedAudioType === "MASTERED"}
                onChange={(event) => {
                    changeSelectedAudioType(
                        event.target.checked ? "MASTERED" : "ORIGINAL",
                    );
                }}
                inputProps={{ "aria-label": "enable mastered output" }}
                sx={{
                    height: "19px",
                    paddingTop: 0,
                    paddingBottom: 0,
                    "& .MuiSwitch-switchBase": {
                        top: "-1px",
                    },
                }}
            />
            <div>Mastered</div>
        </div>
    );
}
