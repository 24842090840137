import { atom } from "jotai";
import { Mastering } from "../../../model/Mastering";

const masteringList = atom<Mastering[] | "MASTERING_NOT_INITIALIZED">(
    "MASTERING_NOT_INITIALIZED",
);

const sortedMasteringList = atom(
    (get) => {
        const masterings = get(masteringList);
        if (masterings === "MASTERING_NOT_INITIALIZED") {
            return "MASTERING_NOT_INITIALIZED";
        }
        return masterings.sort(
            (a, b) =>
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime(),
        );
    },
    (_, set, newMastering: Mastering[]) => {
        set(masteringList, newMastering);
    },
);

const addOne = atom(null, (get, set, newMastering: Mastering) => {
    const masterings = get(masteringList);
    if (masterings === "MASTERING_NOT_INITIALIZED") {
        throw new Error("Illegal state");
    }
    set(masteringList, [...masterings, newMastering]);
});

const updateOne = atom(null, (get, set, masteringToUpdate: Mastering) => {
    const masterings = get(masteringList);
    if (masterings === "MASTERING_NOT_INITIALIZED") {
        throw new Error("Illegal state");
    }
    set(
        masteringList,
        masterings.map((m) =>
            m.id === masteringToUpdate.id ? masteringToUpdate : m,
        ),
    );
});

const deleteOne = atom(null, (get, set, masteringToDelete: Mastering) => {
    const masterings = get(masteringList);
    if (masterings === "MASTERING_NOT_INITIALIZED") {
        throw new Error("Illegal state");
    }
    set(
        masteringList,
        masterings.filter((item) => item !== masteringToDelete),
    );
});

export default {
    masterings: sortedMasteringList,
    addOne,
    updateOne,
    deleteOne,
};
