import { Stack, Typography } from "@mui/material";
import { MasteringPriceWithTax } from "../../../../model/MasteringPriceWithTax";
import { MasteringType } from "../../../../model/MasteringType";
import formatTotalPrice from "../../../formatTotalPrice";
import { LoadedServiceDetailsComputed } from "../../../state/useServiceDetails";
import colors from "../../../theme/colors";

export default function MasteringCostDetails({
    isPayPerUseActive,
    serviceDetails,
    masteringPriceWithTax,
    masteringType,
}: {
    isPayPerUseActive: boolean;
    serviceDetails: LoadedServiceDetailsComputed;
    masteringPriceWithTax: MasteringPriceWithTax;
    masteringType: MasteringType;
}) {
    return (
        <Stack spacing="0px" role="region" aria-label="Mastering cost details">
            <Typography style={{ fontWeight: "bold" }}>
                Pay Per Use is {isPayPerUseActive ? "on" : "off"}
            </Typography>
            <Typography style={{ color: colors.neutrals.n1 }}>
                {(
                    masteringType === "INITIAL_MASTER"
                        ? serviceDetails.isNextInitialMasterFree
                        : serviceDetails.isNextRemasterFree
                ) ? (
                    <FreeMasterDetails
                        serviceDetails={serviceDetails}
                        masteringType={masteringType}
                    />
                ) : (
                    <PaidMasterDetails
                        masteringPriceWithTax={masteringPriceWithTax}
                    />
                )}
            </Typography>
        </Stack>
    );
}

function PaidMasterDetails({
    masteringPriceWithTax,
}: {
    masteringPriceWithTax: MasteringPriceWithTax;
}) {
    return (
        <>
            By clicking the master button, you will be charged{" "}
            <Typography
                component="span"
                display="inline"
                style={{ color: colors.neutrals.n0 }}
            >
                {formatTotalPrice(masteringPriceWithTax)}
            </Typography>{" "}
            to create and enjoy your full HD Master.
        </>
    );
}

function FreeMasterDetails({
    serviceDetails,
    masteringType,
}: {
    serviceDetails: LoadedServiceDetailsComputed;
    masteringType: MasteringType;
}) {
    return serviceDetails.canBenefitPpuPromo &&
        serviceDetails.bonusRemaining === 0 ? (
        <FreePpuPromoDisplay />
    ) : (
        <FreeQuotaAndBonusDisplay
            serviceDetails={serviceDetails}
            masteringType={masteringType}
        />
    );
}

function FreePpuPromoDisplay() {
    return (
        <>
            Click on the <HighlightedText text='"Master"' /> button and activate
            the <HighlightedText text="Pay Per Use" /> to receive{" "}
            <HighlightedText text={2} /> free masters.
        </>
    );
}

function FreeQuotaAndBonusDisplay({
    serviceDetails,
    masteringType,
}: {
    serviceDetails: LoadedServiceDetailsComputed;
    masteringType: MasteringType;
}) {
    return (
        <>
            This master won’t be charged as you have{" "}
            {serviceDetails.hasAap && (
                <>
                    <HighlightedText
                        text={
                            masteringType === "INITIAL_MASTER"
                                ? serviceDetails.initialMasterQuotaRemaining
                                : serviceDetails.remasterQuotaRemaining
                        }
                    />{" "}
                    {masteringType === "INITIAL_MASTER"
                        ? "master(s)"
                        : "remaster(s)"}{" "}
                    remaining on your monthly quota
                </>
            )}
            {serviceDetails.isBonusRemaining && (
                <>
                    {" "}
                    {serviceDetails.hasAap ? "and " : ""}
                    <HighlightedText
                        text={serviceDetails.bonusRemaining}
                    />{" "}
                    bonus master(s) remaining
                </>
            )}
            .
        </>
    );
}

function HighlightedText({ text }: { text: number | string }) {
    return (
        <Typography
            component="span"
            display="inline"
            style={{ color: colors.neutrals.n0 }}
        >
            {text}
        </Typography>
    );
}
