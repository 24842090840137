import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg
        width="24"
        height="24"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="17" cy="17" r="17" fill="#FF5656" />
        <path
            d="M11.5 11.5L23 23"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M23 11.5L11.5 23"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>,
    "Failure",
);
