export default function SupportedAudioFileFormatSpecs() {
    return (
        <>
            <span style={{ fontWeight: 600 }}>Supported format: .wav</span>,
            stereo only (&lt; 200MB)
            <br />
            Recommended sample rates: 44.1kHz, 48kHz
        </>
    );
}
