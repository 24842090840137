import { Box, CircularProgress } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import redirectToSignInPage from "./redirectToSignInPage";
import useAuthentication from "./state/useAuthentication";
import useNotifications from "./state/useNotifications";

/**
 * Mounting this component triggers the authentication workflow:
 *   - read persisted authentication state and refresh the current user data (if any)
 *   - then, if an authentication token is provided in the url, try to log in with it
 *   - additionally, remove the token from the URL (if any)
 *   - if the URL contains ?allowAutoLogin=true, then redirect immediately to the sign-in page
 */
export default function AuthenticationWorkflow({
    children,
}: {
    children?: ReactNode;
}) {
    const {
        login,
        attemptedAuthenticationRefreshFromCache,
        authenticationState,
        initializeAuthentication,
    } = useAuthentication();

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const allowAutoLogin = searchParams.get("allowAutoLogin") === "true";
    const [
        readingAuthenticationTokenFromUrl,
        setReadingAuthenticationTokenFromUrl,
    ] = useState(token != null);

    useEffect(() => {
        const abortController = new AbortController();
        initializeAuthentication(abortController.signal);
        return () => {
            abortController.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    useEffect(() => {
        if (token && attemptedAuthenticationRefreshFromCache) {
            const loginWithToken = async () => {
                try {
                    setReadingAuthenticationTokenFromUrl(false);
                    await login(token);
                    navigate("/", { replace: true });
                } catch (error: any) {
                    if (error.message === "User is unauthorized.") {
                        addNotification({
                            severity: "error",
                            message: "You don't have the right to access Virtu",
                        });
                    } else {
                        addNotification({
                            severity: "error",
                            message: "Authentication failed.",
                        });
                    }
                }
            };

            // noinspection JSIgnoredPromiseFromCall
            loginWithToken();
        }
    }, [
        token,
        attemptedAuthenticationRefreshFromCache,
        navigate,
        login,
        addNotification,
    ]);

    useEffect(removeTokenFromUrl, []);

    useEffect(() => {
        if (allowAutoLogin) {
            redirectToSignInPage({ allowAutoLogin });
        }
    }, [allowAutoLogin]);

    const showProgressBar =
        !attemptedAuthenticationRefreshFromCache ||
        readingAuthenticationTokenFromUrl ||
        authenticationState.type === "REGISTERED_USER_AUTHENTICATING";

    return showProgressBar ? (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
        >
            <CircularProgress aria-label="Authenticating" />
        </Box>
    ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
    );
}

function removeTokenFromUrl() {
    window.history.replaceState({}, document.title, window.location.pathname);
}
