import { Tooltip } from "@mui/material";
import { Mastering } from "../../model/Mastering";
import musicNoteIconDark from "../assets/music-note-icon-dark.svg";
import musicNoteIconLight from "../assets/music-note-icon-light.svg";
import InformationIcon from "../icons/InformationIcon";
import colors from "../theme/colors";
import loudnessPresetMapping from "./loudnessPresetMapping";
import stylePresetMapping from "./stylePresetMapping";

export default function MasteringInformation({
    mastering,
    reduced = false,
    variant = "default",
}: {
    mastering: Mastering;
    reduced?: boolean;
    variant?: "default" | "primary";
}) {
    return (
        <div
            data-testid="mastering-information"
            style={{ overflow: "hidden", display: "flex", gap: "5px" }}
        >
            {reduced && (
                <img
                    aria-hidden
                    data-testid="music-note"
                    alt=""
                    src={
                        variant === "primary"
                            ? musicNoteIconDark
                            : musicNoteIconLight
                    }
                    style={{
                        width: "40px",
                        height: "40px",
                    }}
                />
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflow: "hidden",
                }}
            >
                <Tooltip title={mastering.originalTrack.fileName}>
                    <div
                        aria-label={mastering.originalTrack.fileName}
                        style={{
                            maxWidth: reduced ? "100%" : "180px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            color:
                                variant === "primary"
                                    ? colors.newColors.primary
                                    : colors.neutrals.n0,
                        }}
                    >
                        {mastering.originalTrack.fileName}
                    </div>
                </Tooltip>
                <div
                    data-testid="mastering-parameters"
                    style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "180px",
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                        color: colors.neutrals.n1,
                    }}
                >
                    {stylePresetMapping[mastering.stylePreset].label}
                    {mastering.stylePreset === "REFERENCE_TRACK" && (
                        <Tooltip
                            title={mastering.referenceTrackFileName!!}
                            arrow
                            placement="bottom"
                        >
                            <InformationIcon
                                fontSize="inherit"
                                style={{
                                    cursor: "pointer",
                                    paddingLeft: "2px",
                                    paddingTop: "3px",
                                }}
                            />
                        </Tooltip>
                    )}
                    {` - ${loudnessPresetMapping[mastering.loudnessPreset].label}`}
                </div>
            </div>
        </div>
    );
}
