import { IconButton, Tooltip } from "@mui/material";
import DownloadIcon from "../../icons/DownloadIcon";

export default function DownloadAction({
    onClick,
    fontSize,
}: {
    onClick: () => void;
    fontSize?: number;
}) {
    return (
        <Tooltip title="Download this master">
            <IconButton
                onClick={() => {
                    onClick();
                }}
                aria-label="Download"
            >
                <DownloadIcon
                    style={{ fontSize: fontSize ? `${fontSize}px` : "inherit" }}
                />
            </IconButton>
        </Tooltip>
    );
}
