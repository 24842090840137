import Backend from "../../../Backend";
import { Mastering } from "../../../model/Mastering";
import sentryLogger from "../../../sentryLogger";
import useNotifications from "../../state/useNotifications";
import useConfirmationDialog from "../../useConfirmationDialog";

export default function useDeleteMasteringDialog({
    mastering,
    onMasteringDeleted,
}: {
    mastering: Mastering;
    onMasteringDeleted: () => void;
}) {
    const { addNotification } = useNotifications();

    const { confirmationDialogElement, openConfirmationDialog } =
        useConfirmationDialog({
            question: "Delete this master?",
            details: null,
            confirmationButtonLabel: "Delete",
            confirmationButtonColor: "error",

            confirm: async () => {
                try {
                    await Backend.deleteMastering(mastering.id);
                    onMasteringDeleted();
                    return "CLOSE_DIALOG";
                } catch (e) {
                    sentryLogger.captureException(e);
                    addNotification({
                        severity: "error",
                        message:
                            "Unable to delete mastering, please retry later",
                    });
                    return "KEEP_DIALOG_OPEN";
                }
            },
        });

    return {
        deleteMasteringDialogElement: confirmationDialogElement,
        openDeleteMasteringDialog: openConfirmationDialog,
    };
}
