import config from "./config";

export default {
    initialize() {
        if (config.get().gtm.enabled) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "gtm.start": new Date().getTime(),
                event: "gtm.js",
            });

            const script = document.createElement("script");
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtm.js?id=${
                config.get().gtm.containerId
            }`;

            document.getElementsByTagName("head")[0].appendChild(script);
        }
    },
};
