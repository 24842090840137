import { parseISO } from "date-fns";
import Backend from "./Backend";

type AudioCache = {
    expirationTimestamp: number;
    fileUrl: string;
};

const originalTrackAudioCache = new Map<number, AudioCache>();

const masteredTrackAudioCache = new Map<number, AudioCache>();

const originalPreviewAudioCache = new Map<number, AudioCache>();

const masteredPreviewAudioCache = new Map<number, AudioCache>();

export default {
    getTrackOriginalFileUrl(masteringId: number): Promise<string> {
        return getAudioUrl(masteringId, originalTrackAudioCache, () =>
            Backend.getTrackOriginalCompressedFileUrl(masteringId),
        );
    },

    getTrackMasteredFileUrl(masteringId: number): Promise<string> {
        return getAudioUrl(masteringId, masteredTrackAudioCache, () =>
            Backend.getTrackMasteredCompressedFileUrl(masteringId),
        );
    },

    getPreviewOriginalFileUrl(previewId: number): Promise<string> {
        return getAudioUrl(previewId, originalPreviewAudioCache, () =>
            Backend.getPreviewOriginalCompressedFileUrl(previewId),
        );
    },

    getPreviewMasteredFileUrl(previewId: number): Promise<string> {
        return getAudioUrl(previewId, masteredPreviewAudioCache, () =>
            Backend.getPreviewMasteredCompressedFileUrl(previewId),
        );
    },
};

async function getAudioUrl(
    cacheId: number,
    cacheMap: Map<number, AudioCache>,
    getUrlFromBackend: (masteringId: number) => Promise<string>,
) {
    let cacheEntry = cacheMap.get(cacheId);
    if (cacheEntry == null || isCacheExpired(cacheEntry)) {
        const audioFileUrl = await getUrlFromBackend(cacheId);

        const cacheExpirationTimestampInMs =
            computeCacheExpirationTimestampInMs(audioFileUrl);

        cacheEntry = {
            expirationTimestamp: cacheExpirationTimestampInMs,
            fileUrl: audioFileUrl,
        };
        cacheMap.set(cacheId, cacheEntry);
    }
    return cacheEntry!.fileUrl;
}

function computeCacheExpirationTimestampInMs(url: string): number {
    const urlDateParamString = new URLSearchParams(url).get("X-Amz-Date");

    const signatureStartingTimestampInMs =
        urlDateParamString == null
            ? Date.now()
            : parseISO(urlDateParamString).getTime();

    const urlDurationParamString = new URLSearchParams(url).get(
        "X-Amz-Expires",
    );
    const signatureDurationInMs =
        urlDurationParamString == null
            ? 0
            : parseInt(urlDurationParamString, 10) * 1000 - 60 * 1000;

    return signatureStartingTimestampInMs + signatureDurationInMs;
}

function isCacheExpired(cache: AudioCache) {
    return Date.now() > cache.expirationTimestamp;
}
