import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.96578 5.0337C6.97033 4.83125 8.97512 4.73001 10.9801 4.73001C14.3454 4.73001 17.7201 4.9013 21.074 5.23367C21.4862 5.27452 21.7872 5.64178 21.7464 6.05397C21.7055 6.46617 21.3383 6.7672 20.9261 6.72636C17.62 6.39872 14.2947 6.23001 10.9801 6.23001C9.02535 6.23001 7.07051 6.32873 5.11543 6.52621L5.11323 6.52643L3.07323 6.72643C2.661 6.76685 2.29405 6.46543 2.25363 6.05319C2.21322 5.64095 2.51464 5.27401 2.92688 5.23359L4.96578 5.0337Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.45958 3.78456L9.23964 5.09421C9.17104 5.50271 8.78427 5.77824 8.37578 5.70964C7.96729 5.64104 7.69175 5.25428 7.76035 4.84578L7.98035 3.53579C7.9842 3.51297 7.98815 3.48908 7.99216 3.46487C8.06102 3.04899 8.16203 2.43887 8.56873 1.97769C9.04279 1.44012 9.76609 1.25 10.69 1.25H13.31C14.2451 1.25 14.9677 1.4554 15.439 1.99845C15.8462 2.46776 15.9447 3.08006 16.0105 3.48891C16.0137 3.5088 16.0168 3.52822 16.0199 3.54711L16.2395 4.84486C16.3086 5.25326 16.0335 5.64037 15.6251 5.70949C15.2167 5.7786 14.8296 5.50355 14.7605 5.09514L14.5398 3.79069C14.4561 3.27767 14.4032 3.09352 14.306 2.98155C14.2523 2.9196 14.0649 2.75 13.31 2.75H10.69C9.9239 2.75 9.7422 2.91488 9.69376 2.96981C9.60165 3.07427 9.54966 3.24976 9.45958 3.78456Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.8984 8.39157C19.3117 8.41825 19.6252 8.77497 19.5985 9.18832L18.9482 19.2624L18.9469 19.2814C18.9205 19.6577 18.8916 20.0714 18.814 20.4564C18.7336 20.8555 18.592 21.2768 18.3048 21.6506C17.7037 22.4333 16.6806 22.75 15.21 22.75H8.79005C7.31949 22.75 6.29642 22.4333 5.69525 21.6506C5.40815 21.2768 5.26646 20.8555 5.18606 20.4564C5.10854 20.0714 5.07956 19.6577 5.05322 19.2814L5.0516 19.2583L4.40161 9.18832C4.37493 8.77497 4.68839 8.41825 5.10174 8.39157C5.51509 8.36489 5.87181 8.67835 5.89849 9.0917L6.54822 19.1576C6.54826 19.1583 6.54831 19.159 6.54836 19.1596C6.57658 19.5624 6.60047 19.8818 6.65654 20.1602C6.71115 20.4314 6.78695 20.6095 6.88485 20.7369C7.05368 20.9567 7.47061 21.25 8.79005 21.25H15.21C16.5295 21.25 16.9464 20.9567 17.1153 20.7369C17.2131 20.6095 17.289 20.4314 17.3436 20.1602C17.3996 19.8818 17.4235 19.5624 17.4517 19.1596C17.4518 19.159 17.4518 19.1583 17.4519 19.1576L18.1016 9.0917C18.1283 8.67835 18.485 8.36489 18.8984 8.39157Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.58008 16.5C9.58008 16.0858 9.91586 15.75 10.3301 15.75H13.6601C14.0743 15.75 14.4101 16.0858 14.4101 16.5C14.4101 16.9142 14.0743 17.25 13.6601 17.25H10.3301C9.91586 17.25 9.58008 16.9142 9.58008 16.5Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.75 12.5C8.75 12.0858 9.08579 11.75 9.5 11.75H14.5C14.9142 11.75 15.25 12.0858 15.25 12.5C15.25 12.9142 14.9142 13.25 14.5 13.25H9.5C9.08579 13.25 8.75 12.9142 8.75 12.5Z"
        />
    </>,
    "DeleteIcon",
);
