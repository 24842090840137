import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonIcon from "@mui/icons-material/Person";
import {
    Button,
    Container,
    CssBaseline,
    Tab,
    Tabs,
    ThemeProvider,
} from "@mui/material";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import PageContent from "./PageContent";
import lightTheme from "./theme/lightTheme";

export default function AccountPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const currentTab = location.pathname.split("/").pop();

    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline enableColorScheme />
            <PageContent>
                <Container>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "16px",
                        }}
                    >
                        <Button
                            size="large"
                            startIcon={<ArrowBackIcon />}
                            onClick={() => navigate("..")}
                        >
                            Tracks
                        </Button>
                        <h2>Account</h2>
                    </div>
                    <Tabs value={currentTab}>
                        <Tab
                            icon={<PersonIcon />}
                            iconPosition="start"
                            label="Profile"
                            value="profile"
                            component={Link}
                            to="profile"
                        />
                    </Tabs>
                    <Outlet />
                </Container>
            </PageContent>
        </ThemeProvider>
    );
}
