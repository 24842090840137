import { useAtom } from "jotai";
import { useCallback, useEffect } from "react";
import backend from "../../Backend";
import sentryLogger from "../../sentryLogger";
import pricingAtom from "./atoms/pricingAtom";

/**
 * This hooks immediately returns the cached pricing (if any), and also refreshes it on component mount.
 */
export default function usePricing() {
    const [pricing, setPricing] = useAtom(pricingAtom);

    const refreshPricing = useCallback(async () => {
        setPricing({
            status: "LOADING",
            previousValue:
                pricing.status === "LOADED"
                    ? pricing.masteringPriceWithTax
                    : null,
        });

        try {
            const masteringPriceWithTax =
                await backend.getMasteringPriceWithTax();
            setPricing({ status: "LOADED", masteringPriceWithTax });
        } catch (e) {
            sentryLogger.captureException(e);
            setPricing({ status: "ERROR" });
        }
    }, [pricing, setPricing]);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        refreshPricing();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [pricing, refreshPricing] as const;
}
