import LoadingButton from "@mui/lab/LoadingButton";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { ChangeEventHandler, ReactNode, useEffect, useState } from "react";
import Backend from "../../../Backend";
import { Rating } from "../../../model/Feedback";
import { Mastering } from "../../../model/Mastering";
import sentryLogger from "../../../sentryLogger";
import CloseButton from "../../CloseButton";
import musicNoteIconDark from "../../assets/music-note-icon-dark.svg";
import dates from "../../dates";
import useNotifications from "../../state/useNotifications";
import colors from "../../theme/colors";
import loudnessPresetMapping from "../loudnessPresetMapping";
import stylePresetMapping from "../stylePresetMapping";
import StarRating from "./StarRating";

export default function FeedbackDialog({
    open,
    mastering,
    onClose,
}: {
    open: boolean;
    mastering: Mastering;
    onClose: (updatedMastering: Mastering | undefined) => void;
}) {
    const { addNotification } = useNotifications();

    const [rating, setRating] = useState<number | null>(null);
    const [comments, setComments] = useState<string>("");

    const [ratingFieldInError, setRatingFieldInError] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const fullWidthDisplay = useMediaQuery(useTheme().breakpoints.down("sm"));

    useEffect(() => {
        setRating(mastering.feedback?.rating ?? null);
        setComments(mastering.feedback?.comments ?? "");
    }, [mastering]);

    function closeDialog(updatedMastering?: Mastering) {
        onClose(updatedMastering);
    }

    function cleanTextInput(input: string): string | null {
        const trimmedInput = input.trim();
        if (trimmedInput === "") {
            return null;
        }
        return trimmedInput;
    }

    async function submit() {
        const ratingError = rating == null;

        setRatingFieldInError(ratingError);

        if (ratingError) {
            document.querySelector("#rating-label")!.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        } else {
            setSubmitting(true);

            try {
                const updatedMastering = await Backend.sendMasteringFeedback(
                    mastering.id,
                    {
                        rating: rating as Rating,
                        comments: cleanTextInput(comments),
                    },
                );

                closeDialog(updatedMastering);
            } catch (e) {
                sentryLogger.captureException(e);
                addNotification({
                    severity: "error",
                    message: "Unable to submit form, please retry later",
                });
            } finally {
                setSubmitting(false);
            }
        }
    }

    const readOnly = mastering.feedback !== null;

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            fullScreen={fullWidthDisplay}
            onClose={() => closeDialog()}
        >
            <DialogTitle>
                {mastering.feedback === null
                    ? "Share your feedback"
                    : "Your feedback"}
                <div style={{ marginLeft: "auto", marginRight: "-11px" }}>
                    <CloseButton onClose={closeDialog} />
                </div>
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: "flex",
                        paddingTop: 8,
                        paddingBottom: 8,
                        alignItems: "center",
                    }}
                >
                    <img
                        src={musicNoteIconDark}
                        alt=""
                        style={{ marginRight: 20 }}
                    />
                    <span
                        style={{
                            flexGrow: 1,
                            wordBreak: "break-word",
                            marginRight: 20,
                        }}
                    >
                        {mastering.originalTrack.fileName} (
                        {stylePresetMapping[mastering.stylePreset].label}/
                        {loudnessPresetMapping[mastering.loudnessPreset].label})
                    </span>
                    <span style={{ flexShrink: 0 }}>
                        {dates.formatDateTime(mastering.createdDate)}
                    </span>
                </div>
                <Divider />
                <QuestionLabel id="rating-label">
                    How would you rate the overall quality of the master of this
                    track?
                    <span style={{ color: colors.neutrals.n1 }}>
                        {" "}
                        (mandatory)
                    </span>
                </QuestionLabel>
                <FormControl error={ratingFieldInError}>
                    <StarRating
                        rating={rating}
                        setRating={setRating}
                        disabled={readOnly}
                    />
                    {ratingFieldInError && (
                        <FormHelperText style={{ marginLeft: 0 }}>
                            Required
                        </FormHelperText>
                    )}
                </FormControl>
                <QuestionLabel id="comments">
                    Do you have any additional comments or requests for the
                    master of this track?
                </QuestionLabel>
                <FreeTextInput
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    labelId="comments"
                    disabled={readOnly}
                />
            </DialogContent>
            {!readOnly && (
                <DialogActions style={{ justifyContent: "space-between" }}>
                    <em style={{ fontSize: "0.75rem" }}>
                        Note: You won’t be able to edit your feedback once
                        submitted
                    </em>
                    <LoadingButton
                        loading={submitting}
                        variant="contained"
                        onClick={submit}
                    >
                        Send feedback
                    </LoadingButton>
                </DialogActions>
            )}
        </Dialog>
    );
}

function QuestionLabel({ children, id }: { children: ReactNode; id: string }) {
    return (
        <div id={id} style={{ marginTop: 24, marginBottom: 9 }}>
            {children}
        </div>
    );
}

function FreeTextInput({
    disabled,
    value,
    onChange,
    placeholder = "Start typing…",
    labelId,
}: {
    disabled?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    value: string | null;
    placeholder?: string;
    labelId: string;
}) {
    return (
        <TextField
            fullWidth
            multiline
            minRows={3}
            maxRows={20}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            inputProps={{ "aria-labelledby": labelId }}
        />
    );
}
