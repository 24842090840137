import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import ApplicationView from "./ApplicationView";

export default {
    renderApplication() {
        const container = document.getElementById("root");
        const root = createRoot(container!);
        root.render(
            <StrictMode>
                <ApplicationView />
            </StrictMode>,
        );
    },
};

function updateScrollbarWidth() {
    document.documentElement.style.setProperty(
        "--scrollbar-width",
        `${window.innerWidth - document.documentElement.clientWidth}px`,
    );
}

document.addEventListener("DOMContentLoaded", updateScrollbarWidth);

window.addEventListener("resize", updateScrollbarWidth);
window.addEventListener("load", updateScrollbarWidth);
