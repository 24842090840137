import { useAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import sentryLogger from "../../sentryLogger";
import playerAtoms, { AudioType } from "../state/atoms/playerAtoms";
import useNotifications from "../state/useNotifications";
import { computeMasteredVolume, computeOriginalVolume } from "./computeVolume";

export default function MasteringPlayerAudioSource({
    audioEntityId,
    playRequested,
    setPlayRequested,
    selectedAudioType,
    getOriginalFileUrl,
    getMasteredFileUrl,
    currentTimeInSeconds,
    setCurrentTimeInSeconds,
    matchingGain,
    isLevelMatchingActive,
    setIsAudioSourceLoaded,
    loop,
}: {
    audioEntityId: number;
    playRequested: boolean;
    setPlayRequested: (playRequested: boolean) => void;
    selectedAudioType: AudioType;
    getOriginalFileUrl: (entityId: number) => Promise<string>;
    getMasteredFileUrl: (entityId: number) => Promise<string>;
    currentTimeInSeconds: number;
    setCurrentTimeInSeconds: (newTime: number) => void;
    matchingGain: number | null;
    isLevelMatchingActive: boolean;
    setIsAudioSourceLoaded: (isAudioSourceLoaded: boolean) => void;
    loop: boolean;
}) {
    const [volume] = useAtom(playerAtoms.volume);

    const [originalTrackAudioElement, setOriginalTrackAudioElement] =
        useState<HTMLAudioElement | null>(null);
    const [masteredAudioElement, setMasteredAudioElement] =
        useState<HTMLAudioElement | null>(null);

    const [
        loadedOriginalTrackAudioElement,
        setLoadedOriginalTrackAudioElement,
    ] = useState<HTMLAudioElement | null>(null);
    const [loadedMasteredAudioElement, setLoadedMasteredAudioElement] =
        useState<HTMLAudioElement | null>(null);

    useEffect(() => {
        setIsAudioSourceLoaded(
            loadedOriginalTrackAudioElement != null &&
                loadedMasteredAudioElement != null,
        );
    }, [
        loadedOriginalTrackAudioElement,
        loadedMasteredAudioElement,
        setIsAudioSourceLoaded,
    ]);

    useEffect(() => {
        if (originalTrackAudioElement && masteredAudioElement) {
            originalTrackAudioElement.volume = computeOriginalVolume({
                volume,
                matchingGain,
                isLevelMatchingActive,
            });
            masteredAudioElement.volume = computeMasteredVolume({
                volume,
                matchingGain,
                isLevelMatchingActive,
            });
        }
    }, [
        originalTrackAudioElement,
        masteredAudioElement,
        selectedAudioType,
        volume,
        matchingGain,
        isLevelMatchingActive,
    ]);

    const [error, setError] = useState<any | null>();
    const { addNotification } = useNotifications();

    useEffect(() => {
        if (error) {
            const message =
                error.name === "NotAllowedError"
                    ? "The auto-play is blocked, you can enable it in Safari > Settings > Website > Auto-Play"
                    : "The play failed due to an unexpected error";

            addNotification({
                severity: "error",
                message,
                manualDismiss: true,
            });
            setError(null);
        }
    }, [addNotification, error]);

    useEffect(() => {
        if (loadedOriginalTrackAudioElement && loadedMasteredAudioElement) {
            if (playRequested) {
                // noinspection JSIgnoredPromiseFromCall
                Promise.all([
                    loadedOriginalTrackAudioElement.play(),
                    loadedMasteredAudioElement.play(),
                ]).catch((e) => {
                    setError(e);
                    setPlayRequested(false);
                });
            } else {
                loadedOriginalTrackAudioElement.pause();
                loadedMasteredAudioElement.pause();
            }
        }
    }, [
        loadedOriginalTrackAudioElement,
        loadedMasteredAudioElement,
        playRequested,
        setPlayRequested,
        addNotification,
    ]);

    const synchronizedCurrentTimeInSeconds = useRef<number | null>(null);

    useEffect(() => {
        if (loadedOriginalTrackAudioElement && loadedMasteredAudioElement) {
            if (
                currentTimeInSeconds !==
                synchronizedCurrentTimeInSeconds.current
            ) {
                loadedOriginalTrackAudioElement.currentTime =
                    currentTimeInSeconds;
                loadedMasteredAudioElement.currentTime = currentTimeInSeconds;
            }
        }
    }, [
        loadedOriginalTrackAudioElement,
        loadedMasteredAudioElement,
        currentTimeInSeconds,
    ]);

    const [originalFileUrl, setOriginalFileUrl] = useState<string | null>(null);
    const [masteredFileUrl, setMasteredFileUrl] = useState<string | null>(null);

    const [previousAudioEntityId, setPreviousAudioEntityId] =
        useState<number>(audioEntityId);

    if (
        previousAudioEntityId != null &&
        audioEntityId !== previousAudioEntityId
    ) {
        setPreviousAudioEntityId(audioEntityId);
        loadedOriginalTrackAudioElement?.pause();
        loadedMasteredAudioElement?.pause();
        setLoadedOriginalTrackAudioElement(null);
        setLoadedMasteredAudioElement(null);
    }

    useEffect(() => {
        async function loadData() {
            try {
                const [localOriginalFileUrl, localMasteredFileUrl] =
                    await Promise.all([
                        getOriginalFileUrl(audioEntityId),
                        getMasteredFileUrl(audioEntityId),
                    ]);

                setOriginalFileUrl(localOriginalFileUrl);
                setMasteredFileUrl(localMasteredFileUrl);
            } catch (e) {
                setPlayRequested(false);
                setError(e);
                throw e;
            }
        }

        loadData().catch(sentryLogger.captureException);
    }, [
        audioEntityId,
        getMasteredFileUrl,
        getOriginalFileUrl,
        setPlayRequested,
    ]);

    const resetPlayer = useCallback(() => {
        setPlayRequested(false);
        setCurrentTimeInSeconds(0);
    }, [setCurrentTimeInSeconds, setPlayRequested]);

    return (
        <>
            {originalFileUrl && (
                <audio
                    data-testid="original-audio-element"
                    loop={loop}
                    muted={selectedAudioType === "MASTERED"}
                    src={originalFileUrl}
                    ref={setOriginalTrackAudioElement}
                    // Safari workaround to handle errors when autoplay settings are disabled
                    autoPlay
                    // It seems that Firefox does not always fire the event 'canPlayThrough' when preload is
                    // left to its default value: https://github.com/englercj/resource-loader/issues/150
                    preload="auto"
                    onCanPlayThrough={() => {
                        setLoadedOriginalTrackAudioElement(
                            originalTrackAudioElement,
                        );
                    }}
                    onError={(event) => {
                        console.error(
                            "Error on original audio element",
                            event.currentTarget.error,
                        );
                        if (playRequested) {
                            resetPlayer();
                        }
                    }}
                    onEnded={() => {
                        if (playRequested) {
                            resetPlayer();
                        }
                    }}
                    onTimeUpdate={(event) => {
                        // the value is saved in a variable as successive calls to currentTime may return different values as the time advances
                        const { currentTime } = event.currentTarget;
                        synchronizedCurrentTimeInSeconds.current = currentTime;
                        setCurrentTimeInSeconds(currentTime);
                    }}
                />
            )}
            {masteredFileUrl && (
                <audio
                    data-testid="mastered-audio-element"
                    loop={loop}
                    muted={selectedAudioType === "ORIGINAL"}
                    src={masteredFileUrl}
                    ref={setMasteredAudioElement}
                    // Safari workaround to handle errors when autoplay settings are disabled
                    autoPlay
                    // It seems that Firefox does not always fire the event 'canPlayThrough' when preload is
                    // left to its default value: https://github.com/englercj/resource-loader/issues/150
                    preload="auto"
                    onCanPlayThrough={() => {
                        setLoadedMasteredAudioElement(masteredAudioElement);
                    }}
                    onError={(event) => {
                        console.error(
                            "Error on mastered audio element",
                            event.currentTarget.error,
                        );
                        if (playRequested) {
                            resetPlayer();
                        }
                    }}
                />
            )}
        </>
    );
}
