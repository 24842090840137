import { FormControlLabel, IconButton, Switch } from "@mui/material";
import useEnablePayPerUseDialog from "../EnablePayPerUseDialog/useEnablePayPerUseDialog";
import PayPerUseIcon from "../icons/PayPerUseIcon";
import usePayPerUse from "../state/usePayPerUse";
import colors from "../theme/colors";
import useDisablePayPerUseDialog from "./useDisablePayPerUseDialog";

export default function PayPerUseSwitch({
    compactMode = false,
}: {
    compactMode?: boolean;
}) {
    const { enablePayPerUseDialogElement, openEnablePayPerUseDialog } =
        useEnablePayPerUseDialog();
    const { disablePayPerUseDialogElement, openDisablePayPerUseDialog } =
        useDisablePayPerUseDialog();

    const { payPerUse } = usePayPerUse();

    return (
        <div
            style={{
                marginLeft: compactMode ? undefined : "8px",
                display: "flex",
                alignItems: "center",
            }}
        >
            {payPerUse.applicable &&
                (compactMode ? (
                    <IconButton
                        aria-label="Manage Pay Per Use"
                        onClick={() => {
                            if (payPerUse.enabled) {
                                openDisablePayPerUseDialog();
                            } else {
                                openEnablePayPerUseDialog();
                            }
                        }}
                    >
                        <PayPerUseIcon
                            style={{
                                fill: payPerUse.enabled
                                    ? "url(#blueToMagentaGradient)"
                                    : colors.neutrals.n2,
                            }}
                        />
                    </IconButton>
                ) : (
                    <>
                        <PayPerUseIcon
                            style={{
                                fill: payPerUse.enabled
                                    ? "url(#blueToMagentaGradient)"
                                    : colors.neutrals.n2,
                            }}
                        />
                        <FormControlLabel
                            style={{
                                color: colors.neutrals.n0,
                                marginLeft: "0px",
                                marginRight: "0px",
                            }}
                            control={<Switch />}
                            label={
                                payPerUse.enabled
                                    ? "Pay Per Use is on"
                                    : "Unlock Pay Per Use"
                            }
                            labelPlacement="start"
                            checked={payPerUse.enabled}
                            onClick={() => {
                                if (payPerUse.enabled) {
                                    openDisablePayPerUseDialog();
                                } else {
                                    openEnablePayPerUseDialog();
                                }
                            }}
                        />
                    </>
                ))}

            {enablePayPerUseDialogElement}
            {disablePayPerUseDialogElement}
        </div>
    );
}
