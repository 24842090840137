import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.57141 9.10999C6.98562 9.10999 7.32141 9.44577 7.32141 9.85999V14.15C7.32141 14.5642 6.98562 14.9 6.57141 14.9C6.1572 14.9 5.82141 14.5642 5.82141 14.15V9.85999C5.82141 9.44577 6.1572 9.10999 6.57141 9.10999Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.57141 7.67999C9.98562 7.67999 10.3214 8.01578 10.3214 8.42999V15.57C10.3214 15.9842 9.98562 16.32 9.57141 16.32C9.1572 16.32 8.82141 15.9842 8.82141 15.57V8.42999C8.82141 8.01578 9.1572 7.67999 9.57141 7.67999Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5714 6.25C12.9856 6.25 13.3214 6.58579 13.3214 7V17C13.3214 17.4142 12.9856 17.75 12.5714 17.75C12.1572 17.75 11.8214 17.4142 11.8214 17V7C11.8214 6.58579 12.1572 6.25 12.5714 6.25Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5714 7.67999C15.9856 7.67999 16.3214 8.01578 16.3214 8.42999V15.57C16.3214 15.9842 15.9856 16.32 15.5714 16.32C15.1572 16.32 14.8214 15.9842 14.8214 15.57V8.42999C14.8214 8.01578 15.1572 7.67999 15.5714 7.67999Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5714 9.10999C18.9856 9.10999 19.3214 9.44577 19.3214 9.85999V14.15C19.3214 14.5642 18.9856 14.9 18.5714 14.9C18.1572 14.9 17.8214 14.5642 17.8214 14.15V9.85999C17.8214 9.44577 18.1572 9.10999 18.5714 9.10999Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.66608 3.09467C5.00055 1.7602 6.99744 1.25 9.57141 1.25H15.5714C18.1454 1.25 20.1423 1.7602 21.4767 3.09467C22.8112 4.42914 23.3214 6.42603 23.3214 9V15C23.3214 17.574 22.8112 19.5709 21.4767 20.9053C20.1423 22.2398 18.1454 22.75 15.5714 22.75H9.57141C6.99744 22.75 5.00055 22.2398 3.66608 20.9053C2.33161 19.5709 1.82141 17.574 1.82141 15V9C1.82141 6.42603 2.33161 4.42914 3.66608 3.09467ZM4.72674 4.15533C3.81121 5.07086 3.32141 6.57397 3.32141 9V15C3.32141 17.426 3.81121 18.9291 4.72674 19.8447C5.64227 20.7602 7.14538 21.25 9.57141 21.25H15.5714C17.9974 21.25 19.5005 20.7602 20.4161 19.8447C21.3316 18.9291 21.8214 17.426 21.8214 15V9C21.8214 6.57397 21.3316 5.07086 20.4161 4.15533C19.5005 3.2398 17.9974 2.75 15.5714 2.75H9.57141C7.14538 2.75 5.64227 3.2398 4.72674 4.15533Z"
            />
        </g>
    </svg>,
    "Tracks",
);
