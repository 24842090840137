import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg fill="none" viewBox="0 0 20 15">
        <mask
            id="mask0_256_121"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="18"
            height="13"
            style={{ maskType: "alpha" }}
        >
            <circle cx="7.5" cy="7.5" r="6" stroke="black" />
            <circle cx="12.5" cy="7.5" r="6" stroke="black" />
        </mask>
        <g mask="url(#mask0_256_121)">
            <rect
                x="1"
                y="1"
                width="18"
                height="13"
                fill="url(#paint0_linear_256_121)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_256_121"
                x1="1"
                y1="7.5"
                x2="19"
                y2="7.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7517F8" />
                <stop offset="1" stopColor="#DF22FE" />
            </linearGradient>
        </defs>
    </svg>,
    "StereoDefault",
);
