import { LoudnessPreset } from "../../model/Mastering";

const loudnessPresetMapping: Record<
    LoudnessPreset,
    { label: string; hint: string; lufs: number }
> = {
    LOW: {
        label: "Streaming Standard",
        hint: "Perfect for all streaming platforms",
        lufs: -14,
    },
    MEDIUM: {
        label: "All round",
        hint: "Recommended for louder streaming services",
        lufs: -11,
    },
    HIGH: {
        label: "CD Level",
        hint: "Charts average loudness, CD level",
        lufs: -8,
    },
};

export default loudnessPresetMapping;
