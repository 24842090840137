import { Button, Container, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FullLogo from "./FullLogo";
import VirtuVideo from "./VirtuVideo";
import redirectToSignInPage from "./redirectToSignInPage";
import useAuthentication from "./state/useAuthentication";
import colors from "./theme/colors";

export default function AuthenticationPage() {
    const { authenticationState, logout } = useAuthentication();

    return (
        <div
            style={{
                position: "relative",
                height: "calc(100vh - 68px)",
                maxHeight: "calc(100vh - 68px)",
                overflowY: "auto",
            }}
        >
            <VirtuVideo />
            <div
                style={{
                    position: "relative",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    background: `linear-gradient(${colors.neutrals.n6}, ${colors.neutrals.n6}7f)`,
                }}
            >
                <Container maxWidth="sm">
                    {authenticationState.type ===
                    "REGISTERED_USER_AUTHENTICATED" ? (
                        <LoggedView
                            logoutClick={logout}
                            email={authenticationState.authenticatedUser.email}
                        />
                    ) : (
                        <LoginView loginClick={redirectToSignInPage} />
                    )}
                </Container>
            </div>
        </div>
    );
}

function LoggedView(props: { email: string; logoutClick: () => void }) {
    const navigate = useNavigate();
    const { email, logoutClick } = props;
    return (
        <Stack spacing={2} sx={{ alignItems: "center" }}>
            <div>You are already signed as {email}</div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/")}
            >
                Continue with this account
            </Button>
            <Button onClick={logoutClick} variant="outlined" color="secondary">
                Sign Out
            </Button>
        </Stack>
    );
}

function LoginView(props: { loginClick: () => void }) {
    const { loginClick } = props;
    return (
        <Stack
            spacing="32px"
            sx={{
                padding: "16px",
                textAlign: "center",
            }}
        >
            <FullLogo />
            <div
                style={{
                    fontSize: "16px",
                }}
            >
                VIRTU is an online audio mastering platform combining the
                trusted technology and expertise of Slate Digital to deliver a
                streaming-ready master.
            </div>
            <div
                style={{
                    fontSize: "16px",
                }}
            >
                Give your mix the clarity, space, and depth it deserves to make
                it sound as good as any song on the charts.
            </div>
            <Button variant="contained" onClick={loginClick} size="large">
                Sign In
            </Button>
        </Stack>
    );
}
