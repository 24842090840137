import { atom } from "jotai";
import { MasteringPriceWithTax } from "../../../model/MasteringPriceWithTax";

type Pricing =
    | {
          status: "LOADING";
          previousValue: MasteringPriceWithTax | null;
      }
    | {
          status: "ERROR";
      }
    | {
          status: "LOADED";
          masteringPriceWithTax: MasteringPriceWithTax;
      };

export default atom<Pricing>({ status: "LOADING", previousValue: null });
