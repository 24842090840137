import InfoIcon from "@mui/icons-material/Info";
import { Checkbox, Tooltip, Typography } from "@mui/material";
import { useAtom } from "jotai";
import playerAtoms from "../state/atoms/playerAtoms";
import colors from "../theme/colors";

export type MatchingGain =
    | {
          type: "AVAILABLE";
          value: number;
      }
    | {
          type: "NO_TRACK_LOADED_YET";
      }
    | {
          type: "NOT_AVAILABLE_ON_THIS_TRACK";
      };

export default function MatchingGainToggle({
    matchingGain,
}: {
    matchingGain: MatchingGain;
}) {
    const [isLevelMatchingActive, setLevelMatchingActive] = useAtom(
        playerAtoms.levelMatching,
    );

    const isLevelMatchingNotAvailableOnThisTrack =
        matchingGain.type === "NOT_AVAILABLE_ON_THIS_TRACK";
    const isLevelMatchingEnable =
        !isLevelMatchingNotAvailableOnThisTrack && isLevelMatchingActive;

    return (
        <div
            role="region"
            aria-label="Matching gain toggle"
            style={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Checkbox
                size="small"
                style={{
                    padding: "4px",
                }}
                checked={isLevelMatchingEnable}
                disabled={isLevelMatchingNotAvailableOnThisTrack}
                onChange={(event) =>
                    setLevelMatchingActive(event.target.checked)
                }
                inputProps={{
                    "aria-label": isLevelMatchingEnable
                        ? "Disable level match comparison"
                        : "Enable level match comparison",
                }}
                disableRipple
            />
            <Typography
                style={{
                    fontSize: "10px",
                    textAlign: "center",
                    marginLeft: "4px",
                    color: isLevelMatchingNotAvailableOnThisTrack
                        ? colors.neutrals.n2
                        : "white",
                }}
                noWrap
            >
                Level match
            </Typography>
            <Tooltip
                sx={{ marginLeft: "10px" }}
                title={
                    isLevelMatchingNotAvailableOnThisTrack
                        ? "Level match comparison isn't available for this track."
                        : "Listen to your original and master audio at the same level."
                }
            >
                <InfoIcon aria-label="Information about level matching" />
            </Tooltip>
        </div>
    );
}
