function computeOriginalVolume({
    volume,
    matchingGain,
    isLevelMatchingActive,
}: {
    volume: number;
    matchingGain: number | null;
    isLevelMatchingActive: boolean;
}) {
    if (matchingGain == null || !isLevelMatchingActive) return volume;
    return matchingGain > 1 ? volume / matchingGain : volume;
}

function computeMasteredVolume({
    volume,
    matchingGain,
    isLevelMatchingActive,
}: {
    volume: number;
    matchingGain: number | null;
    isLevelMatchingActive: boolean;
}) {
    if (matchingGain == null || !isLevelMatchingActive) return volume;
    return matchingGain > 1 ? volume : volume * matchingGain;
}

export { computeMasteredVolume, computeOriginalVolume };
