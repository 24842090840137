import {
    Alert,
    AlertTitle,
    Box,
    Collapse,
    Icon,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { TransitionGroup } from "react-transition-group";
import notificationErrorIconImage from "./assets/notification-error-icon.svg";
import notificationInfoIconImage from "./assets/notification-info-icon.svg";
import notificationSuccessIconImage from "./assets/notification-success-icon.svg";
import notificationWarningIconImage from "./assets/notification-warning-icon.svg";
import { Notification } from "./state/Notification";
import atoms from "./state/atoms";
import useNotifications from "./state/useNotifications";
import colors from "./theme/colors";

export default function NotificationCenter() {
    const [notifications] = useAtom(atoms.notifications);

    const displayNotificationInFullWidth = useMediaQuery(
        useTheme().breakpoints.down("sm"),
    );
    const computedMargin = displayNotificationInFullWidth ? 15 : 25;

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: `${computedMargin}px`,
                right: `${computedMargin}px`,
                width: displayNotificationInFullWidth
                    ? `calc(100% - ${computedMargin * 2}px)`
                    : "413px",
                zIndex: 10000,
            }}
        >
            <TransitionGroup
                style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    gap: "10px",
                }}
            >
                {notifications.map((notification) => (
                    <Collapse key={notification.id}>
                        <NotificationDisplay notification={notification} />
                    </Collapse>
                ))}
            </TransitionGroup>
        </Box>
    );
}

function NotificationDisplay({
    notification,
}: {
    notification: { id: number } & Notification;
}) {
    const { removeNotification } = useNotifications();

    useEffect(() => {
        if (!notification.manualDismiss) {
            const timeout = setTimeout(
                () => removeNotification(notification),
                6000,
            );
            return () => clearTimeout(timeout);
        }

        return () => {};

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Alert
            severity={notification.severity}
            onClose={
                notification.manualDismiss
                    ? () => removeNotification(notification)
                    : undefined
            }
            sx={{
                "&": {
                    backgroundColor: colors.newColors.neutrals.n4,
                    color: colors.newColors.neutrals.n1,
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "19px",
                    borderRadius: 0,
                    paddingTop: "21px",
                    paddingBottom: "21px",
                    paddingLeft: "24px",
                    paddingRight: "32px",
                    textAlign: "start",
                },
                ".MuiButton-root": {
                    padding: 0,
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "27px",
                    "&:hover": {
                        background: "none",
                        filter: "brightness(1.3)",
                    },
                },
                ".MuiAlert-icon": {
                    lineHeight: "27px",
                    marginTop: "2px",
                    marginRight: "22px",
                },
                ".MuiAlertTitle-root": {
                    color: colors.newColors.neutrals.n0,
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "27px",
                    marginBottom: 0,
                },
                "&.MuiAlert-standardError": {
                    borderLeft: `8px solid ${colors.red.r3}`,
                    ".MuiButton-root": {
                        color: colors.red.r3,
                    },
                },
                "&.MuiAlert-standardWarning": {
                    borderLeft: `8px solid #FFA726`,
                    ".MuiButton-root": {
                        color: "#FFA726",
                    },
                },
                "&.MuiAlert-standardInfo": {
                    borderLeft: `8px solid ${colors.primary.p3}`,
                    ".MuiButton-root": {
                        color: colors.primary.p3,
                    },
                },
                "&.MuiAlert-standardSuccess": {
                    borderLeft: `8px solid ${colors.green.g2}`,
                    ".MuiButton-root": {
                        color: colors.green.g2,
                    },
                },
            }}
            iconMapping={{
                error: (
                    <Icon data-testid="ErrorIcon">
                        <img src={notificationErrorIconImage} alt="" />
                    </Icon>
                ),
                warning: (
                    <Icon data-testid="WarningIcon">
                        <img src={notificationWarningIconImage} alt="" />
                    </Icon>
                ),
                info: (
                    <Icon data-testid="InfoIcon">
                        <img src={notificationInfoIconImage} alt="" />
                    </Icon>
                ),
                success: (
                    <Icon data-testid="SuccessIcon">
                        <img src={notificationSuccessIconImage} alt="" />
                    </Icon>
                ),
            }}
        >
            <AlertTitle>{notification.message}</AlertTitle>

            {notification.details && (
                <Box sx={{ marginTop: "16px" }}>{notification.details}</Box>
            )}
        </Alert>
    );
}
