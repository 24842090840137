import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import {
    Avatar,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Link as MuiLink,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RegisteredUser } from "../../model/User";
import messageQuestionWhite from "../assets/message-question-white.svg";
import externalUrls from "../externalUrls";
import useAuthentication from "../state/useAuthentication";

export default function AccountMenu({ user }: { user: RegisteredUser }) {
    const { logout } = useAuthentication();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpened = anchorEl != null;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();

    const onLogoutClick = () => {
        setAnchorEl(null);
        logout();
        navigate("/");
    };

    return (
        <div data-testid="user-account">
            <IconButton
                onClick={handleClick}
                aria-controls={isMenuOpened ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpened ? "true" : undefined}
            >
                <UserAvatar email={user.email} size={32} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={isMenuOpened}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <IconButton
                    style={{ position: "absolute", right: 0 }}
                    onClick={handleClose}
                    aria-label="close menu"
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
                <Stack
                    role="region"
                    aria-label="account menu information"
                    spacing="8px"
                    style={{
                        width: "250px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <UserAvatar email={user.email} size={50} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "100%",
                        }}
                    >
                        <Tooltip title={user.email} enterDelay={1000}>
                            <Typography fontWeight="bold" noWrap>
                                {user.email}
                            </Typography>
                        </Tooltip>
                    </div>
                </Stack>
                <MenuItem component={Link} to="/account/profile">
                    <ListItemIcon>
                        <PersonIcon style={{ width: 14, height: 14 }} />
                    </ListItemIcon>
                    Account
                </MenuItem>
                {user.permissions?.length > 0 && (
                    <MenuItem component={MuiLink} href="/back-office">
                        <ListItemIcon>
                            <AdminPanelSettingsIcon
                                style={{ width: 14, height: 14 }}
                            />
                        </ListItemIcon>
                        Administration
                    </MenuItem>
                )}
                <MenuItem
                    component={MuiLink}
                    href={externalUrls.faq}
                    target="_blank"
                    rel="noopener"
                >
                    <ListItemIcon>
                        <img
                            style={{
                                display: "flex",
                                width: 14,
                                height: 14,
                            }}
                            src={messageQuestionWhite}
                            alt="FAQ icon"
                        />
                    </ListItemIcon>
                    FAQ
                </MenuItem>
                <MenuItem onClick={onLogoutClick}>
                    <ListItemIcon>
                        <LogoutIcon style={{ width: 14, height: 14 }} />
                    </ListItemIcon>
                    Sign Out
                </MenuItem>
            </Menu>
        </div>
    );
}

function UserAvatar({ email, size }: { email: string; size: number }) {
    return (
        <Avatar sx={{ width: size, height: size, fontSize: size / 2 }}>
            {email[0].toUpperCase()}
        </Avatar>
    );
}
