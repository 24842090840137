import edmImage from "../assets/edm.png";
import hiphopImage from "../assets/hiphop.png";
import instrumentalImage from "../assets/instrumental.png";
import popImage from "../assets/pop.png";
import rockImage from "../assets/rock.png";
import universalImage from "../assets/universal.png";

import { StylePreset } from "../../model/Mastering";

const stylePresetMapping: Record<
    StylePreset,
    { label: string; image?: string }
> = {
    POP: { label: "Pop", image: popImage },
    ROCK: { label: "Rock", image: rockImage },
    HIPHOP: { label: "Hip-Hop", image: hiphopImage },
    EDM: { label: "EDM", image: edmImage },
    UNIVERSAL: { label: "Universal", image: universalImage },
    INSTRUMENTAL: { label: "Instrumental", image: instrumentalImage },
    REFERENCE_TRACK: { label: "Reference track" },
};

export default stylePresetMapping;
