import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg fill="none" viewBox="0 0 15 15">
        <path
            d="M1 1.49997C1 1.49997 3 3.00002 7.5 3.00002C12 3.00002 14 1.49997 14 1.49997"
            stroke="#4DFDDF"
            strokeLinecap="round"
        />
        <path
            d="M14 13.5002C14 13.5002 11.9484 12 7.44838 12C2.94838 12 1 13.5002 1 13.5002"
            stroke="#9B4DFF"
            strokeLinecap="round"
        />
        <path
            d="M7.5 4.5V10.5M5 5.5V9.5M10 5.5V9.5M12.5 6V9M2.5 6V9"
            stroke="url(#paint0_linear_240_208)"
            strokeLinecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_240_208"
                x1="7.5"
                y1="4.5"
                x2="7.5"
                y2="10.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4DFDDF" />
                <stop offset="1" stopColor="#9B4DFF" />
            </linearGradient>
        </defs>
    </svg>,
    "CompressionPunch",
);
