/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, ButtonProps } from "@mui/material";
import { Ref, forwardRef, useRef } from "react";
import HiddenWavFileInput from "./HiddenWavFileInput";

export default forwardRef(
    (
        {
            onFileSelected,
            ...props
        }: { onFileSelected: (file: File) => void } & Omit<
            ButtonProps,
            "onClick"
        >,
        ref: Ref<HTMLButtonElement>,
    ) => {
        const fileInput = useRef<HTMLInputElement | null>(null);

        return (
            <Box>
                <Button
                    onClick={() => {
                        fileInput.current!.click();
                    }}
                    {...props}
                    ref={ref}
                />

                <HiddenWavFileInput
                    onFileSelected={onFileSelected}
                    ref={fileInput}
                />
            </Box>
        );
    },
);
