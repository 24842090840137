import { z } from "zod";

const dateTimeSchema = z.string().datetime();
const dateSchema = z.string().date();

export default {
    formatDateTime(isoDateTime: string): string {
        return new Date(dateTimeSchema.parse(isoDateTime)).toLocaleDateString(
            "en-US",
            {
                year: "numeric",
                month: "short",
                day: "numeric",
            },
        );
    },

    formatLocalDateWithoutYear(isoLocalDate: string): string {
        return new Date(
            `${dateSchema.parse(isoLocalDate)}T00:00:00`,
        ).toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
        });
    },
};
