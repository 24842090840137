import { LinearProgress } from "@mui/material";
import colors from "../theme/colors";

export default function QuotaProgressBar({ progress }: { progress: number }) {
    return (
        <LinearProgress
            sx={{
                marginTop: "8px",
                height: "4px",
                backgroundColor: `${colors.neutrals.n0}33`,
                ".MuiLinearProgress-bar": {
                    background: colors.newColors.gradients.orangeToPink,
                    borderRadius: "2px",
                },
            }}
            aria-label="Total quotas' usage"
            variant="determinate"
            value={progress}
        />
    );
}
