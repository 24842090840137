import { AxiosResponse } from "axios";
import { z } from "zod";
import { authenticatedBackend } from "./AxiosConfiguration";
import { AuthenticatedUser } from "./model/AuthenticatedUser";
import {
    BillingInformation,
    billingInformationSchema,
} from "./model/BillingInformation";
import { Feedback } from "./model/Feedback";
import {
    Mastering,
    compressionPresetSchema,
    highPresetSchema,
    loudnessPresetSchema,
    lowPresetSchema,
    masteringSchema,
    stereoPresetSchema,
    stylePresetSchema,
} from "./model/Mastering";
import {
    MasteringPriceWithTax,
    masteringPriceWithTaxSchema,
} from "./model/MasteringPriceWithTax";
import { OriginalTrack, originalTrackSchema } from "./model/OriginalTrack";
import { previewStatusSchema } from "./model/Preview";
import { ReferenceTrack, referenceTrackSchema } from "./model/ReferenceTrack";
import { User, userSchema } from "./model/User";

export const masteringPresetsSchema = z.object({
    style: stylePresetSchema,
    loudness: loudnessPresetSchema,
    low: z.nullable(lowPresetSchema),
    high: z.nullable(highPresetSchema),
    compression: z.nullable(compressionPresetSchema),
    stereo: z.nullable(stereoPresetSchema),
});

export type MasteringPresets = z.infer<typeof masteringPresetsSchema>;

const previewResponseSchema = z.object({
    id: z.number(),
    status: previewStatusSchema,
    previewDurationInSeconds: z.number(),
    matchingGain: z.nullable(z.number()),
    waveform: z.nullable(z.array(z.number())),
    originalWaveform: z.nullable(z.array(z.number())),
});

export type PreviewResponse = z.infer<typeof previewResponseSchema>;

const quota = z.object({
    value: z.number(),
    usage: z.number(),
});
export type Quota = z.infer<typeof quota>;

const licenseQuotaSchema = z.object({
    licenseId: z.number(),
    resetDate: z.string(),
    initialMasters: quota,
    remasters: quota,
});

export type LicenseQuota = z.infer<typeof licenseQuotaSchema>;

export default {
    async getUserMasterings(): Promise<Mastering[]> {
        const result = await authenticatedBackend.get("/api/mastering");
        return z.array(masteringSchema).parse(result.data);
    },

    async enablePayPerUse(data: { paymentMethodNonce: string }): Promise<void> {
        await authenticatedBackend.patch("/api/pay-per-use/enable", data);
    },

    async disablePayPerUse(): Promise<void> {
        await authenticatedBackend.patch("/api/pay-per-use/disable");
    },

    async getTrackOriginalCompressedFileUrl(
        masteringId: number,
    ): Promise<string> {
        const response = await authenticatedBackend.get(
            `/api/mastering/${masteringId}/compressedInputFileUrl`,
        );
        return z.string().parse(response.data);
    },

    async getTrackMasteredCompressedFileUrl(
        masteringId: number,
    ): Promise<string> {
        const response = await authenticatedBackend.get(
            `/api/mastering/${masteringId}/compressedOutputFileUrl`,
        );
        return z.string().parse(response.data);
    },

    async getTrackMasteredFileUrl(masteringId: number): Promise<string> {
        const response = await authenticatedBackend.get(
            `/api/mastering/${masteringId}/outputFileUrl`,
        );
        return z.string().parse(response.data);
    },

    async createOriginalTrack(fileName: string): Promise<OriginalTrack> {
        const result = await authenticatedBackend.post("/api/original-track", {
            fileName,
        });
        return originalTrackSchema.parse(result.data);
    },

    async createMastering(
        originalTrackId: number,
        presets: MasteringPresets,
        referenceTrackId: number | null,
    ): Promise<Mastering> {
        const result = await authenticatedBackend.post("/api/mastering", {
            originalTrackId,
            referenceTrackId,
            stylePreset: presets.style,
            loudnessPreset: presets.loudness,
            lowPreset: presets.low,
            highPreset: presets.high,
            compressionPreset: presets.compression,
            stereoPreset: presets.stereo,
        });
        return masteringSchema.parse(result.data);
    },

    async getMastering(masteringId: number): Promise<Mastering> {
        const result = await authenticatedBackend.get(
            `/api/mastering/${masteringId}`,
        );
        return masteringSchema.parse(result.data);
    },

    async getOriginalTrack(originalTrackId: number): Promise<OriginalTrack> {
        const result = await authenticatedBackend.get(
            `/api/original-track/${originalTrackId}`,
        );
        return originalTrackSchema.parse(result.data);
    },

    async getOriginalTrackUploadUrl(masteringId: number): Promise<string> {
        const response = await authenticatedBackend.get(
            `/api/original-track/${masteringId}/upload-url`,
        );
        return z.string().parse(response.data);
    },

    async analyzeOriginalTrack(originalTrackId: number): Promise<void> {
        await authenticatedBackend.post(
            `/api/original-track/${originalTrackId}/analyze`,
        );
    },

    async createReferenceTrack(fileName: string): Promise<ReferenceTrack> {
        const result = await authenticatedBackend.post("/api/reference-track", {
            fileName,
        });
        return referenceTrackSchema.parse(result.data);
    },

    async getReferenceTrack(referenceTrackId: number): Promise<ReferenceTrack> {
        const result = await authenticatedBackend.get(
            `/api/reference-track/${referenceTrackId}`,
        );
        return referenceTrackSchema.parse(result.data);
    },

    async getReferenceTrackUploadUrl(
        referenceTrackId: number,
    ): Promise<string> {
        const response = await authenticatedBackend.get(
            `/api/reference-track/${referenceTrackId}/upload-url`,
        );
        return z.string().parse(response.data);
    },

    async analyzeReferenceTrack(referenceTrackId: number): Promise<void> {
        await authenticatedBackend.post(
            `/api/reference-track/${referenceTrackId}/analyze`,
        );
    },

    async startMastering(masteringId: number): Promise<void> {
        await authenticatedBackend.post(
            `/api/mastering/${masteringId}/start-mastering`,
        );
    },

    async sendMasteringFeedback(
        masteringId: number,
        feedback: Feedback,
    ): Promise<Mastering> {
        const response = await authenticatedBackend.put<Mastering>(
            `/api/mastering/${masteringId}/feedback`,
            feedback,
        );

        return response.data;
    },

    async deleteMastering(masteringId: number): Promise<void> {
        await authenticatedBackend.delete<Mastering>(
            `/api/mastering/${masteringId}`,
        );
    },

    async login(token: string | null): Promise<AuthenticatedUser> {
        try {
            const response = await authenticatedBackend.post<User>(
                "/api/login",
                {
                    token,
                },
            );

            checkResponseAuthorization(response);

            return {
                ...response.data,
                authorizationHeader: response.headers.authorization,
            };
        } catch (error: any) {
            if (error?.response?.status === 403) {
                throw Error("User is unauthorized.");
            }
            throw error;
        }
    },

    async createOrGetPreview(
        originalTrackId: number,
        presets: MasteringPresets,
        referenceTrackId: number | null,
    ): Promise<PreviewResponse> {
        const result = await authenticatedBackend.post("/api/preview", {
            originalTrackId,
            referenceTrackId,
            stylePreset: presets.style,
            loudnessPreset: presets.loudness,
            lowPreset: presets.low,
            highPreset: presets.high,
            compressionPreset: presets.compression,
            stereoPreset: presets.stereo,
        });
        return previewResponseSchema.parse(result.data);
    },

    async startPreview(previewId: number): Promise<void> {
        await authenticatedBackend.post(
            `/api/preview/${previewId}/start-preview`,
        );
    },

    async getPreviewOriginalCompressedFileUrl(
        previewId: number,
    ): Promise<string> {
        const response = await authenticatedBackend.get(
            `/api/preview/${previewId}/compressedInputFileUrl`,
        );
        return z.string().parse(response.data);
    },

    async getPreviewMasteredCompressedFileUrl(
        previewId: number,
    ): Promise<string> {
        const response = await authenticatedBackend.get(
            `/api/preview/${previewId}/compressedOutputFileUrl`,
        );
        return z.string().parse(response.data);
    },

    async getPreview(previewId: number): Promise<PreviewResponse> {
        const result = await authenticatedBackend.get(
            `/api/preview/${previewId}`,
        );
        return previewResponseSchema.parse(result.data);
    },

    async getCurrentUser(abortSignal: AbortSignal): Promise<User> {
        const result = await authenticatedBackend.get("/api/user", {
            signal: abortSignal,
        });
        return userSchema.parse(result.data);
    },

    async getBraintreeClientToken(): Promise<string> {
        const result = await authenticatedBackend.get(
            "/api/pay-per-use/braintree-client-token",
        );
        return z.string().parse(result.data);
    },

    async getBillingInformation(): Promise<BillingInformation | null> {
        const result = await authenticatedBackend.get(
            "/api/user/billing-information",
        );
        return result.data === ""
            ? null
            : billingInformationSchema.parse(result.data);
    },

    async updateBillingInformation(
        billingInformation: BillingInformation,
    ): Promise<void> {
        await authenticatedBackend.put(
            "/api/user/billing-information",
            billingInformation,
        );
    },

    async getMasteringPriceWithTax(): Promise<MasteringPriceWithTax> {
        const result = await authenticatedBackend.get(
            "/api/pay-per-use/price-with-tax",
        );
        return masteringPriceWithTaxSchema.parse(result.data);
    },

    async getUserLicensesQuotas(): Promise<LicenseQuota[]> {
        const result = await authenticatedBackend.get(
            "/api/user/license-quotas",
        );
        return z.array(licenseQuotaSchema).parse(result.data);
    },
};

function checkResponseAuthorization(response: AxiosResponse) {
    if (!response.headers.authorization) {
        throw Error(
            "Cannot sign in: no JWT token provided in authorization header",
        );
    }
}
