import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 9.10999C6.41421 9.10999 6.75 9.44577 6.75 9.85999V14.15C6.75 14.5642 6.41421 14.9 6 14.9C5.58579 14.9 5.25 14.5642 5.25 14.15V9.85999C5.25 9.44577 5.58579 9.10999 6 9.10999Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 7.67999C9.41421 7.67999 9.75 8.01578 9.75 8.42999V15.57C9.75 15.9842 9.41421 16.32 9 16.32C8.58579 16.32 8.25 15.9842 8.25 15.57V8.42999C8.25 8.01578 8.58579 7.67999 9 7.67999Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 7.67999C15.4142 7.67999 15.75 8.01578 15.75 8.42999V15.57C15.75 15.9842 15.4142 16.32 15 16.32C14.5858 16.32 14.25 15.9842 14.25 15.57V8.42999C14.25 8.01578 14.5858 7.67999 15 7.67999Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 9.10999C18.4142 9.10999 18.75 9.44577 18.75 9.85999V14.15C18.75 14.5642 18.4142 14.9 18 14.9C17.5858 14.9 17.25 14.5642 17.25 14.15V9.85999C17.25 9.44577 17.5858 9.10999 18 9.10999Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.09467 3.09467C4.42914 1.7602 6.42603 1.25 9 1.25H15C17.574 1.25 19.5709 1.7602 20.9053 3.09467C22.2398 4.42914 22.75 6.42603 22.75 9V15C22.75 17.574 22.2398 19.5709 20.9053 20.9053C19.5709 22.2398 17.574 22.75 15 22.75H9C6.42603 22.75 4.42914 22.2398 3.09467 20.9053C1.7602 19.5709 1.25 17.574 1.25 15V9C1.25 6.42603 1.7602 4.42914 3.09467 3.09467ZM4.15533 4.15533C3.2398 5.07086 2.75 6.57397 2.75 9V15C2.75 17.426 3.2398 18.9291 4.15533 19.8447C5.07086 20.7602 6.57397 21.25 9 21.25H15C17.426 21.25 18.9291 20.7602 19.8447 19.8447C20.7602 18.9291 21.25 17.426 21.25 15V9C21.25 6.57397 20.7602 5.07086 19.8447 4.15533C18.9291 3.2398 17.426 2.75 15 2.75H9C6.57397 2.75 5.07086 3.2398 4.15533 4.15533Z"
            fill="currentColor"
        />
    </svg>,
    "Master",
);
