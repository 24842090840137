import { atom } from "jotai";
import {
    AuthenticatedGuestUser,
    AuthenticatedRegisteredUser,
} from "../../../model/AuthenticatedUser";
import { Notification } from "../Notification";

const attemptedAuthenticationRefreshFromCacheAtom = atom(false);

type AuthenticationState =
    | {
          type: "NOT_INITIALIZED";
      }
    | {
          type: "NOT_AUTHENTICATED";
      }
    | {
          type: "REGISTERED_USER_AUTHENTICATING";
      }
    | {
          type: "REGISTERED_USER_AUTHENTICATED";
          authenticatedUser: AuthenticatedRegisteredUser;
      }
    | {
          type: "GUEST_USER_AUTHENTICATING";
      }
    | {
          type: "GUEST_USER_AUTHENTICATED";
          authenticatedUser: AuthenticatedGuestUser;
      };

const authenticationStateAtom = atom<AuthenticationState>({
    type: "NOT_INITIALIZED",
});

type PayPerUseState =
    | { applicable: true; enabled: boolean }
    | { applicable: false };

const payPerUseAtom = atom(
    (get): PayPerUseState => {
        const authenticationState = get(authenticationStateAtom);

        if (
            authenticationState.type === "REGISTERED_USER_AUTHENTICATED" &&
            authenticationState.authenticatedUser
        ) {
            return {
                applicable: true,
                enabled: authenticationState.authenticatedUser.payPerUseEnabled,
            };
        }

        return {
            applicable: false,
        };
    },
    (get, set, { enabled }: { enabled: boolean }) => {
        const authenticationState = get(authenticationStateAtom);

        if (
            authenticationState.type === "REGISTERED_USER_AUTHENTICATED" &&
            authenticationState.authenticatedUser
        ) {
            set(authenticationStateAtom, {
                type: "REGISTERED_USER_AUTHENTICATED",
                authenticatedUser: {
                    ...authenticationState.authenticatedUser,
                    payPerUseEnabled: enabled,
                },
            });
        } else {
            throw new Error("Illegal state!");
        }
    },
);

const notificationsAtom = atom<({ id: number } & Notification)[]>([]);

export default {
    attemptedAuthenticationRefreshFromCache:
        attemptedAuthenticationRefreshFromCacheAtom,
    authenticationState: authenticationStateAtom,
    payPerUse: payPerUseAtom,
    notifications: notificationsAtom,
};
