import { Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode } from "react";

export default function AdvancedPresetIcon({
    description,
    children,
}: {
    description: string;
    children: ReactNode;
}) {
    const useSmallFont = useMediaQuery(useTheme().breakpoints.down("lg"));

    return (
        <Tooltip title={description}>
            <Box
                role="listitem"
                data-testid="advanced-preset-icon"
                sx={{
                    display: "inline",
                    "&>svg": {
                        fontSize: useSmallFont ? "13px" : "17px",
                        verticalAlign: "middle",
                        margin: "3px",
                    },
                }}
            >
                {children}
            </Box>
        </Tooltip>
    );
}
