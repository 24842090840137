import { createSvgIcon } from "@mui/material/utils";

export default createSvgIcon(
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M0 0.5V2.5H6V0.5H0ZM0 3.5V5.5H6V3.5H0ZM0 6.5V8.5H6V6.5H0ZM14 6.5V8.5H20V6.5H14ZM0 9.5V11.5H6V9.5H0ZM14 9.5V11.5H20V9.5H14ZM0 12.5V14.5H6V12.5H0ZM7 12.5V14.5H13V12.5H7ZM14 12.5V14.5H20V12.5H14ZM0 15.5V17.5H6V15.5H0ZM7 15.5V17.5H13V15.5H7ZM14 15.5V17.5H20V15.5H14ZM0 18.5V20.5H6V18.5H0ZM7 18.5V20.5H13V18.5H7ZM14 18.5V20.5H20V18.5H14Z" />
    </svg>,
    "Loudness",
);
