import { z } from "zod";
import { audioFileMetadataSchema } from "./AudioFileMetadata";

const originalTrackStatusSchema = z.enum([
    "CREATED",
    "ANALYZING",
    "ANALYZED",
    "ANALYSIS_ERROR",
]);

export const originalTrackSchema = z.object({
    id: z.number(),
    fileName: z.string(),
    status: originalTrackStatusSchema,
    previewFeatureAvailable: z.boolean(),
    waveform: z.nullable(z.array(z.number())),
    inputAudioFileMetadata: z.nullable(audioFileMetadataSchema),
    algorithmVersion: z.string().nullable(),
});

export type OriginalTrack = z.infer<typeof originalTrackSchema>;
